import create from 'zustand';
import apiCall from '../utils/apiCall';
import { UserData } from '../model/Model';

type User = {
  info: UserData | null;
  error: {
    hasError: boolean;
    error: unknown | null;
  };
};

const initialUser: Readonly<User> = {
  info: null,
  error: { hasError: false, error: null },
};

type UserState = {
  user: User;
  setUser: (user: Partial<User>) => void;
  getUser: () => void;
};

const useUserStore = create<UserState>((set) => ({
  user: initialUser,
  setUser: (user) => set((state) => ({ user: { ...state.user, ...user } })),
  getUser: async () => {
    try {
      const { data } = await apiCall.get('/user');
      set({
        user: {
          info: data,
          error: { hasError: false, error: null },
        },
      });
    } catch (error) {
      set({
        user: {
          info: null,
          error: { hasError: true, error },
        },
      });
    }
  },
}));

export default useUserStore;

export const initialState = {
  user: initialUser,
};
