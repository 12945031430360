import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { injectIntl, IntlShape } from 'react-intl';
import useStyles from '../styles';
import { ReactComponent as NoAccessPage } from '../images/no-patient.svg';
import testIds from '../testIds';
import { useIsMobile } from '../../PagesTemplate/utils/ResponsiveHooks';
import useErrorContext from '../../../states/errorStore';
import VariantProps from './VariantProps';

type Props = {
  intl: IntlShape;
  isMobile?: boolean;
  message?: string;
};

const ErrorScreenNoAccessPage = (props: VariantProps) => {
  const { intl, isMobile, message } = props;
  const useIsMobileResult = useIsMobile();
  const isMobileRendering = isMobile || useIsMobileResult;
  const title = intl.formatMessage({ id: 'app.error.noAccess.title' });
  const ending = intl.formatMessage({
    id: 'app.error.noAccess.ending',
  });
  const theme = useTheme();
  const styles = useStyles(theme);
  const setErrorState = useErrorContext((s) => s.setErrorState);
  setErrorState(true);

  return (
    <div
      className={isMobileRendering ? styles.mainContent : styles.fgCenter}
      style={{ background: 'none' }}
    >
      {!isMobileRendering && <NoAccessPage />}
      {isMobileRendering && <NoAccessPage width="244" height="253" />}
      <Typography
        variant="h2"
        component="h2"
        align="center"
        data-testid={testIds.errorMessage}
      >
        {title}
      </Typography>
      <Typography variant="body2" component="p" align="center">
        {message}
      </Typography>
      <Typography variant="body2" component="p" align="center">
        {ending}
      </Typography>
    </div>
  );
};

export default injectIntl(ErrorScreenNoAccessPage);
