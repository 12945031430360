import { useMemo } from 'react';
import { Buffer } from 'buffer';

const useScopedId = (): ((id: string) => string) => {
  const scopedId = useMemo(() => {
    const bytes = new Uint8Array(6);
    for (let i = 0; i < bytes.length; i += 1) {
      bytes[i] = Math.floor(Math.random() * 0x100);
    }
    return Buffer.from(bytes).toString('base64').replace('=', '');
  }, []);

  return (id: string) => `com.pointclickcare.sop.care-vault:care-vault-ui:jar:1.0.0-${scopedId}`;
};

export default useScopedId;
