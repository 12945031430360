import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function VitalsIcon(props: SvgIconProps): React.ReactNode {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM16 18H8V16H16V18ZM16 14H8V12H16V14ZM13 9V3.5L18.5 9H13Z" />
    </SvgIcon>
  );
}
