import React, { useEffect, useMemo } from 'react';
import { Box, DialogActions, IconButton, Popover } from '@material-ui/core';
import { Button } from 'pcc-react-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { FormattedMessage } from 'react-intl';
import NotificationList from './NotificationList';
import { useNotifications } from '../../../../states/notifications';
import useNotificationStyles from './styles';
import { useIsMobile } from '../../../PagesTemplate/utils/ResponsiveHooks';
import NotificationsIcon from '../../../../images/NotificationsIcon';

const NotificationDrawer = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const styles = useNotificationStyles({ isMobile });

  const [
    notifications,
    closeAllNotifications,
    readAllNotifications,
    open,
    setOpen,
  ] = useNotifications((state) => [
    state.notifications,
    state.closeAllNotifications,
    state.readAllNotifications,
    state.drawerOpen,
    state.setDrawerOpen,
  ]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      closeAllNotifications();
      readAllNotifications();
    }
  }, [closeAllNotifications, readAllNotifications, open, notifications]);

  return (
    <>
      <IconButton className="appBarIconButton" onClick={handleClick}>
        <NotificationsIcon />
      </IconButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: theme.spacing(-2),
          horizontal: 'center',
        }}
        marginThreshold={theme.spacing(2)}
        PaperProps={{
          className: styles.drawerPaper,
        }}
      >
        <div className={styles.drawerContent}>
          <NotificationList notifications={notifications} inDrawer />
          {notifications.length === 0 && (
            <Box className={styles.drawerEmptyMessage}>
              <FormattedMessage id="app.notifications.empty" />
            </Box>
          )}
        </div>
        <DialogActions className={styles.drawerActions} disableSpacing>
          <Button variant="text" onClick={handleClose}>
            <FormattedMessage id="app.close" />
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};

export default NotificationDrawer;
