import create from 'zustand';

export enum Features {
  VITALS_CHART,
  PATIENT_PHOTO,
  SUMMARY,
  AUDIT_LOG,
  DATE_FILTER,
  CCD,
  IMPLANTABLE_DEVICES,
  PROBLEMS,
  FHIRAPPS,
  ALLERGY_MANIFESTATIONS,
  PCC_VERSION,
}

const valueIsFeature = (value: string | Features): value is Features =>
  typeof value !== 'string';

export const ALL_FEATURES = new Set(
  Object.values(Features).filter(valueIsFeature)
);

export const mapFeatureKeyToEnum = (key: string) => {
  switch (key) {
    case 'enablevitalsvisualization':
      return Features.VITALS_CHART;
    case 'enablepatientphoto':
      return Features.PATIENT_PHOTO;
    case 'enablesummaryscreen':
      return Features.SUMMARY;
    case 'carevault_view_auditlog_port-3370':
      return Features.AUDIT_LOG;
    case 'enable_date_filter':
      return Features.DATE_FILTER;
    case 'ccd_page_enabled':
      return Features.CCD;
    case 'implantable_devices_enabled':
      return Features.IMPLANTABLE_DEVICES;
    case 'problems_enabled':
      return Features.PROBLEMS;
    case 'fhirapps_enabled':
      return Features.FHIRAPPS;
    case 'ccc_allergy_reaction_manifestation_port-4354':
      return Features.ALLERGY_MANIFESTATIONS;
    case 'pcc_version':
      return Features.PCC_VERSION;
    default:
      return null;
  }
};

export type FeatureContextState = {
  enabledFeatures: Set<Features>;
  setEnabledFeatures: (features: Set<Features>) => void;
  featuresLoading: boolean;
  setFeaturesLoading: (featuresLoading: boolean) => void;
  enableFeature: (feature: Features) => void;
  disableFeature: (feature: Features) => void;
};

const useFeaturesContext = create<FeatureContextState>((set) => ({
  enabledFeatures: new Set(),
  setEnabledFeatures: (features: Set<Features>) => {
    set({ enabledFeatures: features });
  },
  featuresLoading: true,
  setFeaturesLoading: (featuresLoading: boolean) => {
    set({ featuresLoading });
  },
  enableFeature: (feature: Features) => {
    set((state) => {
      state.enabledFeatures.add(feature);
    });
  },
  disableFeature: (feature: Features) => {
    set((state) => {
      state.enabledFeatures.delete(feature);
    });
  },
}));

export default useFeaturesContext;
