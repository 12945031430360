import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

type PageTitleProps = {
  title: string;
  delimit?: string;
  icon?: ReactNode;
};

const PageDescriptionWithIcon = (props: PageTitleProps) => {
  const { title, delimit, icon } = props;
  function flatMap(array: string[], callback: (arrayElement: string) => any) {
    let result: string[] = [];
    for (let i = 0; i < array.length; i += 1) {
      const mapping = callback(array[i]);
      result = result.concat(mapping);
    }
    return result;
  }
  const content = flatMap(title.split(delimit || ''), (part) => {
    return [
      <span key={part}>{part}</span>,
      <span data-testid="page-description-icon" key={part}>
        {icon}
      </span>,
    ];
  });
  content.pop();

  return (
    <div>
      <Typography>{content}</Typography>
    </div>
  );
};

export default PageDescriptionWithIcon;
