import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    menu: {
      marginTop: theme.spacing(1),
    },
    menuHeader: {
      paddingBottom: theme.spacing(1),
    },
    menuItemHeader: {
      '& .MuiListItem-button:hover': {
        backgroundColor: 'transparent',
      },
      backgroundColor: 'transparent',
    },
    menuItemIcon: {
      paddingRight: 16,
      minWidth: 0,
    },
    signOutRowItem: {
      paddingTop: 12,
    },
    tcContent: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '900px',
      },
    },
  })
);

export default useStyles;
