import React, { ReactElement } from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import { API_STATUS, useAPI } from 'pcc-react-components';
import './App.css';
import theme from './themes/everblue';
import SessionInfo from './components/SessionInfo';
import staticMessages from '../generated/i18n/messages';
import languages from './i18n/languages.json';
import { DEFAULT_LOCALE } from './i18n/constants';
import Interface from './components/Interface';
import ErrorBoundary from './components/ErrorBoundary';

const LOCALE = DEFAULT_LOCALE;

const INITIAL_MESSAGES_STATE = Object.keys(languages).reduce(
  (accum, key) => ({ ...accum, [key]: undefined }),
  {}
);

const App = (): ReactElement => {
  const loadI18nMessagesFromAPI =
    process.env.REACT_APP_LOAD_I18N_TRANSLATIONS_FROM_API === 'true';

  let messages: Record<string, any> = loadI18nMessagesFromAPI
    ? INITIAL_MESSAGES_STATE
    : staticMessages;

  const { status: translationsStatus, data: translationsData } = useAPI(
    '/i18n',
    {},
    loadI18nMessagesFromAPI
  ) as { status: string; data: Record<string, string> };

  if (
    loadI18nMessagesFromAPI &&
    translationsStatus === API_STATUS.COMPLETED &&
    translationsData
  ) {
    messages = Object.entries(languages).reduce(
      (accum, [key, value]) => ({ ...accum, [key]: translationsData[value] }),
      {}
    );
  }

  return (
    <IntlProvider
      locale={LOCALE}
      messages={messages[LOCALE]}
      defaultLocale={DEFAULT_LOCALE}
    >
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Router basename="/ui">
            <SessionInfo>
              <Interface />
            </SessionInfo>
          </Router>
        </ErrorBoundary>
        <CssBaseline />
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
