import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RedirectProps } from 'react-router';
import HistoryIcon from '@material-ui/icons/History';
import AppsIcon from '@material-ui/icons/Apps';
import { Route } from '../../EverGreen/NavBar';
import AllergiesIcon from '../../../images/nav/allergiesIcon';
import PractitionersIcon from '../../../images/nav/practitionersIcon';
import DiagnosesIcon from '../../../images/nav/diagnosesIcon';
import CarePlanIcon from '../../../images/nav/carePlanIcon';
import { CareVaultRouteProps } from '../../Routes';
import ImmunizationIcon from '../../../images/nav/immunizationIcon';
import MedicationsIcon from '../../../images/nav/medicationsIcon';
import DiagnosticReportIcon from '../../../images/nav/diagnosticReportIcon';
import NotesIcon from '../../../images/nav/notesIcon';
import VitalsIcon from '../../../images/nav/vitalsIcon';
import SummaryIcon from '../../../images/nav/SummaryIcon';
import CCDIcon from '../../../images/nav/ccdIcon';

import {
  CareVaultPatient,
  DataElement,
  Patient,
  SetPageTitleDelegate,
} from '../../../model/Model';
import ExcludeAllDataResidentPage from '../../../pages/ResidentHeader/ExcludeAllDataResidentPage';
import { Features } from '../../../states/featuresContextStore';
import ImplantableDevicesIcon from '../../../images/nav/implantableDevicesIcon';
import ProblemsIcon from '../../../images/nav/problemsIcon';

const Allergies = lazy(() => import('../../../pages/Allergies'));
const Practitioners = lazy(() => import('../../../pages/Practitioners'));
const CarePlan = lazy(() => import('../../../pages/CarePlanPage'));
const Diagnoses = lazy(() => import('../../../pages/Diagnoses'));
const Problems = lazy(() => import('../../../pages/Problems'));
const ClinicalNotes = lazy(() => import('../../../pages/ClinicalNotes'));
const DiagnosticReports = lazy(
  () => import('../../../pages/DiagnosticReports')
);
const ImplantableDevices = lazy(
  () => import('../../../pages/ImplantableDevices')
);
const Vitals = lazy(() => import('../../../pages/Vitals'));
const VitalsChart = lazy(() => import('../../../pages/VitalsChart'));
const Summary = lazy(() => import('../../../pages/Summary'));
const Medications = lazy(() => import('../../../pages/Medications'));
const Immunization = lazy(() => import('../../../pages/Immunization'));
const MobileNav = lazy(() => import('../../../pages/MobileNav'));
const MobileResidentPage = lazy(
  () => import('../../../pages/MobileResidentPage')
);
const SettingsPage = lazy(() => import('../../../pages/SettingsPage'));
const MobileFacilityPage = lazy(
  () => import('../../../pages/MobileFacilityPage')
);
const CCDPage = lazy(() => import('../../../pages/CCDPage'));
const AuditLog = lazy(() => import('../../../pages/AuditLog'));
const FhirAppsPage = lazy(() => import('../../../pages/FhirAppsPage'));
const MobileNotificationsPage = lazy(
  () => import('../../../pages/MobileNotificationsPage')
);

const withDefaultRoute = (routes: Route<CareVaultRouteProps>[]) => {
  if (routes.length > 0) {
    return [
      {
        ...routes[0],
        path: Array.isArray(routes[0].path)
          ? ['/', ...routes[0].path!]
          : ['/', routes[0].path!],
      },
      ...routes.slice(1),
    ];
  }
  return routes;
};

const getRoutesAndRedirects = (
  isMobile: boolean,
  setTitle: SetPageTitleDelegate,
  patient: Patient | undefined,
  careVaultPatients: CareVaultPatient[],
  enabledFeatures: Set<Features>
): [Route<CareVaultRouteProps>[], RedirectProps[]] => {
  let routes: Route<CareVaultRouteProps>[] = [];
  const redirects: RedirectProps[] = [];

  const selectedPatient = careVaultPatients.find(
    (f) => f.patientId === patient?.patientId
  );

  const hasAnyConsent =
    selectedPatient &&
    (Object.keys(DataElement) as (keyof typeof DataElement)[]).some(
      (e) => selectedPatient?.dataElementConsentLevels[e] !== 'NONE'
    );

  if (isMobile) {
    routes.push({
      path: '/chart',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.chart"
          defaultMessage="Chart"
        />
      ),
      selectedOnExactPath: true,
      routeComponentProps: {
        render: () => <MobileNav setTitle={setTitle} routes={routes} />,
        exact: true,
      },
    });
    routes.push({
      path: '/mobileResident',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.resident"
          defaultMessage="Resident"
        />
      ),
      selectedOnExactPath: true,
      routeComponentProps: {
        component: MobileResidentPage,
        exact: true,
      },
    });
    routes.push({
      path: '/settings',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.settings"
          defaultMessage="settings"
        />
      ),
      selectedOnExactPath: true,
      routeComponentProps: {
        component: SettingsPage,
        exact: true,
      },
    });
    routes.push({
      path: '/facility',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.facility"
          defaultMessage="facility"
        />
      ),
      selectedOnExactPath: true,
      routeComponentProps: {
        component: MobileFacilityPage,
        exact: true,
      },
    });
    routes.push({
      path: '/notifications',
      label: (
        <FormattedMessage
          id="app.notifications"
          defaultMessage="Notifications"
        />
      ),
      selectedOnExactPath: true,
      routeComponentProps: {
        component: MobileNotificationsPage,
        exact: true,
      },
    });
  } else {
    redirects.push(
      {
        from: '/chart',
        to: '/',
      },
      {
        from: '/mobileResident',
        to: '/',
      },
      {
        from: '/settings',
        to: '/',
      },
      {
        from: '/facility',
        to: '/',
      },
      {
        from: '/notifications',
        to: '/',
      }
    );
  }

  if (
    enabledFeatures.has(Features.SUMMARY) &&
    patient?.patientStatus !== 'Discharged' &&
    selectedPatient &&
    [
      DataElement.OBSERVATIONS,
      DataElement.MEDICATIONS,
      DataElement.DIAGNOSTIC_REPORTS,
      DataElement.NOTES,
    ].some((e) => selectedPatient.dataElementConsentLevels[e] !== 'NONE')
  ) {
    routes.push({
      path: '/summary',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.summary"
          defaultMessage="Overview"
        />
      ),
      selectedOnExactPath: true,
      icon: SummaryIcon,
      routeComponentProps: {
        component: Summary,
        exact: true,
      },
    });
  }

  routes.push(
    {
      path: '/vitals',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.vitals"
          defaultMessage="Vital signs"
        />
      ),
      selectedOnExactPath: true,
      icon: VitalsIcon,
      routeComponentProps: {
        component: enabledFeatures.has(Features.VITALS_CHART)
          ? VitalsChart
          : Vitals,
        exact: true,
      },
      dataElement: DataElement.OBSERVATIONS,
    },
    {
      path: '/clinicalNotes',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.clinicalNotes"
          defaultMessage="Clinical notes"
        />
      ),
      selectedOnExactPath: true,
      icon: NotesIcon,
      routeComponentProps: {
        component: ClinicalNotes,
        exact: true,
      },
      dataElement: DataElement.NOTES,
    },
    {
      path: '/medications',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.medications"
          defaultMessage="Medications"
        />
      ),
      selectedOnExactPath: true,
      icon: MedicationsIcon,
      routeComponentProps: {
        component: Medications,
        exact: true,
      },
      dataElement: DataElement.MEDICATIONS,
    },
    {
      path: '/careplan',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.carePlan"
          defaultMessage="Care plan"
        />
      ),
      selectedOnExactPath: true,
      icon: CarePlanIcon,
      routeComponentProps: {
        component: CarePlan,
        exact: true,
      },
      dataElement: DataElement.CARE_PLANS,
    },
    {
      path: '/diagnosticReports',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.diagnosticReports"
          defaultMessage="Diagnostic reports"
        />
      ),
      selectedOnExactPath: true,
      icon: DiagnosticReportIcon,
      routeComponentProps: {
        component: DiagnosticReports,
        exact: true,
      },
      dataElement: DataElement.DIAGNOSTIC_REPORTS,
    },
    {
      path: '/allergies',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.allergies"
          defaultMessage="Allergies"
        />
      ),
      selectedOnExactPath: true,
      icon: AllergiesIcon,
      routeComponentProps: {
        component: Allergies,
        exact: true,
      },
      dataElement: DataElement.ALLERGIES,
    },
    {
      path: '/immunization',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.immunizations"
          defaultMessage="Immunizations"
        />
      ),
      selectedOnExactPath: true,
      icon: ImmunizationIcon,
      routeComponentProps: {
        component: Immunization,
        exact: true,
      },
      dataElement: DataElement.IMMUNIZATIONS,
    },
    {
      path: '/practitioners',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.practitioners"
          defaultMessage="Practitioners"
        />
      ),
      selectedOnExactPath: true,
      icon: PractitionersIcon,
      routeComponentProps: {
        component: Practitioners,
        exact: true,
      },
      dataElement: DataElement.PRACTITIONERS,
    },
    {
      path: '/diagnoses',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.diagnoses"
          defaultMessage="Diagnoses"
        />
      ),
      selectedOnExactPath: true,
      icon: DiagnosesIcon,
      routeComponentProps: {
        component: Diagnoses,
        exact: true,
      },
      dataElement: DataElement.CONDITIONS,
    }
  );

  if (enabledFeatures.has(Features.PROBLEMS)) {
    routes.push({
      path: '/problems',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.problems"
          defaultMessage="Problems"
        />
      ),
      selectedOnExactPath: true,
      icon: ProblemsIcon,
      routeComponentProps: {
        component: Problems,
        exact: true,
      },
      dataElement: DataElement.PROBLEMS,
    });
  }

  if (enabledFeatures.has(Features.IMPLANTABLE_DEVICES)) {
    routes.push({
      path: '/implantableDevices',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.implantableDevices"
          defaultMessage="Implantable Devices"
        />
      ),
      selectedOnExactPath: true,
      icon: ImplantableDevicesIcon,
      routeComponentProps: {
        component: ImplantableDevices,
        exact: true,
      },
      dataElement: DataElement.IMPLANTABLE_DEVICES,
    });
  }

  if (enabledFeatures.has(Features.CCD)) {
    routes.push({
      path: '/ccd',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.ccd"
          defaultMessage="Continuity of Care Document"
        />
      ),
      selectedOnExactPath: true,
      icon: CCDIcon,
      routeComponentProps: {
        component: CCDPage,
        exact: true,
      },
      dataElement: DataElement.CONDITIONS,
    });
  }

  if (enabledFeatures.has(Features.AUDIT_LOG) && hasAnyConsent) {
    routes.push({
      path: '/auditlog',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.audit-log"
          defaultMessage="Activity log"
        />
      ),
      selectedOnExactPath: true,
      icon: HistoryIcon,
      routeComponentProps: {
        component: AuditLog,
        exact: true,
      },
    });
  }

  if (enabledFeatures.has(Features.FHIRAPPS) && hasAnyConsent) {
    routes.push({
      path: '/external-apps',
      label: (
        <FormattedMessage
          id="app.navbar.menu-item.external-apps"
          defaultMessage="External applications"
        />
      ),
      selectedOnExactPath: true,
      icon: AppsIcon,
      routeComponentProps: {
        component: FhirAppsPage,
        exact: true,
      },
    });
  }

  const dividerRoute = routes.find((r) => {
    switch (r.path) {
      case '/auditlog':
      case '/external-apps':
        return true;
      default:
        return false;
    }
  });
  if (dividerRoute) {
    dividerRoute.dividerBefore = true;
  }

  if (!selectedPatient) {
    routes = withDefaultRoute(routes);
    return [routes, redirects];
  }

  routes = routes.filter((route) => {
    return (
      !route.dataElement ||
      selectedPatient.dataElementConsentLevels[route.dataElement] !== 'NONE'
    );
  });

  if (routes.length === 0 && patient) {
    routes.push({
      path: '/',
      selectedOnExactPath: true,
      routeComponentProps: {
        render: () => <ExcludeAllDataResidentPage patient={patient} />,
        exact: true,
      },
    });
  } else {
    routes = withDefaultRoute(routes);
  }

  return [routes, redirects];
};

export default getRoutesAndRedirects;
