import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    PccUsageAnalytics: {
      trackEvent: (eventName: string, eventAttributes: any) => void;
      init(): void;
      config: {
        'user-id': string;
        'account-id': string;
        'blacklisted-params-list': string[];
        'additional-user-attributes': any;
        'common-custom-event-attributes': any;
        'disable-guides': boolean;
        'pendo-primary-subscription-id': string;
        'pendo-additional-subscription-ids': string[];
      };
    };
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
