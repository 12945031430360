import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return createStyles({
    bg: {
      width: '100vw',
      height: '100vh',
    },
    fgCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    fgCenterMobile: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '0',
      paddingTop: '50%',
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      minHeight: '100%',
      background: theme.palette.background.paper,
    },
    mainContentNoPageFound: {
      paddingTop: theme.spacing(6) + 94,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: theme.palette.background.paper,
    },
    mainContentNoPageFoundMobile: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingTop: 0,
      background: theme.palette.background.paper,
    },
    errorMessage: {
      fontSize: '72px',
      fontWeight: 700,
      lineHeight: 1.2,
      maxWidth: '550px',
    },
    errorMessageMobile: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 1.2,
      maxWidth: '200px',
    },
    refreshButton: {
      marginTop: '30px',
    },
    footer: {
      textAlign: 'center',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      marginBottom: theme.spacing(0.5),
    },
    footerText: {
      marginRight: '0.25em',
    },
    footerLogo: {
      verticalAlign: 'text-bottom',
      height: '120px',
      width: 'auto',
    },
  });
});

export default useStyles;
