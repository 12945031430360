import React, { useMemo } from 'react';
import { Portal } from '@material-ui/core';
import useNotificationStyles from './styles';
import { useIsMobile } from '../../../PagesTemplate/utils/ResponsiveHooks';
import { byPeeking, useNotifications } from '../../../../states/notifications';
import NotificationList from './NotificationList';

const NotificationArea = () => {
  const isMobile = useIsMobile();
  const styles = useNotificationStyles({ isMobile });
  const peekingNotifications = useNotifications((state) =>
    state.notifications.filter(byPeeking(true))
  );

  return (
    <Portal container={document.body}>
      <div className={styles.peekPortal}>
        <NotificationList notifications={peekingNotifications} />
      </div>
    </Portal>
  );
};

export default NotificationArea;
