import React, { ReactElement, useMemo } from 'react';
import { Button, Typography } from 'pcc-react-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { injectIntl, IntlShape } from 'react-intl';
import { Facility } from '../../../model/Model';
import { getTranslation } from '../utils/translations';
import FacilityDetails from './FacilityDetails';

export interface FacilityInfoDialogProps {
  handleClose: () => void;
  isDialogOpen: boolean;
  facility: Facility;
  intl: IntlShape;
}

const FacilityInfoDialog = (props: FacilityInfoDialogProps): ReactElement => {
  const { handleClose, isDialogOpen, facility, intl } = props;
  const translate = useMemo(() => getTranslation(intl), [intl]);

  const facilityDialogActions = (
    <Button
      color="primary"
      label="Close"
      onClick={handleClose}
      size="large"
      variant="contained"
    />
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={isDialogOpen} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h6">{translate.facInfoTitle}</Typography>
      </DialogTitle>

      <DialogContent dividers>
        <FacilityDetails facility={facility} />
      </DialogContent>
      <DialogActions>{facilityDialogActions}</DialogActions>
    </Dialog>
  );
};

export default injectIntl(FacilityInfoDialog);
