import { makeStyles, createStyles } from '@material-ui/core';

const dockStyle = makeStyles((theme) =>
  createStyles({
    dock: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      color: theme.palette.text.black?.disabled,
      boxShadow: '2px -1px 17px 2px rgba(0,0,0,0.20)',
      '& .MuiBottomNavigation-root': {
        height: 'var(--dock-height)',
      },
      '& .Mui-selected': {
        color: theme.palette.primary.dark,
      },
      '& .MuiBottomNavigationAction-label': {
        fontSize: theme.typography.captionM?.fontSize,
        fontWeight: theme.typography.captionM?.fontWeight,
        paddingTop: '2px',
      },
    },
  })
);

export default dockStyle;
