import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function CarePlanIcon(props: SvgIconProps): React.ReactNode {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="nonzero"
        className="navIcon"
        d="M20 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3ZM10 17H5V15H10V17ZM10 13H5V11H10V13ZM10 9H5V7H10V9ZM15 10.5V8H17V10.5H19.5V12.5H17V15.5H15V12.5H12.5V10.5H15Z"
      />
    </SvgIcon>
  );
}
