import { ComparatorFunction } from './types';

type Order = 'asc' | 'desc';

export const createUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function generate(c) {
      // eslint-disable-next-line no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);

      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );

  return uuid;
};

export const isEmpty = (value: any): boolean =>
  value === null ||
  typeof value === 'undefined' ||
  (typeof value === 'string' && value.trim() === '');

const ascendingComparator = <T>(
  a: T,
  b: T,
  orderBy: keyof T,
  sortNullsFirstOnAsc: boolean
) => {
  const isAEmpty = isEmpty(a[orderBy]);
  const isBEmpty = isEmpty(b[orderBy]);

  if (isAEmpty && isBEmpty) {
    return 0;
  }
  if (isAEmpty) {
    return sortNullsFirstOnAsc ? -1 : 1;
  }
  if (isBEmpty) {
    return sortNullsFirstOnAsc ? 1 : -1;
  }

  if (a[orderBy] > b[orderBy]) {
    return 1;
  }
  if (a[orderBy] < b[orderBy]) {
    return -1;
  }

  return 0;
};

export const getComparator = <K extends keyof any>(
  orderDirection: Order,
  orderBy: K,
  customComparator: ComparatorFunction | undefined,
  sortNullsFirstOnAsc = true
): ((a: { [key in K]: any }, b: { [key in K]: any }) => number) => {
  const comparator =
    <T>() =>
    (a: T, b: T, key: keyof T) =>
      typeof customComparator === 'function'
        ? customComparator(a, b)
        : ascendingComparator(a, b, key, sortNullsFirstOnAsc);

  return orderDirection === 'asc'
    ? (a, b) => comparator<any>()(a, b, orderBy)
    : (a, b) => -comparator<any>()(a, b, orderBy);
};

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};
