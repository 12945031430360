import { Typography } from 'pcc-react-components';
import clsx from 'clsx';
import { Email, LocationOn, Phone } from '@material-ui/icons';
import { Link } from '@material-ui/core';
import React, { useMemo } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import testIds from './testIds';
import useStyles from './style';
import {
  addressFmt,
  addressFmtLink,
  emailFmt,
  emailFmtLink,
  telFmt,
  telFmtLink,
} from '../../../utils/addressFmt';
import { getTranslation } from '../utils/translations';
import { Facility } from '../../../model/Model';
import { useIsMobile } from '../../../components/PagesTemplate/utils/ResponsiveHooks';

function FacilityDetails(props: { facility: Facility; intl: IntlShape }) {
  const { facility, intl } = props;
  const isMobile = useIsMobile();

  const styles = useStyles();
  const translate = useMemo(() => getTranslation(intl), [intl]);
  const address = addressFmt(facility);
  const addressLink = addressFmtLink(facility);
  const email = emailFmt(facility.emailAddress);
  const emailLink = emailFmtLink(facility.emailAddress);
  const phone = telFmt(facility.phone);
  const phoneLink = telFmtLink(facility.phone);

  return (
    <>
      <div className={isMobile ? styles.mobileBorder : 'none'}>
        <Typography
          variant="subtitle1"
          className={clsx(styles.row, styles.facilityName, isMobile && 'mob')}
          data-testid={testIds.facilityName}
          style={{ marginBottom: isMobile ? '0px' : '16px' }}
        >
          {facility.facilityName}
        </Typography>
      </div>

      <div style={{ padding: isMobile ? '0px 24px' : '0px' }}>
        {email || phone ? (
          <>
            {address && (
              <Typography variant="body1" className={styles.row}>
                <LocationOn className={styles.icon} />
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  href={addressLink}
                  data-testid={testIds.address}
                >
                  {address}
                </Link>
              </Typography>
            )}
            {email && (
              <Typography variant="body1" className={styles.row}>
                <Email className={styles.icon} />
                <Link href={emailLink} data-testid={testIds.email}>
                  {email}
                </Link>
              </Typography>
            )}
            {phone && (
              <Typography variant="body1" className={styles.row}>
                <Phone className={styles.icon} />
                <Link href={phoneLink} data-testid={testIds.phone}>
                  {phone}
                </Link>
              </Typography>
            )}
          </>
        ) : (
          <Typography
            variant="body1"
            className={styles.row}
            data-testid={testIds.noContactInfo}
          >
            {translate.noContactInfo}
          </Typography>
        )}
      </div>
    </>
  );
}

export default injectIntl(FacilityDetails);
