import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    toolbar: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    logo: {
      padding: 0,
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    headerText: {
      padding: 0,
    },
    regular: {
      minHeight: '64px',
    },
    dense: {
      minHeight: '48px',
    },
    children: {
      marginLeft: 'auto',
      display: 'flex',
    },
    colorDefault: {
      backgroundColor: theme.palette.common.white,
    },
  })
);

export default useStyles;
