import React, { ReactElement } from 'react';
import { PopoverOrigin } from '@material-ui/core';
import AccountMenu from '../../../../components/AccountMenu';
import useUserStore from '../../../../states/userStore';

const ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
} as PopoverOrigin;

const TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
} as PopoverOrigin;

const UserInformation = (): ReactElement => {
  // const user = useSelector(userSelectors.selectUserInfo, shallowEqual);
  const user = useUserStore((s) => s.user.info);

  const userName: string =
    user && Object.hasOwnProperty.call(user, 'username')
      ? user.username.trim()
      : '';

  const handleSignOut = () => {
    window.location.href = '/logout';
  };
  return (
    <AccountMenu
      userName={userName}
      onSignOut={handleSignOut}
      anchorOrigin={ANCHOR_ORIGIN}
      transformOrigin={TRANSFORM_ORIGIN}
    />
  );
};

export default UserInformation;
