import React, { ReactElement, useEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  Button,
  List,
  ListItem,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Typography } from 'pcc-react-components';
import { Skeleton } from '@material-ui/lab';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import { injectIntl, IntlShape } from 'react-intl';
import useUserStore from '../../states/userStore';
import { CareVaultPatient, Facility, Patient } from '../../model/Model';
import patientPickerStyle from './style';
import CareVaultService from '../../service/CareVaultService';
import testIds from './utils/testIds';
import { usePatientContext } from '../../states/patientContextStore';
import { allSettled } from '../../utils/common';
import { useIsMobile } from '../PagesTemplate/utils/ResponsiveHooks';
import useScopedId from '../../utils/useScopedId';

type PatientPickerProps = {
  intl: IntlShape;
  onSelect: (patient: Patient) => void;
};

type PatientOption = {
  patient: Patient;
  facilityName?: string;
};

function populatePatients(
  cvPatients: CareVaultPatient[],
  unavailablePatient: string,
  setPatientOptions: (
    value:
      | ((
          prevState: PatientOption[] | undefined
        ) => PatientOption[] | undefined)
      | PatientOption[]
      | undefined
  ) => void,
  setLoadingPatientCount: (value: number) => void,
  onlyLoadLastVisited: boolean
) {
  if (cvPatients.length > 0) {
    const patientPromises: Promise<Patient>[] = [];
    const facilityPromises: Promise<Facility>[] = [];
    if (onlyLoadLastVisited) {
      patientPromises.push(
        CareVaultService.getPatientData(cvPatients[0].patientId)
      );
      facilityPromises.push(
        CareVaultService.getDataFromProxy<Facility>(
          cvPatients[0].patientId,
          'facility'
        )
      );
    } else {
      cvPatients.forEach((v) => {
        patientPromises.push(CareVaultService.getPatientData(v.patientId));
        facilityPromises.push(
          CareVaultService.getDataFromProxy<Facility>(v.patientId, 'facility')
        );
      });
      setLoadingPatientCount(cvPatients.length - 1);
    }
    (async () => {
      const patients = await allSettled(patientPromises);
      const facilities = await allSettled(facilityPromises);
      const options: PatientOption[] = [];
      for (let i = 0; i < patients.length; i += 1) {
        let isUnavailable = false;
        let patientOption: Patient;
        let facilityName;

        if (
          patients[i].status === 'rejected' ||
          facilities[i].status === 'rejected'
        ) {
          isUnavailable = true;
        }
        if (patients[i].status === 'fulfilled') {
          patientOption = {
            ...(patients[i] as PromiseFulfilledResult<Patient>).value,
            isUnavailable,
          };
        } else {
          patientOption = {
            patientId: cvPatients[i].patientId,
            firstName: unavailablePatient,
            lastName: '',
            isUnavailable: true,
            patientStatus: 'New',
          };
        }
        if (facilities[i].status === 'fulfilled') {
          facilityName = (facilities[i] as PromiseFulfilledResult<Facility>)
            .value.facilityName;
        }
        options.push({
          patient: patientOption,
          facilityName,
        });
      }
      setLoadingPatientCount(0);
      setPatientOptions(options);
    })();
  }
}

const PatientPicker = (props: PatientPickerProps): ReactElement => {
  const { intl, onSelect } = props;
  const patient = usePatientContext((store) => store.patient);

  const unavailablePatient = intl.formatMessage({
    id: 'app.error.unavailablePatient',
  });

  const user = useUserStore((state) => state.user);
  const styles = patientPickerStyle();
  const [patientOptions, setPatientOptions] = useState<PatientOption[]>();
  const [loadingPatientCount, setLoadingPatientCount] = useState<number>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const scopedId = useScopedId();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      user &&
      !user.error.hasError &&
      user.info &&
      user.info.careVaultPatients &&
      Array.isArray(user.info.careVaultPatients)
    ) {
      const cvPatients: CareVaultPatient[] = user.info.careVaultPatients;
      populatePatients(
        cvPatients,
        unavailablePatient,
        setPatientOptions,
        setLoadingPatientCount,
        false
      );
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      user &&
      !user.error.hasError &&
      user.info &&
      user.info.careVaultPatients &&
      Array.isArray(user.info.careVaultPatients)
    ) {
      const cvPatients: CareVaultPatient[] = user.info.careVaultPatients;
      populatePatients(
        cvPatients,
        unavailablePatient,
        setPatientOptions,
        setLoadingPatientCount,
        !isMobile
      );
    }
  }, [unavailablePatient, user, isMobile]);

  if (isMobile && patient) {
    return (
      <List component="nav">
        {patientOptions &&
          patientOptions.map((p) => (
            <ListItem
              key={p.patient.patientId}
              button
              selected={patient.patientId === p.patient.patientId}
              onClick={() => {
                handleClose();
                onSelect(p.patient);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyItems: 'center',
                }}
              >
                <div style={{ alignSelf: 'center' }}>
                  {p.patient.firstName} {p.patient.lastName} ({p.facilityName})
                </div>
                {patient.patientId === p.patient.patientId && (
                  <div style={{ alignSelf: 'center', marginLeft: 'auto' }}>
                    <CheckIcon />
                  </div>
                )}
              </div>
            </ListItem>
          ))}
        {[...Array(loadingPatientCount)].map((e, i) => (
          <Skeleton
            variant="rect"
            key="loading-{i}"
            animation="pulse"
            height="45px"
            className={styles.patientLoading}
            data-testid={testIds.skeleton}
          />
        ))}
      </List>
    );
  }

  if (
    user &&
    !user.error.hasError &&
    user.info &&
    user.info.careVaultPatients &&
    Array.isArray(user.info.careVaultPatients) &&
    user.info.careVaultPatients.length > 1
  ) {
    return patient && patientOptions ? (
      <>
        <Button
          variant="outlined"
          aria-controls={scopedId('patient-menu')}
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          size="large"
          classes={{
            root: styles.patientDropdown,
            label: styles.patientDropdownLabel,
          }}
          data-testid={testIds.button}
        >
          {patient.firstName} {patient.lastName}
        </Button>
        <Menu
          id={scopedId('patient-menu')}
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          data-testid={testIds.menu}
        >
          {patientOptions.map((o) => (
            <MenuItem
              key={o.patient.patientId}
              value={o.patient.patientId}
              selected={patient.patientId === o.patient.patientId}
              onClick={() => {
                handleClose();
                onSelect(o.patient);
              }}
              data-testid={testIds.menuItem}
            >
              {o.patient.firstName} {o.patient.lastName}{' '}
              {o.facilityName && `(${o.facilityName})`}
            </MenuItem>
          ))}

          {[...Array(loadingPatientCount)].map((e, i) => (
            <Skeleton
              key="loading-{i}"
              variant="rect"
              animation="pulse"
              height="36px"
              className={styles.patientLoading}
              data-testid={testIds.skeleton}
            />
          ))}
        </Menu>
      </>
    ) : (
      <Skeleton
        variant="rect"
        animation="pulse"
        width={251}
        height={40}
        className={styles.patientSkeleton}
        data-testid={testIds.skeleton}
      />
    );
  }

  return patient && patientOptions ? (
    <Typography variant="body1" className={styles.patientName}>
      {patientOptions[0].patient.firstName} {patientOptions[0].patient.lastName}
    </Typography>
  ) : (
    <Skeleton
      variant="rect"
      animation="pulse"
      width={251}
      height={40}
      className={styles.patientSkeleton}
      data-testid={testIds.skeleton}
    />
  );
};

export default injectIntl(PatientPicker);
