import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { Button } from 'pcc-react-components';
import useStyles from '../styles';
import { ReactComponent as ChartError } from '../images/chart-error.svg';
import VariantProps from './VariantProps';
import testIds from '../testIds';
import useErrorContext from '../../../states/errorStore';
import { refreshPage } from '../utils/pageUtils';

const ErrorScreenChart = (props: VariantProps) => {
  const { intl, isMobile } = props;
  const line1 = intl.formatMessage({ id: 'app.error.chart.line1' });
  const line2 = intl.formatMessage({ id: 'app.error.chart.line2' });

  const theme = useTheme();
  const styles = useStyles(theme);

  const setErrorState = useErrorContext((s) => s.setErrorState);
  setErrorState(true);

  return (
    <div
      className={styles.mainContent}
      style={
        isMobile ? { paddingTop: 0 } : { paddingTop: 114 + theme.spacing(6) }
      }
    >
      {!isMobile && <ChartError />}
      {isMobile && <ChartError width="244" height="253" />}
      <Typography
        variant="h2"
        component="h2"
        align="center"
        data-testid={testIds.errorMessage}
      >
        {line1}
      </Typography>
      <Typography variant="body2" component="p" align="center">
        {line2}
      </Typography>
      {isMobile && (
        <Button
          variant="contained"
          color="primary"
          className={styles.refreshButton}
          onClick={refreshPage}
          data-testid={testIds.refreshButton}
        >
          Refresh
        </Button>
      )}
    </div>
  );
};

export default injectIntl(ErrorScreenChart);
