export function signOut(): void {
  window.location.href = '/logout';
}

export function goBack(): void {
  window.history.back();
}

export function refreshPage(): void {
  window.location.reload();
}
