import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    contentRoot: {
      height: '100%',
    },
    paperRoot: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
      padding: '16px 24px 16px 24px',
      overflowY: 'auto',
    },
    content: {
      paddingLeft: '8px',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '28px',
    },
    divider: {
      margin: '16px -24px 16px -24px',
    },
    bottomDivider: {
      margin: '16px -24px 0px -24px',
    },
    topDivider: {
      width: '844px',
      height: '16px',
      background: 'rgba(0, 0, 0, 0)',
    },
    labels: {
      color: 'rgba(0, 0, 0, 0.64)',
      fontSize: '0.875rem',
      lineHeight: '28px',
      fontStyle: 'normal',
      whiteSpace: 'nowrap',
    },
    row: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    flex1: {
      flex: 1,
    },
    flex2: {
      flex: 2,
    },
    flex3: {
      flex: 3,
    },
    flex4: {
      flex: 4,
    },
    leftMargin: {
      marginLeft: '2%',
    },
    textOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '500px',
    },
  })
);
