import { CareVaultPatient, DataElement } from '../../model/Model';

const formatTuple = (
  firstValue: string | undefined | null,
  secondValue: string | undefined | null,
  delimiter: string,
  emptyData: string
): string => {
  if (firstValue && secondValue) {
    return `${firstValue}${delimiter}${secondValue}`;
  }
  if (firstValue) {
    return firstValue;
  }
  if (secondValue) {
    return secondValue;
  }
  return emptyData;
};

export const sort = (
  a: string | undefined,
  b: string | undefined,
  nullFirst: boolean,
  isDesc?: boolean
): number => {
  if (a) {
    if (b) {
      return isDesc ? b.localeCompare(a) : a.localeCompare(b);
    }
    // b is null or undefined
    return nullFirst ? 1 : -1;
  }
  // a is null or undefined
  if (b) {
    return nullFirst ? -1 : 1;
  }
  // both null or undefined
  return 0;
};

export function allSettled<T>(
  promises: Promise<T>[]
): Promise<PromiseSettledResult<T>[]> {
  return Promise.all(
    promises.map((promise) =>
      promise
        .then(
          (value) =>
            ({ status: 'fulfilled', value } as PromiseFulfilledResult<T>)
        )
        .catch(
          (reason) => ({ status: 'rejected', reason } as PromiseRejectedResult)
        )
    )
  );
}

export const buildAddress = (
  addressLine1?: string,
  addressLine2?: string,
  city?: string,
  postalCode?: string
): string => {
  let address = addressLine1 || '';
  if (addressLine2) {
    if (address !== '') address += ', ';
    address += addressLine2;
  }
  if (city) {
    if (address !== '') address += ', ';
    address += city;
  }
  if (postalCode) {
    if (address !== '') address += ', ';
    address += postalCode;
  }
  return address;
};

export const hasDataElementAccess = (
  selectedPatient: CareVaultPatient | undefined,
  element: DataElement
): boolean => {
  return selectedPatient?.dataElementConsentLevels[element] !== 'NONE';
};

export default formatTuple;
