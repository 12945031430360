import { createStyles, makeStyles } from '@material-ui/core';
import { HeaderProps } from 'pcc-react-components';

type Props = Pick<HeaderProps, 'variant'> & { titleLength: number };

const isToolbarVariantRegular = (props: Props) => props.variant === 'regular';

const useStyles = makeStyles((theme) => {
  const CONTENT_PADDING = theme.spacing(3);

  return createStyles({
    root: {
      display: 'flex',
      height: '100%',
      '&.mobileRoot': {
        flexDirection: 'column',
      },
    },
    toolbar: (props: Props) => ({
      minHeight: theme.spacing(isToolbarVariantRegular(props) ? 8 : 6),
    }),
    title: (props: Props) => ({
      flexGrow: 1,
      color: 'black',
      fontWeight: 500,
      width: '100%',
      textAlign: 'center',
      lineHeight: '35.2px',
      // select a font size that scales linearly as a function of the title length
      // these are just some hand picked magic numbers that result in longer titles fitting
      fontSize: ((17.5 - 22) / 20) * props.titleLength + 22,
    }),
    content: {
      overflowX: 'auto',
      padding: CONTENT_PADDING,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '&.mobile': {
        padding: '0',
        overflowY: 'auto',
        height: '100%',
        overscrollBehavior: 'contain',
        '&.hasDock': {
          height: 'calc(100% - var(--dock-height))',
        },
        '&.hasResidentHeader': {
          paddingTop: '16px',
        },
      },
    },
    contentWrap: {
      overflow: 'hidden',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: 'var(--app-bar-height)',
      '&.mobile': {
        display: 'block',
        height: 'calc(100% - var(--app-bar-height))',
      },
    },
    errorContent: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
    },
    fabContainer: {
      zIndex: 1200,
      position: 'fixed',
      right: '24px',
      bottom: 'calc( var(--dock-height) + 24px )',
      clipPath: 'inset(-24px)',
      pointerEvents: 'none',
      visibility: 'hidden',
      transition: theme.transitions.create('visibility', {
        duration: 0,
        easing: 'linear',
        delay: theme.transitions.duration.enteringScreen,
      }),
      '&.visible': {
        visibility: 'visible',
        transition: theme.transitions.create('visibility', {
          duration: 0,
          easing: 'linear',
          delay: 0,
        }),
      },
      '& .MuiFab-root': {
        pointerEvents: 'auto',
        transform: 'translateY(200%)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.easeIn,
        }),
      },
      '&.visible .MuiFab-root': {
        transform: 'translateY(0)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeOut,
        }),
      },
    },
    fabBackground: {
      zIndex: 1300,
      backgroundColor: theme.palette.surface?.scrim,
      paddingRight: '24px',
      paddingBottom: 'calc( var(--dock-height) + 48px + 56px )',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      gap: '24px',
    },
    fabExpandedItem: {
      marginRight: '4px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '24px',
    },
    fabTooltip: {
      color: theme.palette.text.black?.highEmphasis,
      backgroundColor: theme.palette.surface?.base,
      padding: '4px 16px',
      borderRadius: '4px',
    },
    primaryFab: {
      color: theme.palette.text.white?.highEmphasis,
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
        color: theme.palette.text.white?.highEmphasis,
        backgroundColor: theme.palette.primary.dark,
      },
    },
    expandedFab: {
      color: theme.palette.text.white?.highEmphasis,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.text.white?.highEmphasis,
        backgroundColor: theme.palette.primary.main,
      },
    },
    appBarIcon: {
      display: 'flex',
      position: 'absolute',
      color: theme.palette.text.black?.medEmphasis,
    },
  });
});

export default useStyles;
