import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { injectIntl, IntlShape } from 'react-intl';
import { Button } from 'pcc-react-components';
import useStyles from '../styles';
import { ReactComponent as NoPageFound } from '../images/no-page-found.svg';
import testIds from '../testIds';
import { useIsMobile } from '../../PagesTemplate/utils/ResponsiveHooks';
import useErrorContext from '../../../states/errorStore';
import { goBack } from '../utils/pageUtils';
import VariantProps from './VariantProps';

type Props = {
  intl: IntlShape;
  isMobile?: boolean;
};

const ErrorScreenNoPageFound = (props: VariantProps) => {
  const { intl, isMobile } = props;
  const line1 = intl.formatMessage({ id: 'app.error.noPageFound.line1' });
  const line2 = intl.formatMessage({ id: 'app.error.noPageFound.line2' });
  const useIsMobileResult = useIsMobile();
  const isMobileRendering = isMobile || useIsMobileResult;
  const theme = useTheme();
  const styles = useStyles(theme);
  const setErrorState = useErrorContext((s) => s.setErrorState);
  setErrorState(true);

  return (
    <div
      className={
        isMobileRendering
          ? styles.mainContentNoPageFoundMobile
          : styles.mainContentNoPageFound
      }
    >
      {!isMobileRendering && <NoPageFound />}
      {isMobileRendering && <NoPageFound width="244" height="253" />}
      <Typography
        variant="h2"
        component="h2"
        align="center"
        data-testid={testIds.errorMessage}
      >
        {line1}
      </Typography>
      <Typography variant="body2" component="p" align="center">
        {line2}
      </Typography>
      {isMobileRendering && (
        <Button
          variant="contained"
          color="primary"
          className={styles.refreshButton}
          onClick={goBack}
          data-testid={testIds.backButton}
        >
          Back
        </Button>
      )}
    </div>
  );
};

export default injectIntl(ErrorScreenNoPageFound);
