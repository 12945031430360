import React, { ReactElement, ReactNode, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import GavelIcon from '@material-ui/icons/Gavel';
import { AcknowledgeDialog } from 'pcc-react-components';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Box, IconButton, PopoverOrigin } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';
import careVaultService from '../../service/CareVaultService';
import useFeaturesContext, {
  Features,
} from '../../states/featuresContextStore';

export type AccountMenuProps = {
  userName?: string;
  children?: ReactNode;
  onSignOut?: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

const AccountMenu = ({
  onSignOut,
  anchorOrigin,
  transformOrigin,
}: AccountMenuProps): ReactElement => {
  const classes = useStyles();
  const [termsAndConditionsContent, setTermsAndConditionsContent] =
    React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

  const handleClick = (event: React.MouseEvent<never, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    if (onSignOut) {
      onSignOut();
    }
  };

  const [open, setOpen] = useState<boolean>(false);

  const openTermsAndCondition = () => {
    (async () => {
      setTermsAndConditionsContent(
        await careVaultService.getTermsAndCondition()
      );
    })();
    setOpen(true);
  };
  const closeTermsAndCondition = () => setOpen(false);

  const pccVersionEnabled = useFeaturesContext((state) =>
    state.enabledFeatures.has(Features.PCC_VERSION)
  );

  return (
    <>
      <AcknowledgeDialog
        className={classes.tcContent}
        buttonLabel="Close"
        buttonSize="large"
        content={
          <div
            style={{ fontSize: '16px', fontWeight: 400 }}
            dangerouslySetInnerHTML={{ __html: termsAndConditionsContent }}
          />
        }
        data-testid="dialog"
        onClick={closeTermsAndCondition}
        open={open}
        title="Terms and Conditions"
      />
      <IconButton
        onClick={handleClick}
        className="appBarIconButton"
        data-testid="account-circle-icon"
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        className={classes.menu}
        data-testid="user-info-row"
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        getContentAnchorEl={null}
        transformOrigin={transformOrigin}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        autoFocus={false}
      >
        <MenuItem
          onClick={openTermsAndCondition}
          classes={{
            root: classes.signOutRowItem,
          }}
          data-testid="terms-conditions"
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <GavelIcon data-testid="terms-conditions-to-app-icon" />
          </ListItemIcon>
          <Typography component="p" variant="subtitle1">
            <FormattedMessage
              id="app.header.menu-item.terms-conditions"
              defaultMessage="Terms and conditions"
            />
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleSignOut}
          classes={{
            root: classes.signOutRowItem,
          }}
          data-testid="sign-out-row"
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <ExitToApp data-testid="exit-to-app-icon" />
          </ListItemIcon>
          <Typography component="p" variant="subtitle1">
            <FormattedMessage
              id="app.header.menu-item.sign-out"
              defaultMessage="Sign out"
            />
          </Typography>
        </MenuItem>
        {pccVersionEnabled && (
          <Box px={2} py={1}>
            <Typography variant="caption" color="textSecondary">
              PCC 4.4.27
            </Typography>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default AccountMenu;
