import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as dateFns from 'date-fns';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@material-ui/core';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { Button } from 'pcc-react-components';
import {
  Notification,
  useNotifications,
} from '../../../../states/notifications';
import useNotificationStyles from './styles';
import { useIsMobile } from '../../../PagesTemplate/utils/ResponsiveHooks';
import { usePatientContext } from '../../../../states/patientContextStore';

const NotificationBubble = ({
  notification,
  inDrawer = false,
}: {
  notification: Notification;
  inDrawer?: boolean;
}) => {
  const { id, text, actions, inProgress } = notification;
  const [closeNotification, removeNotification, setDrawerOpen] =
    useNotifications((state) => [
      state.closeNotification,
      state.removeNotification,
      state.setDrawerOpen,
    ]);
  const isMobile = useIsMobile();
  const styles = useNotificationStyles({ isMobile });
  const history = useHistory();
  const [patient, setPatient, setFacility] = usePatientContext((state) => [
    state.patient,
    state.setPatient,
    state.setFacility,
  ]);

  const hasCloseOverride = useMemo(
    () => actions.some((a) => a.key === 'close'),
    [actions]
  );
  const showCloseButton = useMemo(
    () =>
      !hasCloseOverride && (!inDrawer || (actions.length === 0 && !inProgress)),
    [inDrawer, actions, hasCloseOverride, inProgress]
  );
  const handleCloseButton = useCallback(() => {
    if (inDrawer) {
      removeNotification(id);
    } else {
      closeNotification(id);
    }
  }, [id, inDrawer, closeNotification, removeNotification]);

  const [deferredAction, setDeferredAction] = useState<string>();

  const handleSwitchPatient = useCallback(() => {
    if (!notification.patient || !notification.facility) return;
    setPatient(notification.patient);
    setFacility(notification.facility);
    window.sessionStorage.setItem(
      'selectedPatient',
      notification.patient.patientId.toString()
    );
  }, [notification.facility, notification.patient, setFacility, setPatient]);

  useEffect(() => {
    if (
      deferredAction &&
      notification.patient?.patientId === patient?.patientId
    ) {
      const action = notification.actions.find((a) => a.key === deferredAction);
      setDeferredAction(undefined);
      if (action) {
        action.onClick?.();
        if (action.linkTo) {
          history.push(action.linkTo);
          setDrawerOpen(false);
        }
        removeNotification(id);
      }
    }
  }, [
    deferredAction,
    history,
    id,
    notification,
    patient,
    removeNotification,
    setDrawerOpen,
  ]);

  return (
    <div className={clsx(styles.bubbleOuterPadding, inDrawer && 'inDrawer')}>
      <Paper className={styles.bubble} elevation={inDrawer ? 4 : 8}>
        <div className={styles.bubbleContent}>
          <div className={styles.bubbleText}>
            <Typography variant="caption" color="textSecondary">
              <FormattedRelativeTime
                value={
                  dateFns.differenceInSeconds(notification.date, new Date()) - 1
                }
                updateIntervalInSeconds={10}
              />
            </Typography>
            {text.split('\n').map((line) => (
              <Typography key={line} variant="body2">
                {line}
              </Typography>
            ))}
          </div>
          {inProgress && (
            <CircularProgress size={24} className={styles.spinner} />
          )}
        </div>
        {(actions.length > 0 || showCloseButton) && (
          <div className={styles.bubbleActions}>
            {actions.map((action, index) => (
              <Button
                key={action.key}
                variant={index === 0 ? 'contained' : 'outlined'}
                onClick={() => {
                  if (
                    notification.patient &&
                    notification.patient.patientId !== patient?.patientId
                  ) {
                    setDeferredAction(action.key);
                    return;
                  }
                  action.onClick?.();
                  if (action.linkTo) {
                    history.push(action.linkTo);
                    setDrawerOpen(false);
                  }
                  removeNotification(id);
                }}
              >
                {action.text}
              </Button>
            ))}
            {showCloseButton && (
              <Button
                variant={actions.length > 0 ? 'outlined' : 'contained'}
                onClick={handleCloseButton}
              >
                <FormattedMessage id="app.close" />
              </Button>
            )}
          </div>
        )}
      </Paper>
      <Dialog open={!!deferredAction}>
        <DialogTitle>
          <FormattedMessage
            id="app.notifications.switching.title"
            values={{
              name: `${notification.patient?.firstName} ${notification.patient?.lastName}`,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="app.notifications.switching.message"
            values={{
              name: `${notification.patient?.firstName} ${notification.patient?.lastName}`,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSwitchPatient}>
            <FormattedMessage id="app.continue" />
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setDeferredAction(undefined);
            }}
          >
            <FormattedMessage id="app.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotificationBubble;
