import Paper from '@material-ui/core/Paper/Paper';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import BrowseIcon from './browseIcon';
import FacilityIcon from './facilityIcon';
import SettingsIcon from './settingsIcon';
import testIds from './constants';
import dockStyle from './style';
import useFeaturesContext, {
  Features,
} from '../../states/featuresContextStore';
import SummaryIcon from '../../images/nav/SummaryIcon';
import { usePatientContext } from '../../states/patientContextStore';
import getRoutesAndRedirects from '../Interface/utils/routes';
import useUserStore from '../../states/userStore';
import { Route } from '../EverGreen/NavBar';
import { CareVaultRouteProps } from '../Routes';
import NotificationsIcon from '../../images/NotificationsIcon';

export default function Dock(): JSX.Element {
  const history = useHistory();
  const currentPage = history.location.pathname;
  const theme = useTheme();
  const styles = dockStyle(theme);
  const patient = usePatientContext((state) => state.patient);
  const careVaultPatients = useUserStore(
    (state) => state.user.info?.careVaultPatients
  );
  const [enabledFeatures, featuresLoading] = useFeaturesContext((state) => [
    state.enabledFeatures,
    state.featuresLoading,
  ]);
  const [routes, setRoutes] = useState<Route<CareVaultRouteProps>[]>();

  useEffect(() => {
    if (patient && careVaultPatients && !featuresLoading) {
      const [r] = getRoutesAndRedirects(
        true,
        () => {},
        patient,
        careVaultPatients,
        enabledFeatures
      );
      setRoutes(r);
    }
  }, [
    getRoutesAndRedirects,
    patient,
    careVaultPatients,
    enabledFeatures,
    featuresLoading,
    setRoutes,
  ]);

  const value = useMemo(() => {
    switch (currentPage) {
      case '/facility':
        return 'facility';
      case '/settings':
        return 'settings';
      case '/summary':
        return 'summary';
      case '/notifications':
        return 'notifications';
      default:
        return 'chart';
    }
  }, [currentPage]);

  return (
    <Paper component="nav" className={styles.dock} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_, newValue) => {
          history.push(`/${newValue}`);
        }}
      >
        {routes?.find((r) =>
          Array.isArray(r.path)
            ? r.path.includes('/summary')
            : r.path === '/summary'
        ) && (
          <BottomNavigationAction
            label="Overview"
            icon={<SummaryIcon fill="currentColor" />}
            value="summary"
            data-testid={testIds.summaryBtn}
          />
        )}
        <BottomNavigationAction
          label="Chart"
          icon={<BrowseIcon selected={value === 'chart'} />}
          value="chart"
          data-testid={testIds.chartBtn}
        />
        <BottomNavigationAction
          label="Location"
          icon={<FacilityIcon selected={value === 'facility'} />}
          value="facility"
          data-testid={testIds.locationBtn}
        />
        <BottomNavigationAction
          label="Settings"
          icon={<SettingsIcon selected={value === 'settings'} />}
          value="settings"
          data-testid={testIds.settingsBtn}
        />
        {enabledFeatures.has(Features.CCD) && (
          <BottomNavigationAction
            label={
              <FormattedMessage
                id="app.notifications"
                defaultMessage="Notifications"
              />
            }
            icon={
              <div
                style={{
                  color:
                    value === 'notifications'
                      ? theme.palette.primary.dark
                      : undefined,
                }}
              >
                <NotificationsIcon />
              </div>
            }
            value="notifications"
            data-testid={testIds.notificationsBtn}
          />
        )}
      </BottomNavigation>
    </Paper>
  );
}
