import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function DiagnosesIcon(props: SvgIconProps): React.ReactNode {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M22 13.6667C22 11.8333 20.4953 10.3333 18.6563 10.3333C16.8172 10.3333 15.3126 11.8333 15.3126 13.6667C15.3126 15.25 16.3993 16.5 17.8204 16.9167V20.3333H9.46107V16.1667H11.3837C12.6376 16.1667 13.7243 15.25 13.8915 14L15.229 4.75C15.3126 4.25 15.1454 3.75 14.811 3.41667C14.4766 3.08333 13.9751 2.83333 13.4735 2.83333H11.9689C11.9689 2.33333 11.6345 2 11.1329 2C10.6314 2 10.297 2.33333 10.297 2.83333V4.5C10.297 5 10.6314 5.33333 11.1329 5.33333C11.6345 5.33333 11.9689 5 11.9689 4.5H13.4735L12.136 13.75C12.0524 14.1667 11.7181 14.5 11.3001 14.5H5.86657C5.44861 14.5 5.11424 14.1667 5.03065 13.75L3.77675 4.5H5.28142C5.28142 5 5.6158 5.33333 6.11735 5.33333C6.61891 5.33333 6.95328 5 6.95328 4.5V2.83333C6.95328 2.33333 6.61891 2 6.11735 2C5.6158 2 5.28142 2.33333 5.28142 2.83333H3.77675C3.2752 2.83333 2.77364 3.08333 2.43927 3.41667C2.1049 3.75 1.93771 4.25 2.0213 4.75L3.35879 14C3.60957 15.25 4.69627 16.1667 5.86657 16.1667H7.78921V21.1667C7.78921 21.6667 8.12358 22 8.62514 22H18.6563C19.1578 22 19.4922 21.6667 19.4922 21.1667V16.9167C20.9133 16.5 22 15.25 22 13.6667Z" />
    </SvgIcon>
  );
}
