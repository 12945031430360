import { makeStyles, createStyles } from '@material-ui/core';

const patientPickerStyle = makeStyles(() =>
  createStyles({
    patientDropdown: {
      fontWeight: 400,
      fontSize: '1rem',
      letterSpacing: 'inherit',
      textTransform: 'none',
      marginTop: '8px',
      marginBottom: '8px',
      width: '100%',
    },
    patientDropdownLabel: {
      justifyContent: 'space-between',
    },
    patientSkeleton: {
      marginTop: '8px',
      marginBottom: '15px',
    },
    patientLoading: {
      marginTop: '8px',
    },
    patientName: {
      textAlign: 'center',
    },
  })
);

export default patientPickerStyle;
