import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AppBar, Backdrop, Fab, IconButton, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';

import clsx from 'clsx';
import { useHistory } from 'react-router';
import { Info } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import Header, { HeaderProps } from '../Header';
import { ReactComponent as LogoHeaderTextIcon } from '../../../images/ccc-logo-text-header.svg';
import NavBar, { NavBarProps } from '../NavBar';
import useStyles from './styles';
import ErrorBoundary from '../../ErrorBoundary';
import Dock from '../../Dock';
import { usePatientContext } from '../../../states/patientContextStore';
import ErrorScreen from '../../ErrorScreen';
import useUserStore from '../../../states/userStore';
import NoPatientError from '../../../utils/errors/NoPatientError';
import { useIsMobile } from '../../PagesTemplate/utils/ResponsiveHooks';
import useResponsiveContext from '../../../states/responsiveContextStore';
import ResidentHeader from '../../../pages/ResidentHeader';
import usePageContext from '../../../states/pageContextStore';
import useErrorContext from '../../../states/errorStore';
import testIds from './constants';
import DownloadProvider from '../../DownloadProvider';
import useActionContext from '../../../states/actionContextStore';
import InfoDialog from '../../InfoDialog';
import { useNotifications } from '../../../states/notifications';
import NotificationArea from './notifications/NotificationArea';
import useFeaturesContext, {
  Features,
} from '../../../states/featuresContextStore';

export type MainInterfaceProps<R> = {
  children: ReactNode;
  /** headerProps as used by Header component, omitting `onMenuButtonClick` */
  headerProps?: Omit<HeaderProps, 'onMenuButtonClick'>;
  /** navBarProps as used by NavBar component, omitting `mobileOpen`, `onMobileDrawerClose` and `toolbarVariant` */
  navBarProps: Omit<
    NavBarProps<R>,
    'mobileOpen' | 'onMobileDrawerClose' | 'toolbarVariant'
  >;

  pageTitle?: string;
  pageDescription?: string;
};

const MainInterface = <R,>({
  children,
  headerProps = {},
  navBarProps = {
    routes: [],
  },
  pageTitle,
  pageDescription,
}: MainInterfaceProps<R>): ReactElement => {
  const classes = useStyles({
    variant: headerProps.variant || 'regular',
    titleLength: pageTitle?.length || 0,
  });
  const [
    scrolled,
    setScrolled,
    scrolledDirection,
    setScrolledDirection,
    setScrollPosition,
    updateScrollState,
  ] = useResponsiveContext((state) => [
    state.scrolled,
    state.setScrolled,
    state.scrolledDirection,
    state.setScrolledDirection,
    state.setScrollPosition,
    state.updateScrollState,
  ]);
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(true);
  const isMobile = useIsMobile();
  const intl = useIntl();
  const currentPage = history.location.pathname;
  const chartError = usePatientContext((state) => state.chartError);
  const userError = useUserStore((state) => state.user.error);
  const [setDetailPageOpen, isDetailPageOpen, decDetailPageLevel] =
    usePageContext((state) => [
      state.setDetailPageOpen,
      state.isDetailPageOpen,
      state.decDetailPageLevel,
    ]);
  const [pageDescriptionOpen, setPageDescriptionOpen] = useState(false);
  const [
    showPageActions,
    setShowPageActions,
    pageActions,
    areActionsExpanded,
    setActionsExpanded,
    multiActionIcon,
  ] = useActionContext((state) => [
    state.showPageActions,
    state.setShowPageActions,
    state.pageActions,
    state.areActionsExpanded,
    state.setActionsExpanded,
    state.multiActionIcon,
  ]);
  const notifications = useNotifications((state) => state.notifications);
  const isErrorState = useErrorContext((s) => s.isErrorState);
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);
  const enabledFeatures = useFeaturesContext((state) => state.enabledFeatures);

  const handleDrawerClose = useCallback(() => setMobileOpen(false), []);

  const hasResidentHeader =
    !isDetailPageOpen && ['/', '/chart', '/summary'].includes(currentPage);

  const isTopLevelPage = [
    '/',
    '/chart',
    '/facility',
    '/settings',
    '/summary',
    '/notifications',
  ].includes(currentPage);

  const collapseResidentHeader = scrolled || currentPage === '/summary';

  useEffect(() => {
    setScrolled(false);
    setScrolledDirection('up');
    setScrollPosition(undefined);
  }, [history.location, setScrollPosition, setScrolled, setScrolledDirection]);

  useEffect(() => {
    setDetailPageOpen(false);
    setActionsExpanded(false);
  }, [history.location, setDetailPageOpen, setActionsExpanded]);

  useEffect((): (() => void) | undefined => {
    if (notifications.some((v) => v.tags.includes('inhibit-unload'))) {
      const unloadHandler = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = true;
      };

      window.addEventListener('beforeunload', unloadHandler);
      return () => {
        window.removeEventListener('beforeunload', unloadHandler);
      };
    }
    return undefined;
  }, [notifications]);

  return (
    <div
      className={clsx(classes.root, isMobile ? 'mobileRoot' : 'desktopRoot')}
    >
      {isMobile && pageActions && (
        <div
          className={clsx(
            classes.fabContainer,
            showPageActions &&
              !notifications.find((v) => v.peeking) &&
              scrolledDirection === 'up' &&
              'visible'
          )}
          data-testid={testIds.fabContainer}
        >
          {pageActions.length === 1 ? (
            <Fab
              size="large"
              className={classes.primaryFab}
              onClick={pageActions[0].onClick}
              data-testid={pageActions[0].testId}
              title={pageActions[0].text}
              disabled={pageActions[0].disableButton}
            >
              {pageActions[0].icon}
            </Fab>
          ) : (
            <Fab
              size="large"
              className={classes.primaryFab}
              onClick={() => {
                setActionsExpanded(!areActionsExpanded);
              }}
              data-testid={testIds.multiActionFab}
              title={
                areActionsExpanded
                  ? intl.formatMessage({ id: 'app.hide-actions' })
                  : intl.formatMessage({ id: 'app.show-actions' })
              }
            >
              {areActionsExpanded ? (
                <CloseIcon />
              ) : (
                multiActionIcon ?? <AddIcon />
              )}
            </Fab>
          )}
        </div>
      )}

      {isMobile && pageActions && pageActions.length > 1 && (
        <Backdrop
          open={areActionsExpanded}
          className={classes.fabBackground}
          onClick={() => {
            setActionsExpanded(false);
          }}
        >
          {pageActions.map((pageAction) => (
            <div key={pageAction.key} className={classes.fabExpandedItem}>
              <Typography
                variant="caption"
                className={classes.fabTooltip}
                id={`pageActionLabel-${pageAction.key}`}
              >
                {pageAction.text}
              </Typography>
              <Fab
                size="medium"
                className={classes.expandedFab}
                onClick={pageAction.onClick}
                data-testid={pageAction.testId}
                aria-labelledby={`pageActionLabel-${pageAction.key}`}
                disabled={pageAction.disableButton}
              >
                {pageAction.icon}
              </Fab>
            </div>
          ))}
        </Backdrop>
      )}

      {!isMobile && (
        <Header onMenuButtonClick={handleDrawerToggle} {...headerProps}>
          {headerProps.children}
        </Header>
      )}
      {isMobile && isErrorState && (
        <AppBar
          position="fixed"
          variant="elevation"
          style={{
            backgroundColor: 'white',
            color: 'black',
            paddingBottom: '5px',
            paddingTop: '5px',
          }}
        >
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <LogoHeaderTextIcon style={{ paddingLeft: '24px' }} />
          </div>
        </AppBar>
      )}
      {!userError.hasError ? (
        <ErrorBoundary>
          {isMobile && !isErrorState && (
            <AppBar
              position="fixed"
              variant="elevation"
              style={{
                backgroundColor: 'white',
                color: 'black',
                paddingBottom: '5px',
                paddingTop: '5px',
              }}
            >
              <div
                style={{ display: 'flex', width: '100%', alignItems: 'center' }}
              >
                {(!isTopLevelPage || isDetailPageOpen) && (
                  <IconButton
                    className={classes.appBarIcon}
                    onClick={() => {
                      if (!isDetailPageOpen) {
                        history.goBack();
                      } else {
                        decDetailPageLevel();
                      }
                    }}
                    title={
                      isDetailPageOpen
                        ? intl.formatMessage({ id: 'app.close' })
                        : intl.formatMessage({ id: 'app.back' })
                    }
                  >
                    {!isDetailPageOpen && (
                      <ArrowBackIcon
                        fontSize="medium"
                        data-testid={testIds.arrowBackBtn}
                        data-cy={`Cy_${testIds.arrowBackBtn}`}
                      />
                    )}
                    {isDetailPageOpen && (
                      <CloseIcon
                        fontSize="medium"
                        data-testid={testIds.closeBtn}
                        data-cy={`Cy_${testIds.closeBtn}`}
                      />
                    )}
                  </IconButton>
                )}

                <Typography
                  variant="h6"
                  component="h1"
                  className={classes.title}
                  data-testid={testIds.pageTitle}
                  data-cy={`Cy_${testIds.pageTitle}`}
                >
                  {!pageTitle && <div>Chart</div>}
                  {pageTitle}
                </Typography>

                {pageTitle && pageDescription && (
                  <>
                    <IconButton
                      className={classes.appBarIcon}
                      style={{ right: 0 }}
                      onClick={() => {
                        setPageDescriptionOpen(true);
                      }}
                      title={intl.formatMessage({
                        id: 'app.show-page-description',
                      })}
                    >
                      <Info />
                    </IconButton>
                    <InfoDialog
                      title={pageTitle}
                      message={pageDescription}
                      open={pageDescriptionOpen}
                      onClose={() => setPageDescriptionOpen(false)}
                    />
                  </>
                )}
              </div>
            </AppBar>
          )}
          {!isMobile && (
            <NavBar
              mobileOpen={mobileOpen}
              onMobileDrawerClose={handleDrawerClose}
              toolbarVariant={headerProps.variant}
              {...navBarProps}
            >
              {navBarProps.children}
            </NavBar>
          )}
          {isMobile && hasResidentHeader && (
            <div
              style={{
                width: '100%',
                top: 'var(--app-bar-height)',
                position: 'sticky',
                zIndex: 100,
              }}
            >
              <ResidentHeader
                displayAdditionalInfo
                collapsed={collapseResidentHeader}
              />
            </div>
          )}
          <div className={clsx(classes.contentWrap, isMobile && 'mobile')}>
            <main
              className={clsx(
                classes.content,
                isMobile && 'mobile mobile-container',
                isMobile && 'hasDock',
                hasResidentHeader && 'hasResidentHeader'
              )}
              onScroll={updateScrollState}
            >
              {!chartError ? (
                children
              ) : (
                <Paper style={{ minHeight: '100%' }}>
                  {isMobile && ( // add filer div
                    <div
                      style={{
                        height: '88px',
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                    />
                  )}
                  <ErrorScreen variant="chart-error" />
                </Paper>
              )}
            </main>
            {isMobile && <Dock />}
          </div>
        </ErrorBoundary>
      ) : (
        <div className={classes.errorContent}>
          {isMobile && ( // add filer div
            <div
              style={{
                height: '88px',
                width: '100%',
                backgroundColor: 'white',
              }}
            />
          )}
          <ErrorScreen
            variant={
              userError.error instanceof NoPatientError
                ? 'no-patient'
                : 'default'
            }
          />
        </div>
      )}
      {enabledFeatures.has(Features.CCD) && <NotificationArea />}
      <DownloadProvider />
    </div>
  );
};
export default MainInterface;
