import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Typography, SvgIcon } from '@material-ui/core';
import { Button } from 'pcc-react-components';
import { injectIntl } from 'react-intl';
import useStyles from '../styles';
import { ReactComponent as CCCLogoDark } from '../../../images/ccc-logo-dark.svg';
import defaultSvg from '../images/default.svg';
import VariantProps from './VariantProps';
import testIds from '../testIds';
import useErrorContext from '../../../states/errorStore';
import { refreshPage } from '../utils/pageUtils';

const ErrorScreenDefault = (props: VariantProps) => {
  const { intl, isMobile } = props;
  const message = intl.formatMessage({ id: 'app.error.general' });
  const logoTitle = intl.formatMessage({ id: 'app.header.title' });

  const theme = useTheme();
  const styles = useStyles(theme);

  const setErrorState = useErrorContext((s) => s.setErrorState);
  setErrorState(true);
  return (
    <div
      className={styles.bg}
      style={
        isMobile
          ? {
              background: `${theme.palette.background.paper} url(${defaultSvg}) no-repeat center top`,
              backgroundSize: 'contain',
            }
          : {
              background: `${theme.palette.background.paper} url(${defaultSvg}) no-repeat center`,
            }
      }
    >
      <div className={isMobile ? styles.fgCenterMobile : styles.fgCenter}>
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={isMobile ? styles.errorMessageMobile : styles.errorMessage}
          data-testid={testIds.errorMessage}
        >
          {message}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={styles.refreshButton}
          onClick={refreshPage}
          data-testid={testIds.refreshButton}
        >
          Refresh
        </Button>
      </div>
      {!isMobile && (
        <div className={styles.footer}>
          <SvgIcon
            component={CCCLogoDark}
            viewBox="0 0 715.3 326.4"
            className={styles.footerLogo}
            preserveAspectRatio="xMinYMin meet"
            title={logoTitle}
          />
        </div>
      )}
    </div>
  );
};

export default injectIntl(ErrorScreenDefault);
