import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    content: {
      padding: '16px 24px',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '34px',
    },
    row: {
      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
      },
      marginBottom: '16px',
    },
    textOverflow: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '250px',
    },
    icon: {
      marginRight: '6px',
      fill: 'rgba(0, 0, 0, 0.64)',
    },
    facilityName: {
      fontSize: '18px',
      '&.mob': {
        padding: '8px',
      },
    },
    mobileBorder: {
      padding: '8px 16px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      marginBottom: '22px',
    },
  })
);
