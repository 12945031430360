import { addMinutes, isValid, isAfter, isBefore, isEqual } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  DEFAULT_TIMEZONE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from '../../i18n/constants';
import { TimeDifference } from '../../model/Model';

const INVALID_DATE = 'Invalid date';

const getCurrentDate = (dateFormat: string): string | Date => {
  return dateFormat
    ? format(new Date(), dateFormat || DEFAULT_DATE_FORMAT)
    : new Date();
};

const getFormattedDate = (date: Date, dateFormat: string): string => {
  return isValid(date)
    ? format(date, dateFormat || DEFAULT_DATE_FORMAT)
    : INVALID_DATE;
};

const getFormattedDateWithDefaultFormat = (date: Date): string => {
  return getFormattedDate(date, DEFAULT_DATE_FORMAT);
};

const getFormattedRawDate = (rawDate?: string): string => {
  if (!rawDate || rawDate === '') return '-';
  return getFormattedDateWithDefaultFormat(new Date(rawDate));
};

/**
 * Format date returned by API with no timezone
 * @param rawDate - date to be converted
 * @return formatted date without any timezone conversion
 */
const getFormattedRawDateWithoutTimeZone = (
  rawDate?: string | null,
  dateFormat?: string
): string => {
  if (!rawDate || rawDate === '') return '-';
  const date = new Date(rawDate);

  return getFormattedDate(
    addMinutes(date.getTime(), date.getTimezoneOffset()),
    dateFormat || DEFAULT_DATE_FORMAT
  );
};

const getCurrentDateWithTimezone = (
  dateFormat: string,
  timeZone: string
): string | Date => {
  return format(
    utcToZonedTime(new Date(), timeZone),
    dateFormat || DEFAULT_DATE_TIME_FORMAT
  );
};

const getFormattedDateWithTimezone = (
  date?: Date | string,
  dateFormat?: string,
  timeZone?: string
): string => {
  if (!date) return '-';
  let d = date;
  if (typeof d === 'string') {
    d = new Date(date);
  }
  return isValid(d)
    ? format(
        utcToZonedTime(d, timeZone || DEFAULT_TIMEZONE),
        dateFormat || DEFAULT_DATE_TIME_FORMAT
      )
    : INVALID_DATE;
};

const getAge = (dob?: string): number => {
  if (!dob || dob === '') return 0;
  const today = new Date(Date.now());
  const birthDate = new Date(dob);
  if (!isValid(birthDate)) return 0;
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return today.getFullYear() - birthDate.getFullYear() - 1;
  }
  return today.getFullYear() - birthDate.getFullYear();
};

const getTimeDifference = (
  firstDate: Date,
  secondDate: Date
): TimeDifference => {
  if (!isValid(firstDate) || !isValid(secondDate))
    return { type: 'invalid', value: 0 };
  const diffInSeconds =
    Math.abs(secondDate.getTime() - firstDate.getTime()) / 1000;
  if (diffInSeconds >= 29116800) {
    let years = secondDate.getFullYear() - firstDate.getFullYear();
    if (
      firstDate.getUTCMonth() < secondDate.getUTCMonth() ||
      (firstDate.getUTCMonth() === secondDate.getUTCMonth() &&
        firstDate.getUTCDate() < secondDate.getUTCDate())
    ) {
      years += 1;
    }
    return { type: years > 1 ? 'years' : 'year', value: years };
  }
  if (diffInSeconds > 2419200) {
    let months =
      (secondDate.getFullYear() - firstDate.getFullYear()) * 12 +
      secondDate.getMonth() -
      firstDate.getMonth() +
      (secondDate.getUTCDate() <= firstDate.getUTCDate() ? 0 : 1);
    if (months === 0) {
      months = 1;
    }
    if (months === 12) return { type: 'year', value: 1 };
    return { type: months > 1 ? 'months' : 'month', value: months };
  }
  if (diffInSeconds > 518400) {
    const weeks = Math.ceil(diffInSeconds / 604800);
    return { type: weeks > 1 ? 'weeks' : 'week', value: weeks };
  }
  if (diffInSeconds > 82800) {
    const days = Math.ceil(diffInSeconds / 86400);
    return { type: days > 1 ? 'days' : 'day', value: days };
  }
  if (diffInSeconds > 3540) {
    const hours = Math.ceil(diffInSeconds / 3600);
    return { type: hours > 1 ? 'hours' : 'hour', value: hours };
  }
  const minutes = Math.ceil(diffInSeconds / 60);
  return { type: minutes > 1 ? 'minutes' : 'minute', value: minutes };
};

type Interval = {
  seconds: number;
  singular: string;
  plural: string;
  short: string;
};

const intervals: Interval[] = [
  {
    seconds: 1,
    singular: ' second',
    plural: ' seconds',
    short: 's',
  },
  {
    seconds: 60,
    singular: ' minute',
    plural: ' minutes',
    short: 'm',
  },
  {
    seconds: 3600,
    singular: ' hour',
    plural: ' hours',
    short: 'h',
  },
  {
    seconds: 86400,
    singular: ' day',
    plural: ' days',
    short: 'd',
  },
  {
    seconds: 604800,
    singular: ' week',
    plural: ' weeks',
    short: 'w',
  },
  {
    seconds: 2592000,
    singular: ' month',
    plural: ' months',
    short: 'mo',
  },
  {
    seconds: 31536000,
    singular: ' year',
    plural: ' years',
    short: 'y',
  },
];

const relativeTime = (date: Date, short?: boolean): string => {
  const seconds = Math.floor((date.getTime() - Date.now()) / 1000);

  let interval = intervals[0];

  for (let i = 0; i < intervals.length; i += 1) {
    interval = intervals[i];
    if (i === intervals.length - 1) break;
    const nextInterval = intervals[i + 1];
    if (Math.floor(Math.abs(seconds) / nextInterval.seconds) < 1) break;
  }

  const num = Math.floor(Math.abs(seconds) / interval.seconds);

  if (num === 0) {
    return 'now';
  }

  let str = '';

  if (seconds > 0) {
    str += 'in ';
  }

  str += num;

  if (!short && num === 1) {
    str += interval.singular;
  } else {
    str += short ? interval.short : interval.plural;
  }
  return str;
};
const isBeforeOrEqual = (d: Date, compareTo: Date) =>
  isBefore(d, compareTo) || isEqual(d, compareTo);
const isAfterOrEqual = (d: Date, compareTo: Date) =>
  isAfter(d, compareTo) || isEqual(d, compareTo);
const inRange = (d: Date, low: Date, high: Date) =>
  isAfterOrEqual(d, low) && isBeforeOrEqual(d, high);

export {
  getCurrentDate,
  getFormattedDate,
  getCurrentDateWithTimezone,
  getFormattedDateWithTimezone,
  getFormattedDateWithDefaultFormat,
  getFormattedRawDate,
  getFormattedRawDateWithoutTimeZone,
  getAge,
  getTimeDifference,
  relativeTime,
  isBeforeOrEqual,
  isAfterOrEqual,
  inRange,
};
