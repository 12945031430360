import { makeStyles, createStyles } from '@material-ui/core';

const ResidentHeaderStyle = makeStyles((styles) =>
  createStyles({
    page: {
      padding: '24px 24px 20px 24px',
      display: 'flex',
      flexDirection: 'column',
    },
    pageMobile: {
      flexDirection: 'row',
      padding: '16px',
      alignItems: 'center',
    },
    residentDetailMobile: {
      flex: 1,
      marginLeft: '15px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    name: {
      fontSize: '1.1rem',
      fontWeight: 500,
    },
    dobRow: {
      padding: '8px 0px 4px 0px',
    },
    physicianRow: {
      padding: '4px 0px 4px 0px',
    },
    labels: {
      color: 'rgba(0, 0, 0, 0.64)',
      fontSize: '0.875rem',
      lineHeight: '20px',
      fontStyle: 'normal',
    },
    content: {
      paddingLeft: '8px',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '20px',
    },
    mobileResidentRow: {
      display: 'flex',
    },
    mobileResidentContent: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    photo: {
      width: '72px',
      borderRadius: '5px',
      margin: '0 auto 8px',
      transition: '0.2s',
      '&.photoCollapse': {
        width: '40px',
        borderRadius: '1px',
      },
    },
    facilityButton: {
      color: styles.palette.primary.dark,
      paddingLeft: '0px',
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    additionalInfo: {
      margin: '12px 70px 0px 58px',
    },
    photoSkeleton: {
      width: '72px',
      height: '72px',
      borderRadius: '5px',
      margin: '0 auto 8px',
      '&.photoCollapse': {
        width: '40px',
        height: '40px',
        borderRadius: '1px',
      },
    },
    patientSkeleton: {
      margin: '4px 27px 16px 21px',
    },
    photoMobile: {
      margin: 0,
    },
  })
);

export default ResidentHeaderStyle;
