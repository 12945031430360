import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    secondaryColor: {
      backgroundColor: '#FFFFFF',
      color: '#0D48A1',
      padding: '10px 6px',
      margin: '5px',
    },
  })
);
