import React, { Component, ErrorInfo, ReactElement, ReactNode } from 'react';
import ErrorScreen, { ErrorScreenVariants } from '../ErrorScreen';
import NoPatientError from '../../utils/errors/NoPatientError';

type Props = {
  /** Component or string to display in ErrorMessage component when an error is thrown */
  children: ReactNode;
};

type State = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
    });
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render(): ReactElement {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error || errorInfo) {
      let variant: ErrorScreenVariants = 'default';
      if (error instanceof NoPatientError) {
        variant = 'no-patient';
      }
      return <ErrorScreen variant={variant} />;
    }

    return children as ReactElement;
  }
}

export default ErrorBoundary;
