/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CircularLoader} from 'pcc-react-components';
import apiCall from '../../utils/apiCall.ts';
import useUserStore from "../../states/userStore";
import ErrorScreen from "../ErrorScreen";
import NoPatientError from "../../utils/errors/NoPatientError";
import {AppBar} from "@material-ui/core";
import {ReactComponent as LogoHeaderTextIcon} from "../../images/ccc-logo-text-header.svg";
import { useIsMobile } from '../PagesTemplate/utils/ResponsiveHooks';
import { BroadcastChannel } from 'broadcast-channel';
import checkToken from './checkTokenUtil';
import useUsageConfigStore from "../../states/usageConfigStore";



const SessionInfo = ({children}) => {
    const [hasConfigError, setHasConfigError] = useState(false);
    const [usageConfig, setUsageConfig] = useUsageConfigStore(state => [state.usageConfig, state.setUsageConfig]);
    // const dispatch = useDispatch();
    // const user = useSelector(userSelectors.selectUserInfo, shallowEqual);
    // const userError = useSelector(userSelectors.selectUserError, shallowEqual);
    const user = useUserStore(state => state.user.info)
    const userError = useUserStore(state => state.user.error)
    const isMobile = useIsMobile();
    const getUser = useUserStore(state => state.getUser);

    /* istanbul ignore next */
    const getSessionInfo = useCallback(async () => {
        try {
            const {
                data: {appVersion, buildNumber, commitNumber, uuConfig, csrfToken}
            } = await apiCall.get('/config', {
                baseURL: process.env.REACT_APP_INFRASTRUCTURE_BASE_URL
            });
            if (
                csrfToken &&
                csrfToken.headerName &&
                csrfToken.parameterName &&
                csrfToken.token
            ) {
                const {token} = csrfToken;
                apiCall.interceptors.request.use(
                    config => {
                        const newConfig = {...config};
                        newConfig.headers.headerName = token;
                        newConfig.data = {
                            ...config.data,
                            parameterName: token
                        };
                        return newConfig;
                    },
                    error => Promise.reject(error)
                );
            }
            // eslint-disable-next-line no-console
            console.log(`Version: ${appVersion}`);
            // eslint-disable-next-line no-console
            console.log(`Build Number: ${buildNumber}`);
            // eslint-disable-next-line no-console
            console.log(`Commit Number: ${commitNumber}`);
            setUsageConfig(uuConfig);
            // dispatch(userActions.getUser());
            // dispatch(terminologyActions.getTerminology());
            getUser();
        } catch (error) {
            setHasConfigError(true);
        }
    }, [getUser]);

    useEffect(() => {
        let tokenReceived = false;

        setTimeout(() => {
            const isOK = tokenReceived || checkToken();
            if (isOK) getSessionInfo();
        }, 100);

        // create channel and request information
        const channel = new BroadcastChannel('cv', { webWorkerSupport: true });
        // define listener to listen on message
        const handler = message => {
            if (message.requestToken) {
                const token = window.sessionStorage.getItem("cv_token")
                if (token) {
                    channel.postMessage({token: token});
                }
            } else if (message.token && !tokenReceived) {
                window.sessionStorage.setItem("cv_token", message.token);
                tokenReceived = true;
            }
        }
        channel.addEventListener('message', handler);
        channel.postMessage({requestToken: true});
    }, []);

    if (hasConfigError || (userError.hasError && !(userError.error instanceof NoPatientError))) {
        return (
            <div>
                {isMobile && (
                    <AppBar
                        position="fixed"
                        variant="elevation"
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            paddingBottom: '5px',
                            paddingTop: '5px',
                        }}
                    >
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <LogoHeaderTextIcon style={{ paddingLeft: '24px' }} />
                        </div>
                    </AppBar>
                )}
                {isMobile && <div style={{height: '88px', width: '100%', backgroundColor: 'white'}} />}
                <ErrorScreen/>
            </div>
        );
    }

    if (
        (!user && !userError.hasError) ||
        (!usageConfig && !hasConfigError)
    ) {
        return <CircularLoader height="100%"/>;
    }

    return children;
};

SessionInfo.propTypes = {
    children: PropTypes.node
};

SessionInfo.defaultProps = {
    children: undefined
};

export default SessionInfo;
