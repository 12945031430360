import { Address } from '../model/Model';

const addressFmt = (address: Address): string =>
  [
    address.addressLine1,
    address.addressLine2,
    address.addressLine3,
    address.city,
    [address.state, address.postalCode].filter((v) => !!v).join(' '),
    address.country,
  ]
    .filter((v) => !!v)
    .join(', ')
    .trim();

const addressFmtLink = (address: Address): string => {
  const addressStr = addressFmt(address);
  return addressStr
    ? `https://www.google.com/maps/search/${encodeURIComponent(addressStr)}`
    : '';
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const emailFmt = (email?: string): string | undefined => {
  if (!email) return undefined;
  return emailRegex.test(email) ? email : undefined;
};

const emailFmtLink = (email?: string): string => {
  if (!email) return '';
  return emailRegex.test(email) ? `mailto:${email}` : '';
};

const telFmt = (phone?: string): string | undefined => {
  if (!phone) return undefined;
  return phone;
};

const telFmtLink = (phone?: string): string => {
  if (!phone) return '';
  return `tel:${phone}`;
};

export {
  addressFmt,
  addressFmtLink,
  emailFmt,
  emailFmtLink,
  telFmt,
  telFmtLink,
};
