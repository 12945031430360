import React, { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { CircularLoader } from 'pcc-react-components';
import { injectIntl, IntlShape } from 'react-intl';
import apiCall from '../../utils/apiCall';
import InfoDialog from '../InfoDialog';
import useDownloadContext from '../../states/downloadContextStore';
import testIds from './testIds';

const getTranslation = (intl: IntlShape) => ({
  error: intl.formatMessage({ id: 'app.error' }),
  downloadFailed: intl.formatMessage({ id: 'app.download.failed' }),
  downloadPreparing: intl.formatMessage({ id: 'app.download.preparing' }),
  pleaseWait: intl.formatMessage({ id: 'app.download.please-wait' }),
});

const DownloadProvider = ({ intl }: { intl: IntlShape }) => {
  const setDownloadHandler = useDownloadContext(
    (state) => state.setDownloadHandler
  );
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(false);
  const translate = useMemo(() => getTranslation(intl), [intl]);

  useEffect(() => {
    setDownloadHandler(async (url, filename, params) => {
      if (downloading) return;
      setDownloading(true);
      try {
        const response = await apiCall.get(url, {
          params,
          responseType: 'blob',
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        const link = document.createElement('a') as HTMLAnchorElement;
        const objectURL = URL.createObjectURL(blob) as string;
        link.href = objectURL;
        link.download = filename;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(objectURL);
      } catch (ex) {
        setError(true);
      } finally {
        setDownloading(false);
      }
    });
  }, [setDownloadHandler, downloading]);

  return (
    <>
      <Dialog open={downloading && !error} data-testid={testIds.loadingDialog}>
        <DialogTitle disableTypography>
          <Typography component="h2" variant="h6" align="center">
            {translate.downloadPreparing}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            {translate.pleaseWait}
          </DialogContentText>
          <CircularLoader />
        </DialogContent>
      </Dialog>
      <InfoDialog
        open={error}
        variant="failure"
        onClose={() => setError(false)}
        title={translate.error}
        message={translate.downloadFailed}
        testId={testIds.errorDialog}
      />
    </>
  );
};

export default injectIntl(DownloadProvider);
