import { Route } from './types';

type ButtonId = {
  buttonId?: string;
};

type AddButtonIds<T> = (
  list: Readonly<T[]>,
  delimiter?: string,
  prefixIndex?: number | string
) => (T & ButtonId)[];

const addButtonIds: AddButtonIds<Route<any>> = (
  list,
  delimiter = '-',
  prefixIndex = undefined
) => {
  const newList = list.map((item, index) => {
    const delimitedIndex =
      prefixIndex !== undefined
        ? prefixIndex + delimiter + index
        : index.toString();
    if (item.routes !== undefined) {
      return {
        ...item,
        buttonId: delimitedIndex,
        routes: addButtonIds(item.routes, delimiter, delimitedIndex),
      };
    }

    return {
      ...item,
      buttonId: delimitedIndex,
    };
  });

  return newList;
};

const trimIdFromEndByDelimiter = (str: string, delimiter = '-'): string =>
  str.substring(0, str.lastIndexOf(delimiter));

type ExpanderStates = Record<string, boolean>;

/**
 * Calculates the last closed group's buttonId of the selected/clicked button
 * @param id
 * @param expanderStates
 * @param setStateFunction
 */
const getClosedGroupButtonIdOfSelectedButton = (
  id: string,
  expanderStates: ExpanderStates,
  setStateFunction: React.Dispatch<React.SetStateAction<string>>,
  allParentGroups?: ExpanderStates[]
): void => {
  const parentGroups: Record<string, boolean>[] = allParentGroups || [];
  const newId = trimIdFromEndByDelimiter(id);
  if (newId !== '') {
    parentGroups.push({ [newId]: expanderStates[newId] });
    getClosedGroupButtonIdOfSelectedButton(
      newId,
      expanderStates,
      setStateFunction,
      parentGroups
    );
  } else {
    const closedParentGroups = parentGroups.filter(
      (group) => Object.values(group)[0] === false
    );
    const temp =
      closedParentGroups.length && closedParentGroups.slice(-1).pop()
        ? closedParentGroups.slice(-1).pop()
        : {};
    setStateFunction(
      closedParentGroups.length &&
        closedParentGroups.slice(-1).pop() !== undefined
        ? Object.keys(temp || {})[0]
        : ''
    );
  }
};

export {
  addButtonIds,
  trimIdFromEndByDelimiter,
  getClosedGroupButtonIdOfSelectedButton,
};
export type { ButtonId, ExpanderStates };
