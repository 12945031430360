import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function ImplantableDevicesIcon(
  props: SvgIconProps
): React.ReactNode {
  return (
    <SvgIcon width="24" height="24" viewBox="0 96 960 960" {...props}>
      <path d="M260 976q-58 0-99-41t-41-99V519q-27-26-53.5-52.5T40 402q0-27 19.5-46.5T106 336q16 0 29.5 7t24.5 18q11-11 24.5-18t29.5-7q27 0 46.5 19.5T280 402q0 38-26.5 64.5T200 519v317q0 25 17.5 42.5T260 896q25 0 42.5-17.5T320 836V376q0-83 58.5-141.5T520 176q83 0 141.5 58.5T720 376v4q69 14 114.5 68.5T880 576v200q0 83-58.5 141.5T680 976q-83 0-141.5-58.5T480 776V576q0-73 45.5-127.5T640 380v-4q0-50-35-85t-85-35q-50 0-85 35t-35 85v460q0 58-41 99t-99 41Zm420-80q50 0 85-35t35-85V576q0-50-35-85t-85-35q-50 0-85 35t-35 85v200q0 50 35 85t85 35Zm0-200q-33 0-56.5 23.5T600 776q0 33 23.5 56.5T680 856q33 0 56.5-23.5T760 776q0-33-23.5-56.5T680 696Zm0 80Z" />
    </SvgIcon>
  );
}
