import axios from 'axios';

import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: 30 * 60 * 1000, // 30  mins
  exclude: {
    query: false,
    paths: [/^\/?audittrail\//],
  },
});

const apiCall = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  adapter: cache.adapter,
});

apiCall.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      document.getElementsByTagName('body')[0].innerHTML = 'Loading...';
      try {
        await apiCall.get<string>('/login-path').then((result) => {
          window.location.href = result.data;
        });
      } catch (e) {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
    return Promise.resolve();
  }
);

export default apiCall;
