import React, { useMemo } from 'react';
import { useTransition, animated } from '@react-spring/web';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { Notification } from '../../../../states/notifications';
import { useIsMobile } from '../../../PagesTemplate/utils/ResponsiveHooks';
import useNotificationStyles from './styles';
import NotificationBubble from './NotificationBubble';

const NotificationList = ({
  notifications,
  inDrawer,
}: {
  notifications: Notification[];
  inDrawer?: boolean;
}) => {
  const isMobile = useIsMobile();
  const styles = useNotificationStyles({ isMobile });
  const refMap = useMemo(() => new WeakMap(), []);

  const transitions = useTransition(notifications, {
    keys: (item) => [item.id, item.text].join(','),
    from: {
      opacity: 0,
      transform:
        isMobile || inDrawer ? 'translate(0%, 0%)' : 'translate(100%, 0%)',
      maxHeight: 0,
    },
    initial: {
      opacity: 1,
      transform: 'translate(0%, 0%)',
    },
    enter: (item) => async (next) => {
      await next({
        opacity: 1,
        transform: 'translate(0%, 0%)',
        maxHeight: refMap.get(item).scrollHeight,
      });
    },
    leave: (item) => async (next) => {
      await next({
        opacity: 0,
        transform:
          isMobile || inDrawer ? 'translate(0%, 0%)' : 'translate(100%, 0%)',
        maxHeight: 0,
      });
    },
    onRest: (_result, state, item) => {
      if (refMap.has(item)) {
        const element = refMap.get(item);
        element.style.zIndex = 1;
        element.style.maxHeight =
          (state.get().maxHeight as number) > 0 ? null : 0;
      }
    },
    onStart: (_result, state, item) => {
      if (refMap.has(item)) {
        const element = refMap.get(item);
        element.style.zIndex = 0;
      }
    },
  });

  return (
    <Box component="ul" className={clsx(styles.list, inDrawer && 'inDrawer')}>
      {transitions((style, notification) => (
        <animated.li
          style={style}
          ref={(ref: HTMLLIElement) => ref && refMap.set(notification, ref)}
        >
          <NotificationBubble notification={notification} inDrawer={inDrawer} />
        </animated.li>
      ))}
    </Box>
  );
};

export default NotificationList;
