import create from 'zustand';
import { useEffect, useRef, useState } from 'react';

export type PageContextState = {
  isDetailPageOpen: boolean;
  setDetailPageOpen: (isDetailPageOpen: boolean) => void;
  detailPageLevel: number;
  setDetailPageLevel: (detailPageLevel: number) => void;
  incDetailPageLevel: () => void;
  decDetailPageLevel: () => void;
};

const usePageContext = create<PageContextState>((set, get) => ({
  isDetailPageOpen: false,
  setDetailPageOpen: (isDetailPageOpen: boolean) => {
    let { detailPageLevel } = get();
    if (isDetailPageOpen && detailPageLevel === 0) {
      detailPageLevel = 1;
    } else if (!isDetailPageOpen && detailPageLevel > 0) {
      detailPageLevel = 0;
    }
    set({ isDetailPageOpen, detailPageLevel });
  },
  detailPageLevel: 0,
  setDetailPageLevel: (detailPageLevel: number) => {
    set({
      isDetailPageOpen: detailPageLevel > 0,
      detailPageLevel: Math.max(detailPageLevel, 0),
    });
  },
  incDetailPageLevel: () => {
    const detailPageLevel = get().detailPageLevel + 1;
    set({ isDetailPageOpen: true, detailPageLevel });
  },
  decDetailPageLevel: () => {
    const detailPageLevel = Math.max(get().detailPageLevel - 1, 0);
    let isDetailPageOpen = true;
    if (detailPageLevel === 0) {
      isDetailPageOpen = false;
    }
    set({ isDetailPageOpen, detailPageLevel });
  },
}));

export const useOnDetailPageClose = (
  callback: (newLevel: number, initialLevel: number) => void
) => {
  const detailPageLevelRef = useRef<number>(
    usePageContext.getState().detailPageLevel
  );
  const initialLevelRef = useRef(detailPageLevelRef.current);
  useEffect(
    () =>
      usePageContext.subscribe((state) => {
        if (state.detailPageLevel < detailPageLevelRef.current) {
          callback(state.detailPageLevel, initialLevelRef.current);
        }
        detailPageLevelRef.current = state.detailPageLevel;
      }),
    [callback]
  );
};

export default usePageContext;
