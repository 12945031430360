import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function NotesIcon(props: SvgIconProps): React.ReactNode {
  return (
    <SvgIcon {...props} width="24" viewBox="0 0 24 24" height="24">
      <path
        fillRule="nonzero"
        className="navIcon"
        d="M19 3H4.99C3.89 3 3 3.9 3 5l.01 14c0 1.1.89 2 1.99 2h10l6-6V5c0-1.1-.9-2-2-2zM7 8h10v2H7V8zm5 6H7v-2h5v2zm2 5.5V14h5.5L14 19.5z"
        transform="translate(-16 -384) translate(16 384)"
      />
    </SvgIcon>
  );
}
