import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function MedicationsIcon(props: SvgIconProps): React.ReactNode {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M9 2C10.0111 2 10.8667 2.714 11.1933 3.7H14.4444C15.3 3.7 16 4.465 16 5.4V10L7 19H3.55556C2.7 19 2 18.235 2 17.3V5.4C2 4.465 2.7 3.7 3.55556 3.7H6.80667C7.13333 2.714 7.98889 2 9 2ZM11.0005 10.0003H5.00013V11.5003H11.0005V10.0003ZM13.0005 7.00017H5.00013V8.50017H13.0005V7.00017ZM9.00031 4.00007C8.45028 4.00007 8.00026 4.45008 8.00026 5.0001C8.00026 5.55012 8.45028 6.00014 9.00031 6.00014C9.55033 6.00014 10.0003 5.55012 10.0003 5.0001C10.0003 4.45008 9.55033 4.00007 9.00031 4.00007ZM19.2578 10C19.9586 10.0031 20.6585 10.2714 21.1933 10.8061C22.2689 11.8818 22.2689 13.6256 21.1932 14.7014L17.9475 17.9472L14.7018 21.193C14.1639 21.7308 13.4591 21.9997 12.7542 21.9997C12.0494 21.9997 11.3445 21.7308 10.8067 21.193C9.73109 20.1174 9.73109 18.3735 10.8067 17.2979L17.2982 10.8061C17.4148 10.6895 17.5405 10.5881 17.6712 10.4966C18.1398 10.1691 18.686 10.0022 19.2343 10H19.2578ZM14.3836 15.05L11.5409 17.8922C10.8197 18.6135 10.8197 19.7871 11.5409 20.5082C11.8905 20.8576 12.355 21.05 12.8492 21.05C13.3434 21.05 13.808 20.8576 14.1575 20.5082L17 17.6658L14.3836 15.05Z" />
    </SvgIcon>
  );
}
