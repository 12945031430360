import {
  AppBar,
  AppBarProps,
  PropTypes,
  Toolbar,
  ToolbarProps,
  Typography,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { Button } from 'pcc-react-components';
import useStyles from './styles';

export type HeaderProps = ToolbarProps & {
  headerText?: React.ReactNode;
  logo?: React.ReactElement;
  color?: PropTypes.Color | 'transparent';
  classes?: Record<
    'appbar' | 'headerText' | 'logo' | 'toolbar' | 'children',
    string
  >;
  rootProps?: AppBarProps;
  onLogoClick?: (event: React.MouseEvent<any, MouseEvent>) => void;
  onMenuButtonClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  children?: React.ReactNode;
};

const Header = ({
  headerText,
  logo,
  color = 'default',
  classes,
  onLogoClick,
  onMenuButtonClick,
  children,
  rootProps,
  variant = 'regular',
  ...toolbarProps
}: HeaderProps): React.FunctionComponentElement<HeaderProps> => {
  const baseClasses = useStyles();

  return (
    <AppBar
      position="fixed"
      color={color}
      {...rootProps}
      className={clsx(
        baseClasses.root,
        'evergreen-header',
        classes && classes.appbar,
        rootProps && rootProps.className
      )}
      classes={{
        colorDefault: baseClasses.colorDefault,
      }}
    >
      <Toolbar
        variant={variant}
        {...toolbarProps}
        className={clsx(
          baseClasses.toolbar,
          classes && classes.toolbar,
          toolbarProps.className
        )}
        classes={{
          dense: baseClasses.dense,
          regular: baseClasses.regular,
        }}
      >
        <Button
          variant="icon"
          aria-label="open drawer"
          className={baseClasses.menuButton}
          edge="start"
          onClick={onMenuButtonClick}
          size="small"
        >
          <MenuIcon />
        </Button>
        {logo && (
          <Button
            variant="icon"
            className={clsx(baseClasses.logo, classes && classes.logo)}
            aria-label="logo"
            onClick={onLogoClick}
            role="link"
          >
            {logo}
          </Button>
        )}
        {headerText && (
          <Typography
            variant="h3"
            noWrap
            className={clsx(
              baseClasses.headerText,
              classes && classes.headerText
            )}
          >
            {headerText}
          </Typography>
        )}
        <div
          className={clsx(baseClasses.children, classes && classes.children)}
        >
          {children}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
