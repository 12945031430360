import { createStyles, makeStyles } from '@material-ui/core';

type Props = { isMobile: boolean };

const useNotificationStyles = makeStyles((theme) => {
  return createStyles({
    peekPortal: ({ isMobile }: Props) => ({
      position: 'fixed',
      top: '48px', // desktop --app-bar-height
      right: 0,
      zIndex: 1250,
      pointerEvents: 'none',
      ...(isMobile && {
        top: 'auto',
        bottom: '52px', // --dock-height
        width: '100%',
      }),
    }),
    drawerPaper: {
      display: 'flex',
      flexDirection: 'column',
    },
    drawerContent: {
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    drawerEmptyMessage: {
      padding: theme.spacing(1.5),
      paddingTop: 0,
      textAlign: 'center',
    },
    drawerActions: {
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(0.5),
    },
    list: ({ isMobile }: Props) => ({
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginBottom: 0,
      padding: 0,
      width: '380px',
      '&.inDrawer': {
        marginTop: theme.spacing(1.5),
      },
      '& > li': {
        listStyle: 'none',
      },
      ...(isMobile && {
        flexDirection: 'column-reverse',
        width: '100%',
      }),
    }),
    bubbleOuterPadding: {
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      '&.inDrawer': {
        padding: `0 ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
      },
    },
    bubble: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      pointerEvents: 'auto',
    },
    bubbleContent: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    bubbleText: {
      flex: 1,
    },
    spinner: {
      color: theme.palette.text.black?.medEmphasis,
    },
    bubbleActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(2),
    },
  });
});

export default useNotificationStyles;
