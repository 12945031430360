import create from 'zustand';
import React from 'react';

export type DownloadHandler = (
  url: string,
  filename: string,
  params?: unknown
) => void;

type DownloadContextState = {
  downloadHandler?: DownloadHandler;
  setDownloadHandler: (downloadHandler: DownloadHandler) => void;
};

const useDownloadContext = create<DownloadContextState>((set) => ({
  downloadHandler: undefined,
  setDownloadHandler: (downloadHandler) => {
    set({ downloadHandler });
  },
}));

export const useDownloadHandlerRef = () => {};

export default useDownloadContext;
