import React, { ReactElement, useEffect, useState } from 'react';
import {
  AcknowledgeDialog,
  Button,
  CircularLoader,
  DialogWrapper,
} from 'pcc-react-components';
import { injectIntl, IntlShape } from 'react-intl';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { RedirectProps } from 'react-router';
import Routes, { CareVaultRouteProps } from '../Routes';
import getRoutesAndRedirects from './utils/routes';
import UserInformation from '../../features/user/components/UserInformation';
import ResidentHeader from '../../pages/ResidentHeader';
import MainInterface from '../EverGreen/MainInterface';
import { NavBarProps, Route } from '../EverGreen/NavBar';
import CCCHeaderLogo from '../../images/ccc-logo-header.svg';
import useUserStore from '../../states/userStore';
import {
  CareVaultPatient,
  Facility,
  Patient,
  SetPageTitleDelegate,
} from '../../model/Model';
import { usePatientContext } from '../../states/patientContextStore';
import useStyles from './styles';

import { useIsMobile } from '../PagesTemplate/utils/ResponsiveHooks';
import CareVaultService from '../../service/CareVaultService';
import { allSettled } from '../../utils/common';
import ChartDataError from '../../utils/errors/ChartDataError';
import NoPatientError from '../../utils/errors/NoPatientError';
import useFeaturesContext, {
  Features,
  mapFeatureKeyToEnum,
} from '../../states/featuresContextStore';
import { HeaderProps } from '../EverGreen/Header';
import NotificationDrawer from '../EverGreen/MainInterface/notifications/NotificationDrawer';
import useUsageConfigStore from '../../states/usageConfigStore';

const LOGOUT_TIME = 30;
const WARNING_TIME = 29;

const renderListItemComponent = (path: string | string[], isExact = false) =>
  path
    ? // eslint-disable-next-line react/display-name
      React.forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>(
        (props, ref) => {
          return (
            <NavLink
              ref={ref}
              {...(props as NavLinkProps)}
              to={Array.isArray(path) ? path[0] : path}
              exact={isExact}
              activeClassName="Mui-selected"
            />
          );
        }
      )
    : 'div';

export const SELECTED_PATIENT_KEY = 'selectedPatient';

const Interface = (props: { intl: IntlShape }): ReactElement => {
  const { intl } = props;
  const styles = useStyles();
  const appTitle = intl.formatMessage({ id: 'app.header.title' });
  const unavailablePatient = intl.formatMessage({
    id: 'app.error.unavailablePatient',
  });
  const [logoLoaded, setLogoLoaded] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>();
  const [pageDescription, setPageDescription] = useState<string>();
  const isMobile = useIsMobile();

  const [user, userError, setUser] = useUserStore((state) => [
    state.user.info,
    state.user.error,
    state.setUser,
  ]);
  const [patient, setPatient] = usePatientContext((state) => [
    state.patient,
    state.setPatient,
  ]);
  const usageConfig = useUsageConfigStore((state) => state.usageConfig);
  const [
    enabledFeatures,
    setEnabledFeatures,
    featuresLoading,
    setFeaturesLoading,
    enableFeature,
  ] = useFeaturesContext((state) => [
    state.enabledFeatures,
    state.setEnabledFeatures,
    state.featuresLoading,
    state.setFeaturesLoading,
    state.enableFeature,
  ]);
  const setFacility = usePatientContext((state) => state.setFacility);
  const setChartError = usePatientContext((state) => state.setChartError);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [openLogout, setLogout] = useState<boolean>(false);

  const handleSignOut = () => {
    window.location.href = '/logout';
  };
  const handleOnIdle = () => {
    setOpenWarning(true);
  };
  const handleLogOut = () => {
    setLogout(true);
  };

  useIdleTimer({
    timeout: 1000 * 60 * WARNING_TIME,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
  });

  useIdleTimer({
    timeout: 1000 * 60 * LOGOUT_TIME,
    onIdle: handleLogOut,
    debounce: 500,
    crossTab: true,
  });

  const closeWarningWindow = () => {
    setOpenWarning(false);
  };

  const closeLogoutWindow = () => {
    setLogout(false);
    handleSignOut();
  };

  const content = <div>Your session will time out soon due to inactivity.</div>;
  const actions = (
    <div>
      <Button
        color="primary"
        label="Continue session"
        onClick={closeWarningWindow}
        size="large"
        variant="contained"
        style={{ marginRight: '16px' }}
      />
      <Button
        classes={{ root: styles.secondaryColor }}
        color="secondary"
        label="sign out"
        onClick={handleSignOut}
        size="large"
        variant="contained"
      />
    </div>
  );

  const headerProps: HeaderProps = {
    variant: 'dense',
    logo: (
      <img
        src={CCCHeaderLogo}
        alt={appTitle}
        style={{
          height: logoLoaded ? '32px' : '0',
          transition: 'height 0.3s ease-in-out',
        }}
        onLoad={() => {
          setLogoLoaded(true);
        }}
      />
    ),
    children: (
      <>
        {enabledFeatures.has(Features.CCD) && <NotificationDrawer />}
        <UserInformation />
      </>
    ),
  };

  const careVaultPatients = user?.careVaultPatients;

  const [routes, setRoutes] = useState<Route<CareVaultRouteProps>[]>();
  const [redirects, setRedirects] = useState<RedirectProps[]>();
  const [navBarProps, setNavBarProps] =
    useState<NavBarProps<CareVaultRouteProps>>();

  const setPageTitleDelegate: SetPageTitleDelegate = (
    title: string,
    description?: string
  ) => {
    setPageTitle(title);
    setPageDescription(description);
    document.title = `${intl.formatMessage({
      id: 'app.header.title',
      defaultMessage: 'Connected Care Center',
    })} - ${title}`;
  };

  useEffect(() => {
    (async () => {
      if (
        user &&
        !userError.hasError &&
        user.careVaultPatients &&
        Array.isArray(user.careVaultPatients)
      ) {
        const cvPatients: CareVaultPatient[] = user.careVaultPatients;
        if (cvPatients.length > 0) {
          let { patientId } = cvPatients[0];
          const selectedPatient =
            window.sessionStorage.getItem(SELECTED_PATIENT_KEY);
          if (selectedPatient !== null) {
            const selectedPatientId = parseInt(selectedPatient, 10);
            if (cvPatients.find((p) => p.patientId === selectedPatientId)) {
              patientId = selectedPatientId;
            }
          }
          window.sessionStorage.setItem(
            SELECTED_PATIENT_KEY,
            patientId.toString()
          );
          const patientResult = CareVaultService.getPatientData(patientId);
          const facResult = CareVaultService.getDataFromProxy<Facility>(
            patientId,
            'facility',
            {
              includeOptionalFields: 'clinicalConfiguration',
            }
          );
          await (async () => {
            const [patientData, facilityData] = await allSettled<
              Patient | Facility
            >([patientResult, facResult]);
            if (patientData.status === 'fulfilled') {
              setPatient(patientData.value as Patient);
            } else {
              setPatient({
                patientId,
                firstName: unavailablePatient,
                lastName: '',
                isUnavailable: true,
                patientStatus: 'New',
              });
              setChartError(
                new ChartDataError('Failed to load current patient')
              );
            }
            if (facilityData.status === 'fulfilled') {
              setFacility(facilityData.value as Facility);
            } else {
              setFacility(undefined);
              setChartError(
                new ChartDataError('Failed to load current facility')
              );
            }
          })();
        } else {
          setUser({
            error: {
              hasError: true,
              error: new NoPatientError(),
            },
          });
        }
      }
    })();
  }, [
    user,
    setPatient,
    setFacility,
    setChartError,
    userError.hasError,
    unavailablePatient,
    setUser,
  ]);

  useEffect(() => {
    if (patient) {
      window.sessionStorage.setItem(
        SELECTED_PATIENT_KEY,
        patient.patientId.toString()
      );
    }
  }, [patient]);

  useEffect(() => {
    if (
      user &&
      patient &&
      careVaultPatients &&
      careVaultPatients.length > 0 &&
      usageConfig &&
      process.env.REACT_APP_USE_MOCK !== 'true' &&
      window.PccUsageAnalytics
    ) {
      const selectedPatient = careVaultPatients.find(
        (cvp) => cvp.patientId === patient.patientId
      );
      if (selectedPatient) {
        const { primarySubscriptionId } = usageConfig;
        window.PccUsageAnalytics.config['pendo-primary-subscription-id'] =
          primarySubscriptionId;

        window.PccUsageAnalytics.config['user-id'] = user.username;
        window.PccUsageAnalytics.config['account-id'] = selectedPatient.orgCode;
        window.PccUsageAnalytics.init();
      }
    }
  }, [user, careVaultPatients, usageConfig, patient]);

  useEffect(() => {
    if (
      (patient && careVaultPatients && !featuresLoading) ||
      userError.hasError
    ) {
      const [r, rd] = getRoutesAndRedirects(
        isMobile,
        setPageTitleDelegate,
        patient,
        careVaultPatients ?? [],
        enabledFeatures
      );
      setRoutes(r);
      setRedirects(rd);
      setNavBarProps({
        routes: r,
        renderListItemComponent,
        startAdornment: (
          <ResidentHeader
            displayAdditionalInfo={
              !(r.length === 1 && r[0].dataElement === undefined)
            }
            collapsed={false}
          />
        ),
        width: 300,
      });
    }
  }, [
    patient,
    careVaultPatients,
    isMobile,
    enabledFeatures,
    featuresLoading,
    userError.hasError,
  ]);

  useEffect(() => {
    setFeaturesLoading(true);
    setEnabledFeatures(new Set<Features>());
  }, [patient, setEnabledFeatures, setFeaturesLoading]);

  useEffect(() => {
    (async () => {
      if (!patient) return;
      try {
        const enabledFeatureKeys = await CareVaultService.getEnabledFeatures(
          patient.patientId
        );
        enabledFeatureKeys.forEach((key) => {
          const feature = mapFeatureKeyToEnum(key);
          if (feature !== null) {
            enableFeature(feature);
          }
        });
        setFeaturesLoading(false);
      } catch (e) {
        setChartError(new ChartDataError('Failed to load'));
      }
    })();
  }, [enableFeature, setChartError, setFeaturesLoading, patient]);

  return routes && navBarProps && redirects ? (
    <MainInterface
      headerProps={headerProps}
      navBarProps={navBarProps}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
    >
      <AcknowledgeDialog
        buttonLabel="Close"
        buttonSize="large"
        content="Your session timed out due to inactivity. "
        data-testid="dialog"
        onClick={closeLogoutWindow}
        onClose={closeLogoutWindow}
        open={openLogout}
        title="Session expired"
        style={{ height: isMobile ? 'fit-content' : '' }}
      />
      <DialogWrapper
        actions={actions}
        content={content}
        data-testid="dialog"
        open={openWarning}
        title="Session timeout"
        style={{ height: isMobile ? 'fit-content' : '' }}
      />
      <Routes
        routes={routes}
        redirects={redirects}
        setTitle={setPageTitleDelegate}
      />
    </MainInterface>
  ) : (
    <CircularLoader height="100%" />
  );
};

export default injectIntl(Interface);
