import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function SummaryIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z" />
    </SvgIcon>
  );
}
