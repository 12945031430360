import { makeStyles, createStyles } from '@material-ui/core';

const infoDialogStyle = makeStyles((theme) =>
  createStyles({
    titleIcon: {
      verticalAlign: 'middle',
      marginRight: '16px',
      '&.error': {
        color: theme.palette.error.main,
      },
      '&.success': {
        color: theme.palette.success.main,
      },
    },
    title: {
      verticalAlign: 'middle',
    },
    icon: {
      verticalAlign: 'text-bottom',
    },
  })
);

export default infoDialogStyle;
