const infoDialogTestIds = {
  failureIcon: 'infoDialogFailureIcon',
  successIcon: 'infoDialogSuccessIcon',
  title: 'infoDialogTitle',
  message: 'infoDialogMessage',
  okButton: 'infoDialogOkButton',
  retryButton: 'infoDialogRetryButton',
  cancelButton: 'infoDialogCancelButton',
};

export default infoDialogTestIds;
