import create from 'zustand';

type UsageConfig = {
  primarySubscriptionId: string;
  secondarySubscriptionId: string;
};

type UsageConfigState = {
  usageConfig: UsageConfig;
  setUsageConfig: (usageConfig: UsageConfig) => void;
};

const useUsageConfigStore = create<UsageConfigState>((set) => ({
  usageConfig: {
    primarySubscriptionId: '',
    secondarySubscriptionId: '',
  },
  setUsageConfig: (usageConfig) => set({ usageConfig }),
}));

export default useUsageConfigStore;
