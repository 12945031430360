import { IntlShape } from 'react-intl';

const getTranslation = (intl: IntlShape) => ({
  facilityName: intl.formatMessage({
    id: 'app.pages.ResidentHeader.facilityName',
  }),
  additionalInfo: intl.formatMessage({
    id: 'app.pages.ResidentHeader.additionalInfo',
  }),
  dob: intl.formatMessage({
    id: 'app.pages.ResidentHeader.dob',
  }),
  gender: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.gender',
  }),
  primaryDiagnosis: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.primaryDiagnosis',
  }),
  physician: intl.formatMessage({
    id: 'app.pages.ResidentHeader.physician',
  }),
  status: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.status',
  }),
  contactInformation: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.contactInformation',
  }),
  homePhone: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.homePhone',
  }),
  emailAddress: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.emailAddress',
  }),
  previousAddress: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.previousAddress',
  }),
  personalInformation: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.personalInformation',
  }),
  maritalStatus: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.maritalStatus',
  }),
  ethnicity: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.ethnicity',
  }),
  language: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.language',
  }),
  facInfoTitle: intl.formatMessage({
    id: 'app.pages.ResidentHeader.FacilityInfoDialog.title',
  }),
  noContactInfo: intl.formatMessage({
    id: 'app.pages.ResidentHeader.FacilityInfoDialog.noContactInfo',
  }),
  unavailablePatient: intl.formatMessage({
    id: 'app.error.unavailablePatient',
  }),
  suffix: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.suffix',
  }),
  middleName: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.middleName',
  }),
  maidenName: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.maidenName',
  }),
  race: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.race',
  }),
  smokingStatus: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.smokingStatus',
  }),
  preferredLanguage: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.preferredLanguage',
  }),
  showDetails: intl.formatMessage({
    id: 'app.show-details',
  }),
  residentHeaderLabel: intl.formatMessage({
    id: 'app.navbar.label.resident-header',
  }),
  present: intl.formatMessage({
    id: 'app.pages.ResidentHeader.ResidentInfoDialog.smokingStatus.present',
  }),
  failed: intl.formatMessage({
    id: 'app.pages.loadFailed',
  }),
});

// eslint-disable-next-line import/prefer-default-export
export { getTranslation };
