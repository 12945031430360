import {
  Collapse,
  Divider,
  Drawer,
  Hidden,
  List,
  ToolbarProps,
} from '@material-ui/core';
import { match } from 'path-to-regexp';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
// eslint-disable-next-line import/no-cycle
import NavBarListItem, { RenderListItemComponent } from '../NavBarListItem';
// eslint-disable-next-line import/no-cycle
import useStyles from './styles';
import { Route } from './types';
import {
  addButtonIds,
  ButtonId,
  ExpanderStates,
  getClosedGroupButtonIdOfSelectedButton,
} from './utils';

type RenderNavListItems<R> = (
  routesArray: (Route<R> & ButtonId)[],
  renderListItemComponent: RenderListItemComponent,
  expanderStates: {
    [selector: string]: boolean;
  },
  onExpanderClick: (path: string) => void,
  closedGroupButtonIdOfSelectedButton: string,
  navBarWidth: number,
  onNavItemClick: (buttonId: string) => void,
  depth: number,
  classes: Record<string, string>,
  rootItemHasIcon: boolean
) => React.ReactElement[];

export type NavBarProps<R> = {
  routes: Route<R>[];
  /**
   * Base URL for all locations, such as `/ui`, if you have specified one in your routing library.
   */
  baseURL?: string;
  /**
   * Children are rendered in the footer section of the `NavBar`
   */
  children?: ReactNode;
  /**
   * startAdornment is rendered in the header section of the `NavBar`
   */
  startAdornment?: ReactNode;
  /**
   * Flag to show hide the mobile menu version. Logic should be handled by the parent component,
   * like `MainInterface`.
   */
  mobileOpen?: boolean;
  /**
   * Method to invoke when `mobileOpen` is `true` when any navigation button is pressed. Typically
   * used for the close mobile menu logic in the parent component, like `MainInterface`
   */
  onMobileDrawerClose?: () => void;
  /** Function to render the component used in `ListItem`. Since this component is not tied to any
   * routing library implementations, you must specify the component to override the `ListItem`
   * `component` prop to achieve menu button highlighting when selected and routing. This method is used in
   * conjunction with the `routes` prop array.
   *
   *  Here is an example using the `react-router-dom` package:
   * ```javascript
   * renderListItemComponent = (path: string | string[], isExact = false) =>
   path
   ? React.forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>(
   (props, ref) => {
            return (
              <NavLink
                ref={ref}
                {...(props as NavLinkProps)}
                to={Array.isArray(path) ? path[0] : path}
                exact={isExact}
                activeClassName="Mui-selected"
              />
            );
          }
   )
   : 'div',
   * ```
   */
  renderListItemComponent?: RenderListItemComponent;
  /* Toolbar variant to calculate top offset of the ```NavBar``` to accommodate the Header */
  toolbarVariant?: ToolbarProps['variant'];
  /* Numeric width for navigation */
  width?: number;
};

export const NAVBAR_DEFAULT_WIDTH = 256;

const NavBar = <R,>({
  routes,
  baseURL = '',
  children,
  startAdornment,
  mobileOpen = false,
  onMobileDrawerClose,
  renderListItemComponent = () => 'div',
  toolbarVariant = 'regular',
  width = NAVBAR_DEFAULT_WIDTH,
}: NavBarProps<R>): ReactElement => {
  const menuHasIcons = useMemo(
    () => routes.some((route) => route.icon),
    [routes]
  );

  const mappedRoutes = useMemo(() => addButtonIds(routes), [routes]);

  const renderNavListItems: RenderNavListItems<R> = useCallback(
    (
      routesArray,
      renderListItemComponentFunc,
      expanderStates,
      onExpanderClick,
      closedGroupButtonIdOfSelectedButton,
      navBarWidth,
      onNavItemClick,
      depth,
      classes,
      rootItemHasIcon
    ) => {
      return routesArray
        .filter((route) => {
          return !!route.label;
        })
        .map((route, index) => {
          const {
            path = '',
            buttonId,
            label = undefined,
            selectedOnExactPath = false,
            icon = undefined,
            routes: subRoutes = undefined,
            dividerBefore,
          } = route;

          return (
            <div key={buttonId}>
              {((buttonId &&
                Boolean(expanderStates[buttonId]) &&
                depth === 0 &&
                index !== 0) ||
                dividerBefore) && <Divider className={classes?.divider} />}
              <NavBarListItem
                path={path}
                label={label}
                icon={icon}
                renderListItemComponent={renderListItemComponentFunc}
                selectedOnExactPath={selectedOnExactPath}
                routes={subRoutes}
                rootItemHasIcon={rootItemHasIcon}
                expanderOpen={
                  buttonId ? Boolean(expanderStates[buttonId]) : false
                }
                navBarWidth={navBarWidth}
                onExpanderClick={onExpanderClick}
                onNavItemClick={onNavItemClick}
                depth={depth}
                buttonId={buttonId}
                forceHighlight={
                  buttonId === closedGroupButtonIdOfSelectedButton
                }
              >
                {Object.hasOwnProperty.call(route, 'routes') && (
                  <Collapse
                    in={buttonId ? Boolean(expanderStates[buttonId]) : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      dense
                      role="list"
                      aria-label="sub navigation"
                    >
                      {route.routes &&
                        renderNavListItems(
                          route.routes,
                          renderListItemComponentFunc,
                          expanderStates,
                          onExpanderClick,
                          closedGroupButtonIdOfSelectedButton,
                          navBarWidth,
                          onNavItemClick,
                          depth + 1,
                          classes,
                          rootItemHasIcon
                        )}
                    </List>
                  </Collapse>
                )}
              </NavBarListItem>
              {Boolean(buttonId ? Boolean(expanderStates[buttonId]) : false) &&
                depth === 0 &&
                index !== routesArray.length - 1 && (
                  <Divider className={classes?.divider} />
                )}
            </div>
          );
        });
    },
    []
  );

  const createExpanderState = useCallback(
    (routesArray: (Route<R> & ButtonId)[]) => {
      if (typeof routesArray === 'undefined') {
        return {};
      }

      const obj = routesArray.reduce((accum, route) => {
        const expanderStates: ExpanderStates = { ...accum };
        const { buttonId, routes: nestedRoutes } = route;
        if (typeof nestedRoutes !== 'undefined' && buttonId) {
          expanderStates[buttonId] = false;
          Object.assign(expanderStates, createExpanderState(nestedRoutes));
        }

        return expanderStates;
      }, {});

      return obj;
      // exhaustive deps erroneously includes type generic as dependency
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const classes = useStyles({ toolbarVariant, width });
  const [expanderStates, setExpanderStates] = useState<ExpanderStates>(
    createExpanderState(mappedRoutes)
  );
  const [selectedButtonId, setSelectedButtonId] = useState<string>('');
  const [
    closedGroupButtonIdOfSelectedButton,
    setClosedGroupButtonIdOfSelectedButton,
  ] = useState<string>('');

  /** TODO: Remove this implementation for setSelectedButonId in 2.0.0 as it is now handled by the useEffect below, which handles on page load events */
  const handleNavItemClick = useCallback(
    (buttonId: string) => {
      if (mobileOpen && onMobileDrawerClose) onMobileDrawerClose();
      setSelectedButtonId(buttonId);
    },
    [mobileOpen, onMobileDrawerClose]
  );

  const handleExpanderClick = useCallback((buttonId) => {
    setExpanderStates((prevState) => ({
      ...prevState,
      [buttonId]: !prevState[buttonId],
    }));
  }, []);

  const intl = useIntl();

  const renderDrawer = (
    <>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {startAdornment && (
          <div className={classes.startAdornment}>
            {startAdornment}
            <Divider />
          </div>
        )}

        <nav
          className={classes.menuNav}
          aria-label={intl.formatMessage({ id: 'app.navbar.label.main-nav' })}
        >
          {renderNavListItems(
            mappedRoutes,
            renderListItemComponent,
            expanderStates,
            handleExpanderClick,
            closedGroupButtonIdOfSelectedButton,
            width,
            handleNavItemClick,
            0,
            classes,
            menuHasIcons
          )}
        </nav>
      </div>
      {children && (
        <div className={classes.children}>
          <Divider />
          {children}
        </div>
      )}
    </>
  );

  useEffect(() => {
    const checkRoutes = (myRoutes: typeof mappedRoutes = mappedRoutes) => {
      if (!window.location.pathname) {
        return;
      }
      myRoutes.find((route) => {
        const pathMatch = match(route.path || '', {
          decode: decodeURIComponent,
        });
        if (pathMatch(window.location.pathname.replace(baseURL, ''))) {
          setSelectedButtonId(route.buttonId ? route.buttonId : '');

          return true;
        }
        if (route.routes) {
          checkRoutes(route.routes);
        }

        return false;
      });
    };
    checkRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, mappedRoutes, baseURL]);

  useEffect(() => {
    if (selectedButtonId) {
      getClosedGroupButtonIdOfSelectedButton(
        selectedButtonId,
        expanderStates,
        setClosedGroupButtonIdOfSelectedButton
      );
    }
  }, [selectedButtonId, expanderStates]);

  useEffect(() => {
    if (selectedButtonId) {
      setExpanderStates((prevState) =>
        Object.entries(prevState)
          .map(
            ([key]) =>
              [
                key,
                selectedButtonId.indexOf(key) === 0 && selectedButtonId !== key,
              ] as const
          )
          .reduce(
            (accum, [newKey, newValue]) => ({
              ...accum,
              [newKey]: newValue,
            }),
            {}
          )
      );
    }
  }, [selectedButtonId]);

  return (
    <div className={classes.root}>
      <Hidden smUp>
        <Drawer
          data-testid="main-navigation-mobile-drawer"
          variant="temporary"
          open={mobileOpen}
          onClose={onMobileDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {renderDrawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          data-testid="main-navigation-drawer"
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {renderDrawer}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default NavBar;
export * from './types';
