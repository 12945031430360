import { createUUID } from '../../EverGreen/DataTable/utils';

export default () => {
  let isOK = true;

  // generate first token when logging in
  if (document.cookie && document.cookie.indexOf('cv_logged_in=true') >= 0) {
    const newToken = createUUID();
    window.sessionStorage.setItem('cv_token', newToken);
  } else if (!window.sessionStorage.getItem('cv_token')) {
    // all sessions are closed, open new session
    isOK = false;
    window.location.href = '/logout';
  }

  document.cookie = `cv_logged_in=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
  return isOK;
};
