import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Typography } from 'pcc-react-components';
import { injectIntl, IntlShape } from 'react-intl';
import { Skeleton } from '@material-ui/lab';
import { IconButton, Paper } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import testIds from './utils/constants';
import {
  Condition,
  DataElement,
  DataWithPaging,
  Facility,
  Patient,
  Practitioner,
} from '../../model/Model';
import {
  getAge,
  getFormattedRawDateWithoutTimeZone,
} from '../../utils/dateUtils';
import ResidentHeaderStyle from './style';
import CareVaultService, {
  getEmptyDataWithPaging,
} from '../../service/CareVaultService';
import patientPhotoPlaceholder from '../../images/patientPhoto.svg';
import ResidentInfoDialog from './ResidentInfoDialog';
import { getTranslation } from './utils/translations';
import PatientPicker from '../../components/PatientPicker';
import FacilityInfoDialog from './FacilityInfoDialog';
import { usePatientContext } from '../../states/patientContextStore';
import ChartDataError from '../../utils/errors/ChartDataError';
import { useIsMobile } from '../../components/PagesTemplate/utils/ResponsiveHooks';
import useUserStore from '../../states/userStore';
import useFeaturesContext, {
  Features,
} from '../../states/featuresContextStore';
import { hasDataElementAccess } from '../../utils/common';
import usePageContext from '../../states/pageContextStore';

interface ResidentHeaderProps {
  collapsed: boolean;
  displayAdditionalInfo: boolean;
  intl: IntlShape;
}

const ResidentHeader = (props: ResidentHeaderProps) => {
  const styles = ResidentHeaderStyle();
  const isMobile = useIsMobile();
  const { collapsed, displayAdditionalInfo, intl } = props;
  const [error, setError] = useState<Error>();
  const [practitioners, setPractitioners] = useState<
    DataWithPaging<Practitioner>
  >(getEmptyDataWithPaging<Practitioner>());
  const translate = useMemo(() => getTranslation(intl), [intl]);
  const [diagnoses, setDiagnoses] = useState<DataWithPaging<Condition>>(
    getEmptyDataWithPaging<Condition>()
  );
  const [patient, setPatient] = usePatientContext((state) => [
    state.patient,
    state.setPatient,
  ]);
  const [facility, setFacility] = usePatientContext((state) => [
    state.facility,
    state.setFacility,
  ]);
  const setChartError = usePatientContext((state) => state.setChartError);
  const [setDetailPageOpen] = usePageContext((state) => [
    state.setDetailPageOpen,
  ]);
  const user = useUserStore((state) => state.user.info);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const enablePatientPhoto = useFeaturesContext((state) =>
    state.enabledFeatures.has(Features.PATIENT_PHOTO)
  );
  const selectedPatient = user?.careVaultPatients?.find(
    (f) => f.patientId === patient?.patientId
  );
  const handlePatientChange = useCallback(
    (newPatient: Patient) => {
      setLoading(true);
      setChartError(undefined);
      setPatient(undefined);
      setFacility(undefined);
      setPractitioners(getEmptyDataWithPaging<Practitioner>());
      setDiagnoses(getEmptyDataWithPaging<Condition>());
      setDetailPageOpen(false);

      if (newPatient.isUnavailable) {
        setChartError(new ChartDataError('Current patient is unavailable'));
        setPatient(newPatient);
      } else {
        const facResult = CareVaultService.getDataFromProxy<Facility>(
          newPatient.patientId,
          'facility',
          {
            includeOptionalFields: 'clinicalConfiguration',
          }
        );
        history.push('/');
        (async () => {
          try {
            setFacility(await facResult);
            setPatient(newPatient);
          } catch (ex) {
            setChartError(
              new ChartDataError('Failed to load current facility')
            );
          }
        })();
      }
    },
    [setPatient, setFacility, setChartError]
  );

  const [photo, setPhoto] = useState<string>();
  const [open, setOpen] = useState<string>();

  const patientAge = useMemo(() => {
    return !patient ? 0 : getAge(patient.birthDate);
  }, [patient]);

  const primaryPhysician = useMemo(() => {
    if (practitioners) {
      const primaryPhysicians = practitioners.data.filter(
        (p) => p.relation === 'Primary'
      );
      if (primaryPhysicians.length > 0) return primaryPhysicians[0];
    }
    return null;
  }, [practitioners]);

  const primaryDiagnosis = useMemo(() => {
    if (diagnoses) {
      const primaryDiagnoses = diagnoses.data.filter(
        (p) => p.principalDiagnosis
      );
      if (primaryDiagnoses.length > 0) return primaryDiagnoses[0];
    }
    return null;
  }, [diagnoses]);

  useEffect(() => {
    if (error) throw error;
  }, [error]);

  useEffect(() => {
    if (!patient || patient.isUnavailable) {
      setLoading(false);
      return;
    }

    if (hasDataElementAccess(selectedPatient, DataElement.PRACTITIONERS)) {
      const practitionersResult =
        CareVaultService.getAllPatientListData<Practitioner>(
          patient.patientId,
          'practitioners'
        );
      (async () => {
        try {
          setPractitioners(await practitionersResult);
        } catch (e) {
          setChartError(new ChartDataError('Failed to load practitioners'));
        } finally {
          setLoading(false);
        }
      })();
    }

    if (hasDataElementAccess(selectedPatient, DataElement.CONDITIONS)) {
      const conditionsResult =
        CareVaultService.getAllPatientListData<Condition>(
          patient.patientId,
          'conditions'
        );

      (async () => {
        try {
          setDiagnoses(await conditionsResult);
        } catch (e) {
          setChartError(new ChartDataError('Failed to load diagnoses'));
        } finally {
          setLoading(false);
        }
      })();
    }

    if (enablePatientPhoto) {
      let patientPhoto = `/api/proxy/patients/${patient?.patientId}/commands/patientPhoto`;
      if (!patient.hasPhoto) {
        patientPhoto = patientPhotoPlaceholder;
      }
      setPhoto(patientPhoto);
    } else {
      setPhoto(patientPhotoPlaceholder);
    }
  }, [patient, setChartError]);

  const handleClose = () => {
    setOpen(undefined);
  };

  const renderBody = () => {
    const skeleton = loading || !facility || !patient;
    return (
      <>
        {skeleton ? (
          <Skeleton
            variant="rect"
            className={clsx(
              styles.photoSkeleton,
              collapsed && 'photoCollapse',
              isMobile && styles.photoMobile
            )}
            data-testid={testIds.skeleton}
          />
        ) : (
          <img
            src={photo}
            alt={patient?.firstName}
            data-testid={testIds.image}
            className={clsx(
              styles.photo,
              collapsed && 'photoCollapse',
              isMobile && styles.photoMobile
            )}
            onError={() => {
              setPhoto(patientPhotoPlaceholder);
            }}
          />
        )}

        <div className={clsx(isMobile && styles.residentDetailMobile)}>
          {isMobile &&
            (skeleton ? (
              <Skeleton
                className={styles.name}
                data-testid={testIds.skeleton}
              />
            ) : (
              <div
                className={clsx(styles.name, styles.mobileResidentContent)}
                data-testid={testIds.name}
              >
                {patient?.firstName} {patient?.lastName}
              </div>
            ))}
          {!isMobile && <PatientPicker onSelect={handlePatientChange} />}

          {!collapsed && (
            <>
              <div
                className={clsx(
                  styles.dobRow,
                  isMobile && !skeleton && styles.mobileResidentRow
                )}
              >
                {skeleton ? (
                  <Skeleton data-testid={testIds.skeleton} />
                ) : (
                  <>
                    <span
                      data-testid={testIds.dobLabel}
                      className={styles.labels}
                    >
                      {translate.dob}
                    </span>
                    <span
                      data-testid={testIds.dob}
                      className={clsx(
                        styles.content,
                        isMobile && styles.mobileResidentContent
                      )}
                    >
                      {getFormattedRawDateWithoutTimeZone(patient?.birthDate)}
                      {patientAge > 0 && ` (${patientAge})`}
                    </span>
                  </>
                )}
              </div>
              {hasDataElementAccess(
                selectedPatient,
                DataElement.PRACTITIONERS
              ) && (
                <div
                  className={clsx(
                    styles.physicianRow,
                    isMobile && !skeleton && styles.mobileResidentRow
                  )}
                >
                  {skeleton ? (
                    <Skeleton data-testid={testIds.skeleton} />
                  ) : (
                    <>
                      <span
                        data-testid={testIds.physicianLabel}
                        className={styles.labels}
                      >
                        {translate.physician}
                      </span>
                      {!primaryPhysician && (
                        <span
                          data-testid={testIds.physician}
                          className={styles.content}
                        >
                          -
                        </span>
                      )}
                      {primaryPhysician &&
                        (primaryPhysician.firstName ||
                          primaryPhysician.lastName) && (
                          <span
                            className={clsx(
                              styles.content,
                              isMobile && styles.mobileResidentContent
                            )}
                            data-testid={testIds.physician}
                          >
                            {primaryPhysician.firstName}{' '}
                            {primaryPhysician.lastName}
                          </span>
                        )}
                    </>
                  )}
                </div>
              )}
            </>
          )}

          {!isMobile && (
            <div>
              {skeleton ? (
                <Skeleton data-testid={testIds.skeleton} />
              ) : (
                facility && (
                  <Button
                    style={{ padding: '2px 0px', minWidth: 'auto' }}
                    variant="text"
                    size="small"
                    onClick={() => {
                      setOpen('facilityInfo');
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      component="span"
                      data-testid={testIds.facility}
                    >
                      {facility.facilityName}
                    </Typography>
                  </Button>
                )
              )}
            </div>
          )}
          {!isMobile && displayAdditionalInfo && (
            <Button
              variant="outlined"
              onClick={() => setOpen('residentInfo')}
              size="small"
              className={styles.additionalInfo}
              disabled={skeleton}
            >
              <Typography variant="subtitle2" component="span">
                {translate.additionalInfo}
              </Typography>
            </Button>
          )}
        </div>
        {patient && (
          <ResidentInfoDialog
            handleClose={handleClose}
            isDialogOpen={open === 'residentInfo'}
            patient={patient}
            physician={primaryPhysician}
            diagnosis={primaryDiagnosis}
            photo={photo}
          />
        )}
        {facility && (
          <FacilityInfoDialog
            handleClose={handleClose}
            isDialogOpen={open === 'facilityInfo'}
            facility={facility}
          />
        )}
        {isMobile && (
          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            <IconButton
              style={{ margin: 'auto' }}
              title={translate.showDetails}
            >
              <ChevronRightIcon data-testid={testIds.rightArrowBtn} />
            </IconButton>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      {isMobile && (
        <Paper
          component="nav"
          aria-label={translate.residentHeaderLabel}
          className={clsx(styles.page, styles.pageMobile)}
          onClick={() => {
            history.push('/mobileResident');
          }}
        >
          {renderBody()}
        </Paper>
      )}
      {!isMobile && (
        <nav className={styles.page} aria-label={translate.residentHeaderLabel}>
          {renderBody()}
        </nav>
      )}
    </>
  );
};

export default injectIntl(ResidentHeader);
