import create from 'zustand';
import { produce } from 'immer';
import { createUUID } from '../components/EverGreen/DataTable/utils';
import { Facility, Patient } from '../model/Model';
/* eslint-disable no-param-reassign */

export type Notification = {
  id: string;
  date: Date;
  text: string;
  actions: {
    key: string;
    text: string;
    onClick?: () => void;
    linkTo?: string;
  }[];
  inProgress: boolean;
  tags: string[];
  peeking: boolean;
  read: boolean;
  patient?: Patient;
  facility?: Facility;
};

type NotificationsState = {
  notifications: Notification[];
  clearNotifications: () => void;
  addNotification: (
    notification: Partial<Notification> & Pick<Notification, 'text'>
  ) => string;
  removeNotification: (id: string) => void;
  closeNotification: (id: string) => void;
  closeAllNotifications: () => void;
  readAllNotifications: () => void;
  updateNotification: (
    id: string,
    partialNotification: Partial<Notification>,
    moveToFront?: boolean
  ) => void;
  drawerOpen: boolean;
  setDrawerOpen: (drawerOpen: boolean) => void;
};

export const byPatient =
  (patientId?: number) => (notification: Notification) => {
    if (!patientId) {
      return !notification.patient;
    }
    return (
      !notification.patient || notification.patient.patientId === patientId
    );
  };

export const byTags =
  (...tags: string[]) =>
  (notification: Notification) => {
    return tags.every((tag) => notification.tags.includes(tag));
  };

export const byPeeking = (peeking: boolean) => (notification: Notification) => {
  return notification.peeking === peeking;
};

export const byRead = (read: boolean) => (notification: Notification) => {
  return notification.read === read;
};

export const useNotifications = create<NotificationsState>((set) => ({
  notifications: [],
  clearNotifications: () =>
    set(
      produce<NotificationsState>((draft) => {
        draft.notifications.splice(0, draft.notifications.length);
      })
    ),
  addNotification: (
    notification: Partial<Notification> & Pick<Notification, 'text'>
  ): string => {
    const id = notification.id ?? createUUID();
    set(
      produce<NotificationsState>((draft) => {
        draft.notifications.unshift({
          id,
          date: new Date(),
          text: notification.text,
          actions: notification.actions ?? [],
          inProgress: notification.inProgress ?? false,
          tags: notification.tags ?? [],
          peeking: notification.peeking ?? true,
          read: notification.read ?? false,
          patient: notification.patient,
          facility: notification.facility,
        });
      })
    );
    return id;
  },
  removeNotification: (id: string) =>
    set(
      produce<NotificationsState>((draft) => {
        const index = draft.notifications.findIndex((v) => v.id === id);
        if (index > -1) {
          draft.notifications.splice(index, 1);
        }
      })
    ),
  closeNotification: (id: string) =>
    set(
      produce<NotificationsState>((draft) => {
        const notification = draft.notifications.find((v) => v.id === id);
        if (notification) {
          notification.peeking = false;
        }
      })
    ),
  closeAllNotifications: () =>
    set(
      produce<NotificationsState>((draft) => {
        return draft.notifications.forEach((notification) => {
          notification.peeking = false;
        });
      })
    ),
  readAllNotifications: () =>
    set(
      produce<NotificationsState>((draft) => {
        return draft.notifications.forEach((notification) => {
          notification.read = true;
        });
      })
    ),
  updateNotification: (
    id: string,
    partialNotification: Partial<Notification>,
    moveToFront = true
  ) =>
    set(
      produce<NotificationsState>((draft) => {
        const index = draft.notifications.findIndex((v) => v.id === id);
        if (index > -1) {
          if (moveToFront) {
            const [notification] = draft.notifications.splice(index, 1);
            draft.notifications.unshift({
              ...notification,
              peeking: true,
              ...partialNotification,
            });
          } else {
            draft.notifications.splice(index, 1, {
              ...draft.notifications[index],
              ...partialNotification,
            });
          }
        }
      })
    ),

  drawerOpen: false,
  setDrawerOpen: (drawerOpen: boolean) => set({ drawerOpen }),
}));
