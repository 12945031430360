import React, { useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as NotificationsIconSvg } from './notification-icon-dot.svg';
import { byRead, useNotifications } from '../states/notifications';

const NotificationsIcon = () => {
  const unreadNotifications = useNotifications((state) =>
    state.notifications.filter(byRead(false))
  );
  const showNotificationDot = useMemo(
    () => unreadNotifications.length > 0,
    [unreadNotifications]
  );

  return (
    <SvgIcon
      component={NotificationsIconSvg}
      className={clsx(showNotificationDot && 'dot-visible')}
    />
  );
};

export default NotificationsIcon;
