import create from 'zustand';

export type ErrorContextState = {
  isErrorState: boolean;
  setErrorState: (isErrorState: boolean) => void;
};

const useErrorContext = create<ErrorContextState>((set) => ({
  isErrorState: false,
  setErrorState: (isErrorState: boolean) => {
    set({ isErrorState });
  },
}));

export default useErrorContext;
