const testIds = {
  name: 'patientName',
  image: 'patientImage',
  dob: 'patientDOB',
  dobLabel: 'patientDOBLabel',
  facilityLabel: 'facilityLabel',
  facility: 'patientFacility',
  physician: 'patientPhysician',
  physicianLabel: 'patientPhysicianLabel',
  skeleton: 'patientScreenSkeleton',
  address: 'patientPrevAddress',
  diagnosis: 'patientPrimaryDiagnosis',
  email: 'patientEmail',
  ethnicity: 'patientEthnicity',
  gender: 'patientGender',
  languageDesc: 'patientLanguageDesc',
  maritalStatus: 'patientMaritalStatus',
  suffix: 'patientSuffix',
  middleName: 'patientMiddleName',
  maidenName: 'patientMaidenName',
  race: 'patientRace',
  smokingStatus: 'patientSmokingStatus',
  preferredLanguage: 'patientPreferredLanguage',
  phone: 'patientPhone',
  status: 'patientStatus',
  rightArrowBtn: 'rightArrowBtn',
  smokingDates: 'patientSmokingDates',
};

export default testIds;
