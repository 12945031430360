import React, { ReactElement } from 'react';
import ErrorScreenNoPatient from './variants/ErrorScreenNoPatient';
import ErrorScreenDefault from './variants/ErrorScreenDefault';
import ErrorScreenChart from './variants/ErrorScreenChart';
import ErrorScreenNoPageFound from './variants/ErrorScreenNoPageFound';
import { useIsMobile } from '../PagesTemplate/utils/ResponsiveHooks';
import ErrorScreenNoAccessPage from './variants/ErrorScreenNoAccessPage';

type ErrorScreenVariants =
  | 'default'
  | 'no-patient'
  | 'chart-error'
  | 'no-access-page'
  | 'no-page-found';

type Props = {
  variant?: ErrorScreenVariants;
  message?: string;
};

const ErrorScreen = (props: Props): ReactElement => {
  const { variant, message } = props;
  const isMobile = useIsMobile();
  let component;

  switch (variant) {
    case 'no-patient':
      component = ErrorScreenNoPatient;
      break;
    case 'chart-error':
      component = ErrorScreenChart;
      break;
    case 'no-page-found':
      component = ErrorScreenNoPageFound;
      break;
    case 'no-access-page':
      component = ErrorScreenNoAccessPage;
      break;
    default:
      component = ErrorScreenDefault;
  }
  return React.createElement(component, { isMobile, message });
};

export default ErrorScreen;
export type { ErrorScreenVariants };
