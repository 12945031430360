import React, { createElement, ReactElement, Suspense, useMemo } from 'react';

import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { CircularLoader, Route as BrowserRoute } from 'pcc-react-components';
import { RedirectProps } from 'react-router';
import { Divider } from '@material-ui/core';
import ErrorScreenNoPageFound from '../ErrorScreen/variants/ErrorScreenNoPageFound';
import { DataElement, SetPageTitleDelegate } from '../../model/Model';

export interface CareVaultRouteProps extends RouteProps {
  dataElement?: DataElement;
}

type Props = {
  routes: BrowserRoute<CareVaultRouteProps>[];
  redirects: RedirectProps[];
  setTitle?: SetPageTitleDelegate;
};

const getRenderableRoutes = (
  routesArray: BrowserRoute<CareVaultRouteProps>[] | undefined
): BrowserRoute<CareVaultRouteProps>[] => {
  if (typeof routesArray === 'undefined') return [];

  const arr = routesArray.reduce((accum, route) => {
    if (Object.hasOwnProperty.call(route, 'routes')) {
      accum.push(...getRenderableRoutes(route.routes));
    }
    if (Object.hasOwnProperty.call(route, 'routeComponentProps'))
      accum.push(route);
    return accum;
  }, [] as BrowserRoute<CareVaultRouteProps>[]);

  return arr;
};

const renderRoutes = (
  routesArray: BrowserRoute<CareVaultRouteProps>[],
  setTitle?: SetPageTitleDelegate
) => {
  return routesArray.map((route) => {
    if (!Object.hasOwnProperty.call(route, 'routeComponentProps')) return false;

    const {
      path,
      routeComponentProps: {
        exact = undefined,
        path: routePath = undefined,
        component,
        render,
      } = {},
    } = route;

    return (
      <Route
        exact={exact}
        path={routePath || path}
        key={Array.isArray(path) ? path[0] : path}
        render={render}
      >
        {component && React.createElement(component, { setTitle })}
      </Route>
    );
  });
};

const Routes = ({ routes, redirects, setTitle }: Props): ReactElement => {
  const routesList = useMemo(() => getRenderableRoutes(routes), [routes]);

  return (
    <Suspense
      fallback={<CircularLoader height="calc(100% - var(--dock-height))" />}
    >
      <Switch>
        {renderRoutes(routesList, setTitle)}
        <Route path="/pagenotfound" component={ErrorScreenNoPageFound} />
        {redirects.map((rd) =>
          createElement(Redirect, {
            ...rd,
            key: `redirect[${rd.from}->${rd.to}]`,
          })
        )}
        <Redirect to="/pagenotfound" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
