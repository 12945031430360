import React, { ReactElement, useMemo } from 'react';
import { Typography } from 'pcc-react-components';
import Divider from '@material-ui/core/Divider';
import { Link, Paper } from '@material-ui/core';
import { injectIntl, IntlShape } from 'react-intl';
import clsx from 'clsx';
import useStyles from './style';
import { Patient } from '../../../model/Model';
import testIds from '../utils/constants';
import { getTranslation } from '../utils/translations';
import { addressFmt, addressFmtLink } from '../../../utils/addressFmt';

export interface ExcludeAllDataResidentPageProps {
  patient: Patient;
  intl: IntlShape;
}

const ExcludeAllDataResidentPage = (
  props: ExcludeAllDataResidentPageProps
): ReactElement => {
  const styles = useStyles();
  const { patient, intl } = props;

  const translate = useMemo(() => getTranslation(intl), [intl]);

  const previousAddress =
    patient.previousAddress && addressFmt(patient.previousAddress);
  const previousAddressLink =
    patient.previousAddress && addressFmtLink(patient.previousAddress);

  return (
    <div className={styles.contentRoot} data-cy="contentRoot">
      <Paper className={clsx(styles.paperRoot)}>
        <Typography
          variant="h4.medium"
          data-testid={testIds.name}
          style={{ paddingBottom: '16px' }}
        >
          {patient.firstName} {patient.lastName} {patient.suffix}
        </Typography>
        <div className={styles.row}>
          <div className={styles.flex2}>
            <div>
              <span className={styles.labels}>{translate.gender}</span>
              <span data-testid={testIds.gender} className={styles.content}>
                {patient.gender ?? '-'}
              </span>
            </div>
            <div>
              <span className={styles.labels}>{translate.status}</span>
              <span data-testid={testIds.status} className={styles.content}>
                {patient.patientStatus ?? '-'}
              </span>
            </div>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div>
          <Typography variant="h4.medium">
            {translate.contactInformation}
          </Typography>
          <Divider className={styles.topDivider} />
          <div className={styles.row}>
            <div className={styles.flex1}>
              <div>
                <span className={styles.labels}>{translate.homePhone}</span>
                <span data-testid={testIds.phone} className={styles.content}>
                  {patient.homePhone ? (
                    <Link href={`tel:${patient.homePhone}`}>
                      {patient.homePhone}
                    </Link>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
              <div>
                <span className={styles.labels}>{translate.emailAddress}</span>
                <span data-testid={testIds.email} className={styles.content}>
                  {patient.email ? (
                    <Link href={`mailto:${patient.email}`}>
                      {patient.email}
                    </Link>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </div>
            <div className={styles.flex2}>
              <div>
                <span className={styles.labels}>
                  {translate.previousAddress}
                </span>
                <span data-testid={testIds.address} className={styles.content}>
                  {previousAddress ? (
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      href={previousAddressLink}
                    >
                      {previousAddress}
                    </Link>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div>
          <Typography variant="h4.medium">
            {translate.personalInformation}
          </Typography>
          <Divider className={styles.topDivider} />
          <div className={styles.row}>
            <div className={styles.flex2}>
              <div>
                <span className={styles.labels}>{translate.suffix}</span>
                <span data-testid={testIds.suffix} className={styles.content}>
                  {patient.suffix ?? '-'}
                </span>
              </div>
              <div>
                <span className={styles.labels}>{translate.middleName}</span>
                <span
                  data-testid={testIds.middleName}
                  className={styles.content}
                >
                  {patient.middleName ?? '-'}
                </span>
              </div>
              <div>
                <span className={styles.labels}>{translate.maidenName}</span>
                <span
                  data-testid={testIds.maidenName}
                  className={styles.content}
                >
                  {patient.maidenName ?? '-'}
                </span>
              </div>
            </div>
            <div className={styles.flex2}>
              <div>
                <span className={styles.labels}>{translate.maritalStatus}</span>
                <span
                  data-testid={testIds.maritalStatus}
                  className={styles.content}
                >
                  {patient.maritalStatus ?? '-'}
                </span>
              </div>
              <div>
                <span className={styles.labels}>{translate.ethnicity}</span>
                <span
                  data-testid={testIds.ethnicity}
                  className={styles.content}
                >
                  {patient.ethnicityDesc ?? '-'}
                </span>
              </div>
              <div>
                <span className={styles.labels}>{translate.race}</span>
                <span data-testid={testIds.race} className={styles.content}>
                  {patient.raceCode?.codings.map((x) => x.display).join(', ') ??
                    '-'}
                </span>
              </div>
            </div>
            <div className={styles.flex2}>
              <div>
                <span className={styles.labels}>{translate.smokingStatus}</span>
                <span
                  data-testid={testIds.smokingStatus}
                  className={styles.content}
                >
                  {patient.smokingStatusDesc ?? '-'}
                </span>
              </div>
              <div>
                <span className={styles.labels}>
                  {translate.preferredLanguage}
                </span>
                <span
                  data-testid={testIds.preferredLanguage}
                  className={styles.content}
                >
                  {patient.languageDesc ?? '-'}
                </span>
              </div>
            </div>
          </div>
          <Divider className={styles.bottomDivider} />
        </div>
      </Paper>
    </div>
  );
};

export default injectIntl(ExcludeAllDataResidentPage);
