import { createStyles, makeStyles, Theme } from '@material-ui/core';
// eslint-disable-next-line import/no-cycle
import { NavBarListItemProps } from './index';

type Props = Required<
  Pick<NavBarListItemProps<any>, 'depth' | 'path' | 'rootItemHasIcon'>
>;

const useStyles = makeStyles(({ palette, spacing }: Theme) => {
  function calcPadding(depth: number) {
    return spacing(1.5) * (2 + depth) - spacing(1);
  }

  function calcPaddingWithIcons(depth: number) {
    return depth === 0
      ? spacing(1)
      : spacing(2) * (0.75 * (depth - 1) + 4) - spacing(1);
  }

  return createStyles({
    item: ({ depth, path, rootItemHasIcon }: Props) => ({
      backgroundClip: 'content-box',
      color: 'rgba(0, 0, 0, 0.64)',
      height: spacing(depth === 0 ? 6 : 5),
      padding: spacing(0.5, 0),
      '&.Mui-selected [aria-label="icon"]': {
        color: palette.primary.dark,
      },
      '&.mobile [aria-label="icon"]': {
        color: palette.primary.dark,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focusVisible': {
        backgroundColor: palette.action.focusBackground,
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        color: palette.primary.dark,
        backgroundColor: palette.action.activeBackground,
      },
      '& > .MuiTouchRipple-root': {
        height: depth === 0 ? spacing(5) : spacing(4),
        marginTop: spacing(0.5),
      },
      '&[aria-haspopup="true"] > .MuiTouchRipple-root': {
        width: `calc(100% - ${spacing(6)}px)`,
      },
      '& > div:first-child': {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
        padding: spacing(0, 1, 0, 0),
        paddingLeft: rootItemHasIcon
          ? calcPaddingWithIcons(depth)
          : calcPadding(depth),
        '&:hover': {
          background: path ? 'rgba(0, 0, 0, 0.04)' : undefined,
        },
      },
      '&[aria-haspopup="true"] > div:first-child': {
        paddingRight: 0,
      },

      '&.Mui-disabled': {
        color: '#000',
        opacity: '0.4',
      },
      '&.Mui-disabled:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled.Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled.Mui-selected, &.Mui-disabled.Mui-selected:hover': {
        color: '#000',
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled [aria-label="icon"] svg path': {
        fill: '#000',
      },
    }),
    expanderButton: {
      height: 48,
      width: 48,
    },
    forceHighlight: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:hover': {
        backgroundColor: `rgba(0, 0, 0, 0.04) !important`,
      },
    },
    noCursor: {
      cursor: 'auto',
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent !important',
      },
    },
    icon: {
      minWidth: spacing(4),
    },
  });
});

export default useStyles;
