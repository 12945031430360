/* eslint-disable no-use-before-define */
import { FunctionComponent, ReactNode } from 'react';
import { IntlShape } from 'react-intl';
import {
  DataTableColumn,
  DataTableProps,
} from '../components/EverGreen/DataTable';
import { Column, Data } from '../components/EverGreen/DataTable/types';
import { DateFilterInfo } from '../components/DateRangePicker/types';

export interface UserData {
  username: string;
  careVaultPatients?: CareVaultPatient[];
}

export interface Practitioner {
  practitionerId: string;
  firstName: string;
  lastName: string;
  providerType: string;
  relation: string;
  title?: string;
  middleName?: string;
  gender?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  county?: string;
  cellPhone?: string;
  homePhone?: string;
  officePhone?: string;
  pager?: string;
  email?: string;
  fax?: string;
  npi?: string;
  upin?: string;
  revisionBy?: string;
  revisionDate?: string;
  createdBy?: string;
  createdDate?: string;
}

export interface DiagnosticReportResultValueQuantity {
  value?: string;
  unitText?: string;
}

export interface DiagnosticReportResultCodingSystem {
  code?: string | null;
  display?: string;
  system?: string;
}

export interface DiagnosticReportResultInterpretation {
  coding?: DiagnosticReportResultCodingSystem[] | null;
}

export interface DiagnosticReportResult {
  codeDescription?: string;
  referenceRange?: string;
  resultStatus?: string;
  valueQuantity?: DiagnosticReportResultValueQuantity;
  observationName?: string;
  procedureName?: string;
  comment?: string;
  interpretation?: DiagnosticReportResultInterpretation;
}

export interface DiagnosticReportTestSet {
  panelName?: string | null;
  results: DiagnosticReportResult[];
}

export type DiagnosticReport = {
  reportId: string;
  reportType: string;
  effectiveDateTime?: string;
  issuedDateTime?: string;
  reportName?: string;
  reportStatus?: string;
  reportFile?: boolean;
  category?: string[];
  providerFirstName?: string;
  providerLastName?: string;
  reportingLaboratory?: string;
  notes?: string[];
  testSet?: DiagnosticReportTestSet[];
  reportingLabAddress?: ReportingLabAddress;
};

export interface ReportingLabAddress {
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  country?: string;
  county?: string;
  postalCode?: string;
  state?: string;
  phone?: string;
  faxNumber?: string;
  facilityName?: string;
  email?: string;
  labId?: number;
}

export interface ClinicalNoteSection {
  value?: string;
  name?: string;
}

export interface ClinicalNote {
  progressNoteId: number;
  noteType?: string;
  progressNoteType: string;
  createdDate?: string;
  effectiveDate?: string;
  summaryText?: string;
  completed?: string;
  sections?: ClinicalNoteSection[];
}

export interface CareVaultPatient {
  patientId: number;
  corePatientId: number;
  facId: number;
  orgUuid: string;
  orgCode: string;
  patientMpiId: number;
  dataElementConsentLevels: DataElements;
}

// eslint-disable-next-line no-shadow
export enum DataElement {
  ALLERGIES = 'ALLERGIES',
  CARE_PLANS = 'CARE_PLANS',
  CONDITIONS = 'CONDITIONS',
  PROBLEMS = 'PROBLEMS',
  DIAGNOSTIC_REPORTS = 'DIAGNOSTIC_REPORTS',
  IMMUNIZATIONS = 'IMMUNIZATIONS',
  MEDICATIONS = 'MEDICATIONS',
  PRACTITIONERS = 'PRACTITIONERS',
  NOTES = 'NOTES',
  OBSERVATIONS = 'OBSERVATIONS',
  IMPLANTABLE_DEVICES = 'IMPLANTABLE_DEVICES',
}

export type ConsentLevel = 'NONE' | 'PARTIAL' | 'FULL';

export type DataElements = {
  [Property in keyof typeof DataElement]: ConsentLevel;
};

interface Coding {
  system: string;
  version: string;
  code: string;
  display: string;
}

export interface Codings {
  codings: Coding[];
}

export interface Patient {
  patientId: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  maidenName?: string;
  birthDate?: string;
  hasPhoto?: boolean;
  gender?: string;
  homePhone?: string;
  email?: string;
  preferredName?: string;
  suffix?: string;
  smoking?: string;
  admissionDate?: string;
  patientStatus: 'New' | 'Current' | 'Discharged';
  patientRaces?: { raceDesc: string }[];

  languageDesc?: string;
  ethnicityDesc?: string;
  maritalStatus?: string;
  smokingStatusDesc?: string;
  previousAddress?: Address;
  // patientContacts?: PatientContact[];
  raceCode?: Codings;
  isUnavailable?: boolean;
}

export interface CareProfile {
  questionId?: number;
  patientId?: number;
  createdDate?: string;
  createdBy?: string;
  valueId?: number;
  value?: string;
  questionTypeId?: number;
  parentQuestionId?: number;
  categoryId?: number;
  questionText?: string;
  questionKey?: string;
}

// export type PatientContact = ContactInfo & Address;

// export interface ContactInfo {
//   contactId: string;
//   cellPhone?: string;
//   homePhone?: string;
//   officePhone?: string;
//   namePrefix?: string;
//   firstName?: string;
//   lastName?: string;
//   gender?: string;
//   email?: string;
//   relationship?: string;
// }

export interface Address {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  county?: string;
  state?: string;
}

export interface Allergy {
  allergyIntoleranceId: string;
  patientId: number;
  allergen: string;
  clinicalStatus: string;
  type: string;
  severity: string;
  category: string;
  reactionType: string | null;
  reactionNote: string | null;
  onsetDate: string | null;
  reactionSubType?: string | null;
  resolvedDate?: string | null;
  listOfManifestations?: string | null;
}

export interface Condition {
  conditionId: string;
  onsetDate: string;
  icd10: string;
  icd10Description: string;
  clinicalStatus: string;
  resolvedDate?: string;
  principalDiagnosis: boolean;
}

export type ProblemsDto = {
  problems: Problem[];
  noKnownProblems: boolean;
};

export type Problem = {
  onsetDate: string;
  resolvedDate?: string;
  conceptStatusCode: string;
  observations: ProblemObservation[];
};

export interface ProblemValue {
  system: string;
  code: string;
  display: string;
}

export interface ProblemStatus {
  longDescription: string;
  shortDescription: string;
  code: string;
}
export interface ProblemObservation {
  onsetDate?: string;
  resolvedDate?: string;
  resolved: boolean;
  clinicalStatus: ProblemStatus;
  value: ProblemValue;
}

export interface Device {
  deviceIdentifier: string;
  expirationDate: string;
  description: string;
  status: string;
  createdDate: string;
  manufacturingDate: string;
  implantDate: string;
  udi: string;
  serialNumber: string;
  brandName: string;
  companyName: string;
  batchNumber: string;
  mriSafetyLabelInfo: string;
  containsRubber: boolean;
  versionModelNumber: string;
  donationId: string;
  assigningAuthority?: string;
}
export type DataWithPaging<T> = {
  data: T[];
  paging: {
    hasMore: boolean;
    page: number;
    pageSize: number;
  };
};

export interface Facility {
  orgId: number;
  orgUUID: string;
  facId: number;
  facilityName: string;
  country: string;
  billingStyleCountry?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  phone?: string;
  city?: string;
  state?: string;
  fax?: string;
  emailAddress?: string;
  bedCount?: string;
  lineObservation?: LineOfBusiness;
  healthType?: string;
  timeZone?: string;
  adjustForDst?: string;
  clinicalConfiguration?: ClinicalConfiguration;
}

export interface LineOfBusiness {
  shortDesc?: string;
  longDesc?: string;
}

export interface ClinicalConfiguration {
  units: Units;
}

export interface Units {
  heartRate: string;
  bloodSugar: string;
  bloodPressure: string;
  weight: string;
  height: string;
  temperature: string;
  respirations: string;
  oxygenSaturation: string;
  headCircumference: string;
}

export type PageTemplateFilterOptions = {
  key: string;
  value: string;
};

// string if dropdown is not multi select
export type FilterSelect = string | string[];

export type PageTemplateFilterInfo = {
  /** A array of key/value includes all options */
  filterOptions: PageTemplateFilterOptions[];
  /** filter Title */
  filterTitle: string;
  /** select status */
  selectedStatus: FilterSelect;
  /** A function which will gets called on filter change */
  onFilterChange(value: FilterSelect): boolean | void;
  /** Used to provide data-test and data-cy for testing */
  testId: string;
  /** Show if the filter is multiSelect or not* */
  multiple: boolean;
  /** width of dropdown filter in ch */
  width?: number;
  /** margin of dropdown filter default is normal */
  margin?: 'super-dense' | 'dense' | 'normal';
};

export interface DetailPageProps {
  data: unknown;
  patientId?: number;
  intl: IntlShape;
  classes?: Record<string, string>;
  setPageTitle?: SetPageTitleDelegate;
  embedded?: boolean;
}

export declare type PageDataTableColumn<T> = DataTableColumn<T> &
  TypedColumn<T> & {
    /** Used to provide data-test and data-cy for testing */
    testId: string;

    /** If is true, then a custom sort will be used for column to ignore cases in sorting
     *  Default is true
     *  This will be ignored if a custom sort provided for the column already
     */
    alphabeticSortRequired?: boolean;

    /** By default column will be shown but If this function return true it won't */
    hideColumn?(selectedStatus: FilterSelect): boolean;

    /** Show in expanded view when this returns true */
    showInExpandedView?(selectedStatus: FilterSelect): boolean;
  };

/**
 * Identify the column with its semantic metadata
 */
export interface TypedColumn<T> extends Column<T> {
  columnType?: ColumnType;
  lineClamp?: number;
  singleColumn?: boolean;
}
export type UIDesignPattern = 'moreInfo' | 'cards' | 'expandData' | 'dataTable';
export type StatusColor =
  | 'green'
  | 'orange'
  | 'yellow'
  | 'purple'
  | 'gray'
  | 'blue';
export type ColumnType = 'status' | 'title' | 'body' | 'date' | 'moreInfo';
export type MobileFilterStyle = 'chip' | 'dropdown';
export type HtmlSvg = HTMLElement & SVGElement;

export interface ActionItem {
  key: string;
  text: string;
  icon: JSX.Element;
  onClick: () => void;
  testId: string;
  description?: string;
  infoTestId?: string;
  disableButton?: boolean;
}

export interface ReportProps {
  reportKey?: string;
  generateButtonText: string;
  disableGenerateButton?: boolean;
  onClickGenerate: () => void;
  onClickCancel: () => void;
  actions?: ActionItem[];
  multiActionIcon?: JSX.Element;
  bottomText?: string;
  messages: {
    loading: string;
    empty: string;
  };
}

export type SetPageTitleDelegate = (
  title: string,
  description?: string
) => void;

export interface PageTemplateProps<T> {
  /** Page Title */
  pageTitle: string;
  pageDescription?: string;
  /** An indicator which shows data is loading/loaded */
  loading: boolean;
  /** information of page filter */
  filterInfo?: PageTemplateFilterInfo;
  /** Date filter info */
  dateFilterInfo?: DateFilterInfo;

  /** Used for translation */
  intl: IntlShape;

  /** A React Element which should gets render when user click on row */
  detailPage?(props: DetailPageProps): JSX.Element;

  /** Data table Properties */
  dataTableProps?: DataTableProps<T>;

  /** Array of objects to label table header and map data to each table cell
   *  This is optional but if it has been passed then we rebuild data table columns with
   *  following feature
   *  we will apply row Level styles, using rowLevelClasses propery
   *  we will add data-testid to <div> tag surrounding the column data
   *  we will add data-cv to column attributes for e2e testing
   *  we will show dash if column data is empty
   * */
  pageDataTableColumns?: PageDataTableColumn<T>[];

  /** Enable fixed table layout */
  fixedLayout?: boolean;

  /** Error message to show as a banner at the top of the page */
  errorMessage?: string;

  /** Handle when the user presses the RETRY button in the error message */
  onReload?(): void;

  /** If we need to have ellipsis on overflow, default is false */
  withOverflowEllipsis?: boolean;

  /** Patient Id, it will pass to detail page */
  patientId?: number;

  getStatusColor?: (row: Data<T>) => StatusColor | undefined;
  /**
   * UI Design Pattern in mobile, default is cards
   */
  designPattern?: UIDesignPattern;

  cardDensity?: 'normal' | 'dense';

  mobileFilterStyle?: MobileFilterStyle;

  /** If we need to remove border of detail page */
  noBorderInDetail?: boolean;

  setPageTitle?: SetPageTitleDelegate;

  /** number of expanded columns */
  numExpandedCols?: number;

  reportProps?: ReportProps;
}

export interface SummaryComponentTemplateProps<T> {
  /** Component Title */
  title: string;

  detailTitle?: string;

  /** Component Description */
  description: string;

  /** Component Icon */
  icon: string;

  /** An indicator which shows data is loading/loaded */
  loading: boolean;

  /** Used for translation */
  intl: IntlShape;

  /** Two column  */
  twoColumn?: boolean;
  /** A React Element which should gets render when user click on row */
  detailPage?: FunctionComponent<DetailPageProps>;
  /** Error message to show as a banner at the top of the page */
  errorMessage?: string;
  /** Handle when the user presses the RETRY button in the error message */
  onReload?(): void;

  /** If we need to have ellipsis on overflow, default is false */
  withOverflowEllipsis?: boolean;

  /** Patient Id, it will pass to detail page */
  patientId?: number;

  getStatusColor?: (row: Data<T>) => StatusColor | undefined;

  /** Data table Properties */
  dataTableProps: DataTableProps<T>;

  /** Array of objects to label table header and map data to each table cell
   *  This is optional but if it has been passed then we rebuild data table columns with
   *  following feature
   *  we will apply row Level styles, using rowLevelClasses propery
   *  we will add data-testid to <div> tag surrounding the column data
   *  we will add data-cv to column attributes for e2e testing
   *  we will show dash if column data is empty
   * */
  pageDataTableColumns: PageDataTableColumn<T>[];

  /** A optional function which accept rowdata and return array of style class names
   *  This property will get ignored if dataTableProps is not provided
   * */
  rowLevelClasses?(rowData: T): string[];

  /**
   * Force to use desktop layout for mobile
   */
  forceDesktop?: boolean;

  /** If we need to remove border of detail page */
  noBorderInDetail?: boolean;

  setPageTitle?: SetPageTitleDelegate;

  /** has view more button or not */
  hasViewMoreButton?: boolean;

  viewAllLink: string;

  /** In case the patient is new and has no chart data */
  noCharts?: boolean;
  /** extra CSS style */
  style?: React.CSSProperties;
}
export interface SummaryVitalsTemplateProps<T> {
  /** Component Title */
  title: string;

  /** Component Description */
  description: string;

  /** Component Icon */
  icon: string;

  /** An indicator which shows data is loading/loaded */
  loading: boolean;

  /** Used for translation */
  intl: IntlShape;

  /** Error message to show as a banner at the top of the page */
  errorMessage?: string;
  /** Handle when the user presses the RETRY button in the error message */
  onReload?(): void;

  /** If we need to have ellipsis on overflow, default is false */
  withOverflowEllipsis?: boolean;

  /** Patient Id, it will pass to detail page */
  patientId?: number;

  getStatusColor?: (row: Data<T>) => StatusColor | undefined;

  /** Array of observations to populate the tiles */
  observations?: Observation[];

  /**
   * Force to use desktop layout for mobile
   */
  forceDesktop?: boolean;

  /** If we need to remove border of detail page */
  noBorderInDetail?: boolean;

  setPageTitle?: SetPageTitleDelegate;

  /** extra CSS style */
  style?: React.CSSProperties;

  viewAllLink: string;

  /** In case the patient is new and has no chart data */
  noCharts?: boolean;
}

export interface DetailPageTemplateProps {
  returnBackHandler(): void;
  buttonTitle: string;
  pageTitle?: string;
  children?: ReactNode;
  noBorderInDetail?: boolean;
}

export interface CarePlan {
  carePlanId: string;
  status: string;
  createdDate: string;
  closedDate: string;
  nextReviewDate: string;
  closureReason: string;
  focuses?: Focus[];
}

export interface Focus {
  focusId: string;
  description: string;
  createdDate: string;
  createdBy: string;
  initiatedDate: string;
  resolvedDate?: string;
  status: string;
  revisionBy: string;
  revisionDate: string;
  goals?: Goal[];
  interventions?: Intervention[];
}

export interface Goal {
  goalId: number;
  status: string;
  description: string;
  initiatedDate: string;
  targetDate: string;
  resolvedDate?: string;
}

export interface Intervention {
  interventionId: number;
  status: string;
  description: string;
  instruction?: string;
  frequency: string;
  initiatedDate: string;
  targetDate?: string;
  resolvedDate?: string;
}
export type CarePlanItem = Focus | Goal | Intervention;
export interface CarePlanDetailProps {
  key?: string;
  focus?: Focus;
  showResolved: boolean;
  filterFunc?: (item: CarePlanItem, resolved: boolean) => boolean;
  intl: IntlShape;
}

export type RowData<T> = {
  id: string | number;
  data: T[];
};

export interface Immunization {
  immunizationId: number;
  cvxDescription?: string;
  consentStatus: string;
  consentConfirmedDate?: string;
  educationProvided?: boolean;
  administrationDateTime?: string;
  locationGiven?: string;
  manufacturerName?: string;
  notes?: string;
  reasonRefused?: string;
  reasonNotEligible?: string;
  results?: string;
  createdDateTime?: string;
  step?: number;
  multiStep?: boolean;
  trackResults?: boolean;
}

export interface Medication {
  orderId: number;
  description: string;
  directions?: string;
  orderedBy?: string;
  startDate: string;
  endDate?: string;
  status: string;
  revisionDate?: string;
  holdDate?: string;
  holdEndDate?: string;
  discontinueDate?: string;
}

export type PageTranslatesIndex = {
  [selector: string]: string;
};

export interface Observation {
  observationId: number;
  patientId: number;
  type: string;
  value?: number;
  diastolicValue?: number;
  systolicValue?: number;
  method?: string;
  unit?: string;
  recordedDate: string;
  headCircumferencePercentile?: string;
  oxygenConcentration?: string;
  weightForLengthPercentile?: string;
  bmiPercentile?: string;
  headCircumferencePercentileValue?: string;
  oxygenConcentrationValue?: string;
  weightForLengthPercentileValue?: string;
  bmiPercentileValue?: string;
  // warnings?: Warning[];
}

// export interface Warning {
//   description: string;
//   cleared: boolean;
//   clearedBy?: string;
// }

export interface ObservationBaseline {
  patientId: number;
  type: string;
  value?: number;
  diastolicValue?: number;
  systolicValue?: number;
  method?: string;
  unit?: string;
  revisionDate: string;
}

export interface DetailPageRow {
  labelId: string;
  value: string | ReactNode;
  testId: string;
}

export interface CCDDocument {
  documentId: number;
  patientId?: number;
  fileMetadataId?: number;
  documentName?: string;
  description?: string;
  documentCategory?: number;
  documentCategoryDescription?: string;
  revisionDate?: string | null;
  effectiveDate?: string;
  createdDate?: string;
  createdBy?: string;
}

export interface DetailPageDataTableInfo {
  detailPageRows: DetailPageRow[];
  intl: IntlShape;
  firstColumnWidth: string;
  detailPageTitle: string;
  titleTestId: string;
  classes?: Record<string, string>;
  embedded?: boolean;
}

export interface TimeDifference {
  type:
    | 'minute'
    | 'minutes'
    | 'hour'
    | 'hours'
    | 'day'
    | 'days'
    | 'week'
    | 'weeks'
    | 'month'
    | 'months'
    | 'year'
    | 'years'
    | 'invalid';
  value: number;
}

export type AuditAction = 'READ' | 'DOWNLOAD' | 'TRANSMIT';

export interface AuditEntry {
  auditEntryId: number;
  action?: AuditAction;
  user?: string;
  information?: string;
  recipient?: string;
  date: string;
}

export interface AuditResult {
  hasMore: boolean;
  lastGenerated?: string;
  data: AuditEntry[];
}

/* eslint-enable no-use-before-define */
