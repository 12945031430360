const DEFAULT_LOCALE = 'en-US';
const DEFAULT_DATE_FORMAT = 'MMM dd, yyyy';
const DEFAULT_DATE_TIME_FORMAT = 'MMM dd, yyyy hh:mm a';
const DEFAULT_TIMEZONE = 'America/New_York';
const DATE_INPUT_FORMAT = 'MM/dd/yyyy';

export {
  DEFAULT_LOCALE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIMEZONE,
  DATE_INPUT_FORMAT,
};
