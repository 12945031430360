import axios from 'axios';
import apiCall from '../utils/apiCall';
import { CCDDocument, DataWithPaging, Patient } from '../model/Model';
import appConstants from '../constants';

export const getEmptyDataWithPaging = <T>(): DataWithPaging<T> => {
  return {
    data: [],
    paging: {
      hasMore: false,
      page: 1,
      pageSize: 50,
    },
  };
};

const CareVaultService = {
  getPatientListData: async <T>(
    patientId: number,
    command: string,
    params: { [key: string]: any } = {}
  ): Promise<DataWithPaging<T>> => {
    const url = `proxy/patients/${patientId}/commands/${command}`;

    return new Promise((resolve, reject) => {
      apiCall
        .get<DataWithPaging<T>>(url, { params })
        .then((result) => resolve(result.data))
        .catch(reject);
    });
  },

  getAllPatientListData: async <T>(
    patientId: number,
    command: string,
    params: { [key: string]: any } = {},
    pageSize: number = appConstants.apiMaxRow
  ): Promise<DataWithPaging<T>> => {
    const getAllDataStratingFromPage = async (page: number): Promise<any[]> => {
      return new Promise((resolve, reject) => {
        CareVaultService.getPatientListData<T>(patientId, command, {
          ...params,
          page,
          pageSize,
        })
          .then(async (value) => {
            const { data } = value;
            if (data.length === appConstants.apiMaxRow) {
              data.push(...(await getAllDataStratingFromPage(page + 1)));
            }
            resolve(data);
          })
          .catch(reject);
      });
    };
    return new Promise((resolve, reject) => {
      getAllDataStratingFromPage(1)
        .then((data) => {
          const result = getEmptyDataWithPaging<T>();
          result.data = data;
          result.paging.pageSize = data.length;
          result.paging.page = 1;
          resolve(result);
        })
        .catch(reject);
    });
  },

  getDataFromProxy: async <T>(
    patientId: number,
    command: string,
    params: { [key: string]: string } = {}
  ): Promise<T> => {
    const url = `proxy/patients/${patientId}/commands/${command}`;

    return new Promise((resolve, reject) => {
      apiCall
        .get<T>(url, { params })
        .then((result) => resolve(result.data))
        .catch(reject);
    });
  },

  getPatientData: async (
    patientId: number,
    params: { [key: string]: string } = {}
  ): Promise<Patient> => {
    return new Promise((resolve, reject) => {
      CareVaultService.getDataFromProxy<Patient>(patientId, 'patient', params)
        .then((patient) => {
          resolve({ ...patient, patientId });
        })
        .catch(reject);
    });
  },

  getTermsAndCondition: async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      axios
        .get<string>('/public/tc/en-USContent.html')
        .then((result) => resolve(result.data))
        .catch(reject);
    });
  },

  getFeatureState: async (featureName: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      axios
        .get<boolean>(`/features/${featureName}`)
        .then((result) => resolve(result.data))
        .catch(reject);
    });
  },

  getEnabledFeatures: async (patientId: number): Promise<Set<string>> => {
    return new Promise((resolve, reject) => {
      axios
        .get<Set<string>>('/features', { params: { patientId } })
        .then((result) => resolve(new Set(result.data)))
        .catch(reject);
    });
  },
};

export default CareVaultService;
