import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {
  ErrorOutline,
  CheckCircleOutline,
  CreateNewFolder,
} from '@material-ui/icons';

import { Button } from 'pcc-react-components';
import { injectIntl, IntlShape } from 'react-intl';
import clsx from 'clsx';
import useStyles from './style';
import testIds from './testIds';
import PageDescriptionWithIcon from '../PageTitle/PageDescriptionWithIcon';

const InfoDialog = ({
  open,
  variant = 'info',
  title,
  message,
  onClose,
  onRetry,
  testId,
  intl,
}: {
  open: boolean;
  variant?: 'info' | 'success' | 'failure';
  title?: string;
  message?: string;
  onClose: () => void;
  onRetry?: () => void;
  testId?: string;
  intl: IntlShape;
}) => {
  const styles = useStyles();

  return (
    <Dialog open={open} onClose={onClose} data-testid={testId}>
      {title && (
        <DialogTitle>
          {variant === 'failure' && (
            <ErrorOutline
              className={clsx(styles.titleIcon, 'error')}
              data-testid={testIds.failureIcon}
            />
          )}
          {variant === 'success' && (
            <CheckCircleOutline
              className={clsx(styles.titleIcon, 'success')}
              data-testid={testIds.successIcon}
            />
          )}
          <span className={styles.title} data-testid={testIds.title}>
            {title}
          </span>
        </DialogTitle>
      )}
      {message && (
        <DialogContent>
          <DialogContentText color="textPrimary" data-testid={testIds.message}>
            {message && message.includes('[icon]') ? (
              <PageDescriptionWithIcon
                title={message}
                delimit="[icon]"
                icon={<CreateNewFolder className={styles.icon} />}
              />
            ) : (
              message
            )}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {onRetry && (
          <>
            <Button
              onClick={onRetry}
              color="primary"
              variant="contained"
              data-testid={testIds.retryButton}
            >
              {intl.formatMessage({ id: 'app.retry' })}
            </Button>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              data-testid={testIds.cancelButton}
            >
              {intl.formatMessage({ id: 'app.cancel' })}
            </Button>
          </>
        )}
        {!onRetry && (
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            data-testid={testIds.okButton}
          >
            {intl.formatMessage({ id: 'app.close' })}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(InfoDialog);
