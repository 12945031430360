import { createStyles, makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/no-cycle
import { NavBarProps } from './index';

type Props = Pick<NavBarProps<never>, 'toolbarVariant' | 'width'>;

const isToolbarVariantRegular = (props: Props) =>
  props.toolbarVariant === 'regular';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: (props: Props) => ({
      flexShrink: 0,
      width: props.width,
      [theme.breakpoints.down('xs')]: {
        width: 0,
      },
    }),
    toolbar: (props: Props) => ({
      minHeight: theme.spacing(isToolbarVariantRegular(props) ? 8 : 6),
    }),
    drawerPaper: (props: Props) => ({
      [theme.breakpoints.up('sm')]: {
        boxShadow: theme.shadows[1],
      },
      overflowY: 'hidden',
      width: props.width,
    }),
    children: {
      flexShrink: 0,
    },
    startAdornment: {
      flexShrink: 0,
      marginBottom: theme.spacing(0.5),
    },
    content: {
      flexGrow: 1,
      overflowY: 'auto',
    },
    menuNav: {
      padding: theme.spacing(0, 1),
      '& hr:last-child': {
        marginTop: theme.spacing(0.5),
      },
    },
    divider: {
      marginTop: -1,
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
      backgroundColor: '#e0e0e0',
    },
  })
);

export type NavBarStyles = typeof useStyles;
export default useStyles;
