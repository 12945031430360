import create from 'zustand';
import React from 'react';

type ScrollDirection = 'down' | 'up';

export type ScrollPosition = {
  scrollHeight: number;
  clientHeight: number;
  top: number;
  bottom: number;
};

export type ResponsiveContext = {
  scrolled: boolean;
  setScrolled: (scrolled: boolean) => void;
  scrolledDirection: ScrollDirection;
  setScrolledDirection: (scrollDirection: ScrollDirection) => void;
  scrollPosition?: ScrollPosition;
  setScrollPosition: (scrollPosition?: ScrollPosition) => void;
  updateScrollState: (e: React.UIEvent<HTMLElement | Document> | Event) => void;
};

const targetIsDocument = (target: EventTarget): target is Document => {
  return (target as Document).scrollingElement !== undefined;
};

const targetIsHTMLElement = (target: EventTarget): target is HTMLElement => {
  return (target as HTMLElement).scrollTop !== undefined;
};

const useResponsiveContext = create<ResponsiveContext>((set, get) => ({
  scrolled: false,
  setScrolled: (scrolled: boolean) => {
    set({ scrolled });
  },
  scrolledDirection: 'up',
  setScrolledDirection: (scrolledDirection: ScrollDirection) => {
    set({ scrolledDirection });
  },
  scrollPosition: undefined,
  setScrollPosition: (scrollPosition?: ScrollPosition) => {
    set({ scrollPosition });
  },
  updateScrollState: (e: React.UIEvent<HTMLElement | Document> | Event) => {
    let target = e.currentTarget as HTMLElement | Element | Document | null;
    if (e.target && targetIsHTMLElement(e.target)) {
      if (
        e.target.classList.contains('DataTable-container') ||
        e.target.classList.contains('mobile-container') ||
        e.target.classList.contains('scroll-box')
      ) {
        target = e.target;
      }
    }

    if (target && targetIsDocument(target)) {
      target = target.scrollingElement;
    }

    if (!target) return;

    const scrollPos = {
      scrollHeight: target.scrollHeight,
      clientHeight: target.clientHeight,
      top: target.scrollTop,
      bottom: target.scrollHeight - target.scrollTop - target.clientHeight,
    };

    const scrolled = scrollPos.top > 8 || scrollPos.bottom < 8;

    set({ scrolled });

    const diff = (get().scrollPosition?.top ?? 0) - target.scrollTop;
    if (scrolled) {
      if (diff !== 0) {
        set({ scrolledDirection: diff >= 0 ? 'up' : 'down' });
      }
    } else {
      set({ scrolledDirection: 'up' });
    }

    get().setScrollPosition(scrollPos);
  },
}));

export default useResponsiveContext;
