import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  TypographyProps,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, {
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from 'pcc-react-components';
// eslint-disable-next-line import/no-cycle
import { Route } from '../NavBar';
// eslint-disable-next-line import/no-cycle
import useStyles from './styles';
import { usePatientContext } from '../../../states/patientContextStore';

export type RenderListItemComponent = (
  to: string | string[],
  isExact: boolean
) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
any;

export type NavBarListItemProps<R> = {
  /** Function to create component passed into ListItem `component` prop */
  renderListItemComponent: RenderListItemComponent;
  /** Label to display */
  label: ReactNode;
  /** Unique identifer for button. Used as an argument in `onExpanderClick` and `onNavItemClick` */
  buttonId?: string;
  /** Children prop is reserved for nested menu for the button */
  children?: ReactNode;
  /** Depth of the button (0 and above) is used to determine root level and nested buttons. Used mainly for hierarchial styles */
  depth?: number;
  /** Boolean to determine show more or less state of the expander button */
  expanderOpen?: boolean;
  /** Boolean to force button to be highlighted. Currently used to highlight nested groups where a selected button exists but the menu is collapsed */
  forceHighlight?: boolean;
  /** Icon to display beside the button label. Icons are only displayed on root level items (depth is 0) */
  icon?: ReactNode;
  /** Width of the parent NavBar component. Used in a useEffect to trigger text overflow calculations and show a toolip and ellipsify text */
  navBarWidth?: number;
  /** Function to execute when expander button is clicked */
  onExpanderClick?: (buttonId: string) => void;
  /** Function to execute when NavBarListItem is clicked */
  onNavItemClick?: (buttonId: string) => void;
  /** Optional URL path for NavBarListItem */
  path?: string | string[];
  /** Padding for NavBarListItems nested in a menu differ whether the root level NavBarListItems have icons or not */
  rootItemHasIcon?: boolean;
  /** List of Routes to be used in nested menu */
  routes?: Route<R>[];
  /** Boolean to determine if selection style should be applied when path is an exact match or not */
  selectedOnExactPath?: boolean;
};

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  // eslint-disable-next-line no-shadow
  wrapper: (children: React.ReactElement) => React.ReactElement;
  children: React.ReactElement;
}) => (condition ? wrapper(children) : children);

const NavBarListItem = <R,>({
  label,
  renderListItemComponent,
  buttonId = '',
  children,
  depth = 0,
  expanderOpen = false,
  forceHighlight = false,
  icon,
  navBarWidth = 0,
  onExpanderClick,
  onNavItemClick,
  path = '',
  rootItemHasIcon = true,
  routes,
  selectedOnExactPath = false,
}: NavBarListItemProps<R>): ReactElement => {
  const classes = useStyles({ depth, path, rootItemHasIcon });

  const [hasTextOverflow, setHasTextOverflow] = useState(false);
  const listItemTextRef = useRef<HTMLElement>(null);

  const chartError = usePatientContext((state) => state.chartError);

  const handleListItemClick = () => {
    if (onNavItemClick) {
      onNavItemClick(buttonId);
    }
  };

  const handleExpandClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      if (onExpanderClick) {
        onExpanderClick(buttonId);
      }
    },
    [onExpanderClick, buttonId]
  );

  const renderExpander = useCallback(() => {
    if (!routes) {
      return null;
    }

    const expander = (
      <Button
        variant="icon"
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={handleExpandClick}
        aria-label="expander"
        className={classes.expanderButton}
      >
        {expanderOpen ? (
          <ExpandLess data-testid="less" fontSize="small" />
        ) : (
          <ExpandMore data-testid="more" fontSize="small" />
        )}
      </Button>
    );

    return expander;
  }, [routes, handleExpandClick, classes.expanderButton, expanderOpen]);

  const listItemPrimaryTypographyProps = useMemo(
    () => ({
      variant: (depth === 0
        ? 'subtitle1'
        : 'body1') as TypographyProps['variant'],
      noWrap: true,
    }),
    [depth]
  );

  useEffect(() => {
    if (
      listItemTextRef &&
      listItemTextRef.current &&
      listItemTextRef.current.firstChild
    ) {
      const { clientWidth, scrollWidth } = listItemTextRef.current
        .firstChild as HTMLElement;
      setHasTextOverflow(scrollWidth > clientWidth);
    }
  }, [navBarWidth]);

  return (
    <>
      <ListItem
        aria-haspopup={routes ? true : undefined}
        button
        component={renderListItemComponent(path, selectedOnExactPath)}
        disabled={chartError !== undefined}
        onClick={path ? handleListItemClick : undefined}
        className={clsx(
          classes.item,
          !path && classes.noCursor,
          forceHighlight && classes.forceHighlight
        )}
        disableRipple={!path}
        tabIndex={!path ? -1 : undefined}
      >
        <div>
          {icon && depth === 0 && (
            <ListItemIcon className={classes.icon} role="img" aria-label="icon">
              {React.createElement(
                icon as React.ComponentClass<{ name: string }>
              )}
            </ListItemIcon>
          )}
          <ConditionalWrapper
            condition={hasTextOverflow}
            wrapper={(wrapperChildren) => (
              <Tooltip
                title={label ? label.toString() : ''}
                placement="bottom-start"
              >
                {wrapperChildren}
              </Tooltip>
            )}
          >
            <ListItemText
              primary={label}
              primaryTypographyProps={listItemPrimaryTypographyProps}
              ref={listItemTextRef}
            />
          </ConditionalWrapper>
        </div>
        {renderExpander()}
      </ListItem>
      {children}
    </>
  );
};

export default memo(NavBarListItem);
