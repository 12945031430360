import create from 'zustand';
import { ActionItem } from '../model/Model';

export type ActionContextState = {
  showPageActions: boolean;
  setShowPageActions: (showPageActions: boolean) => void;
  pageActions: ActionItem[];
  setPageActions: (pageActions: ActionItem[]) => void;
  areActionsExpanded: boolean;
  setActionsExpanded: (areActionsExpanded: boolean) => void;
  multiActionIcon?: JSX.Element;
  setMultiActionIcon: (multiActionIcon?: JSX.Element) => void;
};

const useActionContext = create<ActionContextState>((set) => ({
  showPageActions: false,
  setShowPageActions: (showPageActions: boolean) => {
    set({ showPageActions });
  },
  pageActions: [],
  setPageActions: (pageActions: ActionItem[]) => {
    set({ pageActions });
  },
  areActionsExpanded: false,
  setActionsExpanded: (areActionsExpanded: boolean) => {
    set({ areActionsExpanded });
  },
  multiActionIcon: undefined,
  setMultiActionIcon: (multiActionIcon?: JSX.Element) => {
    set({ multiActionIcon });
  },
}));

export default useActionContext;
