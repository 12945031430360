import create from 'zustand';
import { Facility, Patient } from '../model/Model';

export type PatientContextState = {
  patient?: Patient;
  facility?: Facility;
  chartError?: Error;
  setPatient: (patient?: Patient) => void;
  setFacility: (facility?: Facility) => void;
  setChartError: (chartError?: Error) => void;
};

export const usePatientContext = create<PatientContextState>((set) => ({
  patient: undefined,
  facility: undefined,
  chartError: undefined,
  setPatient: (patient?: Patient) => {
    set({ patient });
  },
  setFacility: (facility?: Facility) => {
    set({ facility });
  },
  setChartError: (chartError?: Error) => {
    set({ chartError });
  },
}));
