import React, {
  createRef,
  ReactElement,
  useRef,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { Button, Typography, CircularLoader } from 'pcc-react-components';
import Divider from '@material-ui/core/Divider';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Link,
} from '@material-ui/core';
import { injectIntl, IntlShape } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import apiCall from '../../../utils/apiCall';
import useStyles from './style';
import {
  Condition,
  DataElement,
  Patient,
  Practitioner,
  CareProfile,
  Device,
} from '../../../model/Model';
import testIds from '../utils/constants';
import {
  getAge,
  getFormattedRawDateWithoutTimeZone,
} from '../../../utils/dateUtils';
import { getTranslation } from '../utils/translations';
import { addressFmt, addressFmtLink } from '../../../utils/addressFmt';
import useUserStore from '../../../states/userStore';
import { hasDataElementAccess } from '../../../utils/common';

export interface ResidentInfoDialogProps {
  handleClose: () => void;
  isDialogOpen: boolean;
  patient: Patient;
  physician: Practitioner | null;
  diagnosis: Condition | null;
  photo: string | undefined;
  intl: IntlShape;
}

const ResidentInfoDialog = (props: ResidentInfoDialogProps): ReactElement => {
  const styles = useStyles();
  const {
    handleClose,
    isDialogOpen,
    patient,
    physician,
    diagnosis,
    photo,
    intl,
  } = props;

  const translate = useMemo(() => getTranslation(intl), [intl]);

  const textElementRef = useRef<HTMLSpanElement>(null);
  const [showDiagnosisTooltip, setShowDiagnosisTooltip] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [careProfiles, setCareProfiles] = useState<CareProfile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setLoading(true);
  }, [patient]);

  useEffect(() => {
    if (!patient || !loading || !careProfiles) {
      return;
    }
    const url = `proxy/patients/${patient.patientId}/commands/careProfiles?questionIds=163,164`;
    (async () => {
      try {
        setLoading(true);
        const response = await apiCall.get(url);
        setCareProfiles(response.data.data);
      } catch (e) {
        setErrorMessage(translate.smokingStatus);
      } finally {
        setLoading(false);
      }
    })();
  }, [patient, loading, translate]);

  const previousAddress =
    patient.previousAddress && addressFmt(patient.previousAddress);
  const previousAddressLink =
    patient.previousAddress && addressFmtLink(patient.previousAddress);
  const user = useUserStore((state) => state.user.info);
  const selectedPatient = user?.careVaultPatients?.find(
    (f) => f.patientId === patient?.patientId
  );
  const ResidentDialogContent = (
    <>
      <div className={styles.row}>
        <div style={{ marginRight: '10px' }}>
          <img
            src={photo}
            data-testid={testIds.image}
            alt={patient.firstName}
            className={styles.photo}
          />
        </div>

        <div className={styles.flex2}>
          <div>
            <span data-testid={testIds.dobLabel} className={styles.labels}>
              {translate.dob}
            </span>
            <span data-testid={testIds.dob} className={styles.content}>
              {getFormattedRawDateWithoutTimeZone(patient.birthDate)} (
              {getAge(patient.birthDate)})
            </span>
          </div>
          <div>
            <span className={styles.labels}>{translate.gender}</span>
            <span data-testid={testIds.gender} className={styles.content}>
              {patient.gender ?? '-'}
            </span>
          </div>
          <div>
            <span className={styles.labels}>{translate.status}</span>
            <span data-testid={testIds.status} className={styles.content}>
              {patient.patientStatus ?? '-'}
            </span>
          </div>
        </div>

        <div className={styles.flex4}>
          {hasDataElementAccess(selectedPatient, DataElement.PRACTITIONERS) && (
            <div>
              <span className={styles.labels}>{translate.physician}</span>
              {!physician && (
                <span
                  data-testid={testIds.physician}
                  className={styles.content}
                >
                  -
                </span>
              )}
              {physician && (physician.firstName || physician.lastName) && (
                <span
                  data-testid={testIds.physician}
                  className={styles.content}
                >
                  {physician.lastName}, {physician.firstName}
                </span>
              )}
            </div>
          )}
          {hasDataElementAccess(selectedPatient, DataElement.CONDITIONS) && (
            <div className={styles.row}>
              <span className={styles.labels}>
                {translate.primaryDiagnosis}
              </span>
              <Tooltip
                title={diagnosis?.icd10Description ?? '-'}
                disableHoverListener
                open={showDiagnosisTooltip}
                onMouseEnter={() => {
                  if (textElementRef.current) {
                    setShowDiagnosisTooltip(
                      textElementRef.current.scrollHeight >
                        textElementRef.current.clientHeight
                    );
                  }
                }}
                onMouseLeave={() => {
                  setShowDiagnosisTooltip(false);
                }}
              >
                <span
                  data-testid={testIds.diagnosis}
                  className={`${styles.content} ${styles.textOverflow}`}
                  ref={textElementRef}
                >
                  {diagnosis?.icd10Description ?? '-'}
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <Divider className={styles.divider} />
      <div>
        <Typography variant="h4.medium">
          {translate.contactInformation}
        </Typography>
        <Divider className={styles.topDivider} />
        <div className={styles.row}>
          <div className={styles.flex1}>
            <div>
              <span className={styles.labels}>{translate.homePhone}</span>
              <span data-testid={testIds.phone} className={styles.content}>
                {patient.homePhone ? (
                  <Link href={`tel:${patient.homePhone}`}>
                    {patient.homePhone}
                  </Link>
                ) : (
                  '-'
                )}
              </span>
            </div>
            <div>
              <span className={styles.labels}>{translate.emailAddress}</span>
              <span data-testid={testIds.email} className={styles.content}>
                {patient.email ? (
                  <Link href={`mailto:${patient.email}`}>{patient.email}</Link>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
          <div className={styles.flex2}>
            <div>
              <span className={styles.labels}>{translate.previousAddress}</span>
              <span data-testid={testIds.address} className={styles.content}>
                {previousAddress ? (
                  <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    href={previousAddressLink}
                  >
                    {previousAddress}
                  </Link>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Divider className={styles.divider} />
      <div>
        <Typography variant="h4.medium">
          {translate.personalInformation}
        </Typography>
        <Divider className={styles.topDivider} />
        <div className={styles.row}>
          <div className={styles.flex2}>
            <div>
              <span className={styles.labels}>{translate.suffix}</span>
              <span data-testid={testIds.suffix} className={styles.content}>
                {patient.suffix ?? '-'}
              </span>
            </div>
            <div>
              <span className={styles.labels}>{translate.middleName}</span>
              <span data-testid={testIds.middleName} className={styles.content}>
                {patient.middleName ?? '-'}
              </span>
            </div>
            <div>
              <span className={styles.labels}>{translate.maidenName}</span>
              <span data-testid={testIds.maidenName} className={styles.content}>
                {patient.maidenName ?? '-'}
              </span>
            </div>
          </div>
          <div className={styles.flex2}>
            <div>
              <span className={styles.labels}>{translate.maritalStatus}</span>
              <span
                data-testid={testIds.maritalStatus}
                className={styles.content}
              >
                {patient.maritalStatus ?? '-'}
              </span>
            </div>
            <div>
              <span className={styles.labels}>{translate.ethnicity}</span>
              <span data-testid={testIds.ethnicity} className={styles.content}>
                {patient.ethnicityDesc ?? '-'}
              </span>
            </div>
            <div>
              <span className={styles.labels}>{translate.race}</span>
              <span data-testid={testIds.race} className={styles.content}>
                {patient.raceCode?.codings.map((x) => x.display).join(', ') ??
                  '-'}
              </span>
            </div>
          </div>
          <div className={styles.flex2}>
            <div className={styles.smokingDesc}>
              <span className={styles.labels}>{translate.smokingStatus}</span>
              <div
                className={styles.content}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span data-testid={testIds.smokingStatus}>
                  {patient.smokingStatusDesc ?? '-'}
                </span>
                {careProfiles &&
                  careProfiles.length > 0 &&
                  careProfiles[0].value && (
                    <span>
                      <span data-testid={testIds.smokingDates}>
                        {getFormattedRawDateWithoutTimeZone(
                          careProfiles[0].value
                        ) ?? '-'}
                      </span>{' '}
                      &ndash;{' '}
                      <span data-testid={testIds.smokingDates}>
                        {careProfiles[1].value
                          ? getFormattedRawDateWithoutTimeZone(
                              careProfiles[1].value
                            )
                          : translate.present}
                      </span>
                    </span>
                  )}
              </div>
            </div>
            <div>
              <span className={styles.labels}>
                {translate.preferredLanguage}
              </span>
              <span
                data-testid={testIds.preferredLanguage}
                className={styles.content}
              >
                {patient.languageDesc ?? '-'}
              </span>
            </div>
          </div>
        </div>
        <Divider className={styles.bottomDivider} />
      </div>
    </>
  );

  const ResidentDialogActions = (
    <Button
      color="primary"
      label="Close"
      onClick={handleClose}
      size="large"
      variant="contained"
    />
  );

  return (
    <Dialog fullWidth maxWidth="md" open={isDialogOpen} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h4.medium">Profile</Typography>
        <Divider className={styles.divider} />
        <Typography variant="h4.medium" data-testid={testIds.name}>
          {patient.firstName} {patient.lastName} {patient.suffix}
        </Typography>
      </DialogTitle>

      <DialogContent style={{ padding: '8px 24px 0px 24px' }}>
        {loading ? <CircularLoader /> : ResidentDialogContent}
      </DialogContent>
      <DialogActions>{ResidentDialogActions}</DialogActions>
    </Dialog>
  );
};

export default injectIntl(ResidentInfoDialog);
